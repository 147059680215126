<template>
    <Dropdown :value="status" @input="updateStatus($event)" :disabled="disabled">
        <div value="unhandled"><div class="dot red"></div> Uhåndtert</div>
        <div value="ongoing"><div class="dot yellow"></div> Pågår</div>
        <div value="completed"><div class="dot green"></div> Ferdig</div>
    </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: 'StatusDropdown',
    components: {
        Dropdown,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        value: {
            type: String,
            default: 'unhandled',
        },
        dev: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        status: 'unhandled',
    }),
    methods: {
        async updateStatus(status) {
            if (this.item) await this.$store.dispatch(`Project/updateStatus`, {
                item: this.item,
                status,
            });
            this.status = status;
            if (!this.item) this.$emit('input', this.status);
        },
    },
    watch: {
        item: {
            handler() {
                if (this.item) this.status = this.item.status;
                else this.status = this.value;
            },
            immediate: true,
        },
        value: {
            handler() {
                if (this.item) this.status = this.item.status;
                else this.status = this.value;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: black;

    &.red {
        background: #C63030;
    }

    &.yellow {
        background: #EACE3C;
    }

    &.green {
        background: #88DB6B;
    }
}
</style>