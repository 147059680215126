import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { initializeApp } from "firebase/app";

import BaseCard from '../src/components/ui/BaseCard.vue'
import BaseButton from '../src/components/ui/BaseButton.vue'
import BasePopup from '../src/components/ui/BasePopup.vue'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyALaUNiAdqszitA7kZzR674pDapmPcg6ek",
  authDomain: "issue-system-1a9eb.firebaseapp.com",
  projectId: "issue-system-1a9eb",
  storageBucket: "issue-system-1a9eb.appspot.com",
  messagingSenderId: "143159364739",
  appId: "1:143159364739:web:40febd5b95dedd2ae19fb6",
  measurementId: "G-EC5HTRMRFW"
};

//window.store = store;

initializeApp(firebaseConfig);
//store.dispatch('User/startListening');

Vue.config.productionTip = false

Vue.component('base-card', BaseCard)
Vue.component('base-button', BaseButton)
Vue.component('base-popup', BasePopup)

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

app.$mount('#app')
