<template>
    <Modal class="input-modal" :value="value" @input="close">
        <div class="contents">
            <h3 class="title"><slot name="title"></slot></h3>
            <span class="text"><slot name="text"></slot></span>
            <PaddedInput class="input" :type="type" :placeholder="placeholder" v-model="inputValue" />
            <span class="error" v-if="error">{{ error }}</span>
            <div class="spacing"></div>
            <dialog-button @click="submit"><slot name="button">Lagre</slot></dialog-button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import PaddedInput from '@/components/PaddedInput.vue'
import DialogButton from './DialogButton.vue'

export default {
    name: 'InputDialog',
    components: {
        Modal,
        PaddedInput,
        DialogButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        type: {
            type: String,
            default: 'text',
        },
        placeholder: {
            type: String,
            default: '',
        },
        defaultValue: {
            type: [String, Number],
            default: null,
        },
        validate: {
            type: Function,
            required: false,
        },
    },
    data: () => ({
        inputValue: '',
        error: null,
        resolve: null,
    }),
    methods: {
        ask(defaultValue) {
            if (this.resolve) return;
            return new Promise(resolve => {
                this.resolve = resolve;
                if (defaultValue || defaultValue === 0) this.inputValue = defaultValue;
                this.$emit('input', true);
            });
        },
        submit() {
            if (this.validate) {
                const error = this.validate(this.inputValue);
                if (error) {
                    this.error = error;
                    return;
                }
            }
            this.$emit('submit', (this.inputValue || this.inputValue === 0)  ? this.inputValue : '');
            if (this.resolve) this.resolve((this.inputValue || this.inputValue === 0)  ? this.inputValue : '');
            this.resolve = null;
            this.inputValue = '';
            this.error = null;
            this.$emit('input', false);
        },
        close() {
            if (this.resolve) this.resolve(null);
            this.resolve = null;
            this.$emit('input', false);
        },
    },
    watch: {
        defaultValue: {
            handler() {
                this.inputValue = this.defaultValue;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.input-modal {
    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        min-width: 500px;
        min-height: 350px;
        padding-bottom: 30px;
        box-sizing: border-box;

        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .text {
        }

        .input {
            width: 100%;
            box-sizing: border-box;
        }

        .error {
            color: red;
            font-style: italic;
        }

        .spacing {
            flex-grow: 1;
        }
    }
}
</style>