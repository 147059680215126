<template>
    <div class="category">
        <img v-if="open" src="@/assets/angle-up-solid.svg" @click="open = false"/>
        <img v-else src="@/assets/angle-down-solid.svg" @click="open = true"/>
        <i class="delete-button fa fa-times" v-if="editing && category.items.length == 0" @click="$emit('deleteCategory', category)" />
        <div class="flex">
            <h3 @click="open = !open">
                <status-icon v-if="showIcon" :status="status" />
                {{ category.name }}
            </h3>
        </div>
        <div class="children" v-show="open">
            <item-view v-for="item in category.items"
                :key="key(item)"
                :item="item"
                :editing="editing"
                :level="level + 1"
                @open="$emit('open', $event)"
                @addCategory="$emit('addCategory', $event)"
                @addTest="$emit('addTest', $event)"
                @deleteCategory="$emit('deleteCategory', $event)"
            />
        </div>
        <div class="buttons" v-if="open && editing">
            <base-button @clicked="$emit('addCategory', category)">+ Kategori</base-button>
            <base-button @clicked="$emit('addTest', category)">+ Test</base-button>
        </div>
    </div>
</template>

<script>
let _id = 0;
const _idMap = new WeakMap();

import StatusIcon from './StatusIcon.vue'

export default {
    name: 'CategoryView',
    emits: ['open'],
    components: {
        ItemView: () => import('./ItemView.vue'),
        StatusIcon,
    },
    props: {
        category: {
            type: Object,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        open: true,
    }),
    computed: {
        showIcon() {
            return 'status' in this.category;
        },
        status() {
            if (this.category.status.none > 0) return 'none';
            if (this.category.status.running > 0) return 'running';
            if (this.category.status.error > 0) return 'error';
            if (this.category.status.ok == 0 && this.category.status.ok_note == 0) return 'skipped';
            return 'ok';
        },
    },
    methods: {
        key(item) {
            if (!_idMap.has(item)) _idMap.set(item, (_id++));
            return _idMap.get(item);
        },
    },
    mounted() {
        if ('status' in this.category && (this.status == 'ok' || this.status == 'skipped')) this.open = false;
    },
}
</script>

<style lang="scss" scoped>
.category {
    position: relative;
    width: 100%;

    h3 {
        margin: 0;
    }

    img {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
    }

    .delete-button {
        width: 24px;
        height: 24px;
        font-size: 24px;
        position: absolute;
        right: 30px;
    }

    .buttons {
        margin-left: 15px;
        display: flex;
        gap: 10px;

        button {
            padding: 5px 10px;
        }
    }
}
</style>