<template>
    <div class="nav">
        <div class="navigation">
            <div>
                <h2>Devhelper</h2>
                <h3>Av Link Utvikling</h3>
            </div>
            <div class="options">
                <router-link class="exact-link" :to="{ name: 'Frontpage' }">Alle prosjekter</router-link>
                <template v-if="selectedProject">
                    <Dropdown v-model="selectedProject" v-if="selectedProject && isDev" class="project-name" projectSelector>
                        <span v-for="project in $store.state.Project.projectsList" :value="project.id" :key="project.id">{{ project.name }}</span>
                    </Dropdown>
                    <router-link class="exact-link" v-if="isDev" :to="{name: 'DeveloperDashboard'}">Dashboard</router-link>
                    <router-link class="exact-link" v-if="!isDev" :to="{name: 'UserDashboard', params: {projectId: this.projectId}}" >Dashboard</router-link>
                    <router-link :to="{name: 'CustomerBugOverview', params: {projectId: this.projectId}}">Feil</router-link>
                    <router-link :to="{name: 'DevelopmentOverview', params: {projectId: this.projectId}}">Oppgaver</router-link>
                    <router-link :to="{name: `UserBugOverview`, params: {projectId: this.projectId}}">Brukerrapporter</router-link>
                    <router-link v-if="isDev" :to="{name: `Timesheet`, params: {projectId: this.projectId}}">Timelister</router-link>
                    <router-link v-if="isDev" :to="{name: 'testingOverview', params: {projectId: this.projectId}}">Testing</router-link>
                </template>
            </div>
        </div>
        <div class="help">
            <div class="selector">
                <router-link class="settings" :to="{name: `Settings`, params: {projectId: this.projectId}}">⚙</router-link>
                <button class="logout" @click="logout()">Logg ut</button>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import Dropdown from '@/components/Dropdown.vue'

export default {
    emits: ['press'],
    components: {
        Dropdown,
    },
    data() {
        return {
            isActive: false,
            projectId: '',
            selectedProject: null,
            selectedProjectName: '',
        }
    },
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        isDev() {
            if (this.isAdmin) return true;
            if (!this.$store.state.User.currentUser) return false;
            if (!this.selectedProject) return false;
            const project = this.$store.state.Project.projectsList.find(proj => proj.id == this.selectedProject);
            if (!project) return false;
            return project.devs.includes(this.$store.state.User.currentUser.id);
        },
    },
    methods: {
        logout() {
            const auth = getAuth();
            signOut(auth)
            .then(() => {
                this.$router.push("/Login")
            })
            .catch((error) => {
                console.log(error.message)
            });
        }
    },
    watch: {
        selectedProject() {
            if (this.selectedProject == this.$route.params.projectId) return;
            this.$router.push({ name: 'DeveloperDashboard', params: { projectId: this.selectedProject } });
        },
    },
    beforeMount() {
        if (!this.$store.state.User.currentUser) this.$router.push({ name: 'Login' });
    },
    async mounted() {
        await this.$store.dispatch(`Project/fetchActiveProjects`);
        this.projectId = this.$route.params.projectId;
        this.selectedProject = this.$route.params.projectId;
        for(let project of this.$store.state.Project.projectsList) {
            if(project.id === this.selectedProject) {
                this.selectedProjectName = project.name
            }
        }        
    },
}
</script>

<style lang="scss" scoped>
h2, h3 {
    margin: 0;
}

.settings {
    text-decoration: none;
    font-size: 24px;
    margin: 0;
}

h3 {
    font-weight: normal;
}

.project-name {
    padding-top: 10px;
    border-top: 1px solid #dcdcdc;
    width: 100%;
    font-weight: bold;
}

.nav {
    flex-shrink: 0;
    width: 200px;
    height: 100vh;
    background: #FFFFFF;
    border-right: 1px solid #D8D8D8;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 0 25px;
}

.navigation {
    display: flex;
    flex-direction: column;
    gap: 90px;  
    align-items: center;

    p {
        color: black;
    }
}

.selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    button {
        width: 70%;
        border-radius: 10px;
        padding: 15px;
        border: solid #DADADA 1px;
        background: none;
        cursor: pointer;
    }
}

.options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 65%;

    a {
        text-decoration: none;
        color: black;
    }

    .unf {
        color: red;
    }

    .exact-link.router-link-exact-active::after,
    :not(.exact-link).router-link-active::after {
        content: '';
        background: #A3D49B;
        width: 14px;
        height: 28px;
        border-radius:5px;
        position: absolute;
        left: -6px;
        transform: translateY(-10%);
    }
}

.logout {
    width: 100px;
    margin: auto;
    display: block;
}
</style>