<template>
    <editable-value type="number" :value="displayValue" @input="hoursChanged" :validate="validateHours" :disabled="disabled">
        <template #title>Timer</template>
        <template #text>Skriv inn antall estimerte timer:</template>
    </editable-value>
</template>

<script>
import EditableValue from '@/components/EditableValue.vue'

export default {
    name: 'EstimatedHours',
    components: {
        EditableValue,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        value: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        displayValue: 0,
    }),
    methods: {
        validateHours(hours) {
            const val = parseFloat(hours);
            if (isNaN(val) || val <= 0) return 'Du må skrive et tall over 0.';
        },
        async hoursChanged(hours) {
            const estimatedHours = parseFloat(hours);
            if (this.item) await this.$store.dispatch(`Project/updateEstimatedHours`, {
                item: this.item,
                estimatedHours,
            });
            this.displayValue = estimatedHours;
            this.$emit('input', this.displayValue);
        },
        async loadValue() {
            if (this.item) {
                const info = await this.$store.dispatch('Project/fetchCostInfo', { item: this.item });
                if (info) this.displayValue = info.estimatedHours;
                else this.displayValue = 0;
            }
            else this.displayValue = this.value;
        },
    },
    watch: {
        item: {
            handler() {
                this.loadValue();
            },
            immediate: true,
        },
        value: {
            handler() {
                this.loadValue();
            },
            // Don't need both of these to be immediate.
        },
    },
}
</script>
