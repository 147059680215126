<template>
  <div class="popup" @click.self="$emit('close')">
    <base-card class="card">
     <slot></slot>
    </base-card>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
.popup {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
}
.card {
  width: 500px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate( -50%,-50%);
}
</style>