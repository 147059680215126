<template>
    <div class="grid item">
        <p>{{ project.name }}</p>
        <div class="notification">
            <p>{{ counts.criticalBugs ?? 0 }}</p>
            <div v-if="isDev && counts.criticalBugs > 0" class="alert"></div>
        </div>
        <div class="notification">
            <p>{{ counts.newBugs ?? 0 }}</p>
            <div v-if="isDev && counts.newBugs > 0" class="alert"></div>
        </div>
        <div class="notification">
            <p>{{ counts.newDevelopments ?? 0 }}</p>
            <div v-if="isDev && counts.newDevelopments > 0" class="alert"></div>
        </div>
        <div class="options">
            <base-button @clicked="manage">Åpne</base-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectItem',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        counts: {},
    }),
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        isDev() {
            if (!this.$store.state.User.currentUser) return false;
            if (this.isAdmin) return true;
            return this.project.devs.includes(this.$store.state.User.currentUser.id);
        },
    },
    methods: {
        manage() {
            if(this.isDev) {
                this.$router.push({name: 'DeveloperDashboard', params: {projectId: this.project.id}})
            } else {
                this.$router.push({name: 'UserDashboard', params: {projectId: this.project.id}})
            }
        },
    },
    async mounted() {
        this.counts = await this.$store.dispatch('Project/fetchProjectCounts', { project: this.project });
    },
}
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.item {
    border-top: solid 1px lightgrey;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.notification {
    justify-self: flex-start;
    position: relative;
}

.alert {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: 10px;
    left: 8px;
}
</style>