import {
    getFirestore, collection, collectionGroup, onSnapshot, getDocs, query, getDoc, doc, addDoc, deleteDoc, updateDoc,
    where, increment, setDoc, orderBy, limit, startAfter, runTransaction, serverTimestamp, getCountFromServer, or, and,
    arrayUnion, arrayRemove,
} from "firebase/firestore"
import { getFunctions, httpsCallable } from 'firebase/functions'
import Vue from 'vue'
import Project from '@/types/Project'
import CustomerBug from '@/types/CustomerBug'
import UserBug from '@/types/UserBug'
import Development from '@/types/Development'
import Comment from '@/types/Comment'
import WorkSession from '@/types/WorkSession'
import CostInfo from '@/types/CostInfo'
import Invoice from '@/types/Invoice'
import User from '@/types/User'
import Reminder from '@/types/Reminder'
import TestRun from '@/types/TestRun'
import TestPlan from '@/types/TestPlan'
import Test from '@/types/Test'
import TestInstance from '@/types/TestInstance'

import deepClone from '@/util/deepClone'

const testStatuses = Object.freeze([
    'none',
    'running',
    'ok',
    'ok_note',
    'error',
    'skipped',
]);

const emptyStatusMap = Object.freeze({
    none: 0,
    running: 0,
    ok: 0,
    ok_note: 0,
    error: 0,
    skipped: 0,
});

export default {
    namespaced: true,
    state: () => ({
        projectsList: [],
        _projectsFetched: false,
        _isFetching: false,
        comments: {},
        developerComments: {},
        workSessions: {},
        projectAccesses: {},
        reminders: {},

        _testPlansUnsub: null,
        _testRunsUnsub: null,
        _testPlanUnsub: null,
        _testRunUnsub: null,
        _testUnsub: null,
        _remindersUnsub: null,
        _currentRemindersProject: null,

        // currentProject: {},
        // customerBugs:   [],
    }),

    mutations: {
        cacheComments(state, { parent, comments, dev }) {
            Vue.set(dev ? state.developerComments : state.comments, parent.path, comments);
        },

        addComment(state, { parent, comment, dev }) {
            const comments = dev ? state.developerComments : state.comments;

            if (!comments[parent.path]) Vue.set(comments, parent.path, []);
            comments[parent.path].push(comment);
        },

        removeComment(state, { parent, comment, dev }) {
            const field = dev ? 'developerComments' : 'comments';

            if (!state[field][parent.path]) return;
            const idx = state[field][parent.path].findIndex(c => c.id == comment.id);
            if (idx == -1) return;
            state[field][parent.path].splice(idx, 1);
        },

        cacheWorkSessions(state, { parent, sessions }) {
            Vue.set(state.workSessions, parent.path, sessions);
        },

        addWorkSession(state, { parent, session }) {
            if (!state.workSessions[parent.path]) Vue.set(state.workSessions, parent.path, []);
            state.workSessions[parent.path].push(session);
            state.workSessions[parent.path].sort((x, y) => x.date - y.date);
        },

        updateWorkSession(state, { parent, session }) {
            if (!state.workSessions[parent.path]) return;
            Vue.set(state.workSessions, parent.path, state.workSessions[parent.path].map(ses => ses.id == session.id ? session : ses));
            state.workSessions[parent.path].sort((x, y) => x.date - y.date);
        },

        removeWorkSession(state, { parent, session }) {
            if (!state.workSessions[parent.path]) return;
            const idx = state.workSessions[parent.path].findIndex(s => s.id == session.id);
            if (idx == -1) return;
            state.workSessions[parent.path].splice(idx, 1);
        },

        cacheProjectAccesses(state, { projectId, users }) {
            Vue.set(state.projectAccesses, projectId, users);
        },

        addProjectAccess(state, { projectId, user }) {
            if (state.projectAccesses[projectId] === undefined) Vue.set(state.projectAccesses, projectId, []);
            if (state.projectAccesses[projectId].find(_user => _user.id == user.id)) return;
            state.projectAccesses[projectId].push(user);
        },

        removeProjectAccess(state, { projectId, user }) {
            if (state.projectAccesses[projectId] === undefined) return;
            const idx = state.projectAccesses[projectId].findIndex(_user => _user.id == user.id);
            if (idx == -1) return;
            state.projectAccesses[projectId].splice(idx, 1);
        },

        cacheProjectList(state, projects) {
            state.projectsList = projects;
            state._projectsFetched = true;
        },

        cacheReminders(state, { projectId, reminders }) {
            Vue.set(state.reminders, projectId, reminders);
            state._currentRemindersProject = projectId;
        },

        cacheRemindersUnsub(state, unsub) {
            if (state._remindersUnsub) state._remindersUnsub();
            state._remindersUnsub = unsub;
        },

        storeTestPlansUnsub(state, unsub) {
            if (state._testPlansUnsub) state._testPlansUnsub();
            state._testPlansUnsub = unsub;
        },

        storeTestRunsUnsub(state, unsub) {
            if (state._testRunsUnsub) state._testRunsUnsub();
            state._testRunsUnsub = unsub;
        },

        storeTestPlanUnsub(state, unsub) {
            if (state._testPlanUnsub) state._testPlanUnsub();
            state._testPlanUnsub = unsub;
        },

        storeTestRunUnsub(state, unsub) {
            if (state._testRunUnsub) state._testRunUnsub();
            state._testRunUnsub = unsub;
        },

        storeTestUnsub(state, unsub) {
            if (state._testUnsub) state._testUnsub();
            state._testUnsub = unsub;
        },
    },

    actions: {
        //-----------------------------------------------------------
        //------------------------- TESTS ---------------------------
        //-----------------------------------------------------------

        listenTestPlans({ commit }, { projectId, listener }) {
            const db = getFirestore();

            const unsub = onSnapshot(query(collection(db, `Project/${projectId}/TestPlans/`)), snapshot => {
                const items = snapshot.docs.map(doc => new TestPlan(doc));
                listener(items);
            });

            commit('storeTestPlansUnsub', unsub);
            return unsub;
        },

        listenTestRuns({ commit }, { projectId, listener }) {
            const db = getFirestore();

            const unsub = onSnapshot(query(collection(db, `Project/${projectId}/TestRuns/`)), snapshot => {
                const items = snapshot.docs.map(doc => new TestRun(doc));
                listener(items);
            });

            commit('storeTestRunsUnsub', unsub);
            return unsub;
        },

        listenTestPlan({ commit }, { projectId, id, listener }) {
            const db = getFirestore();

            const unsub = onSnapshot(doc(db, `Project/${projectId}/TestPlans/${id}`), snapshot => {
                const item = new TestPlan(snapshot);
                listener(item);
            });

            commit('storeTestPlanUnsub', unsub);
            return unsub;
        },

        listenTestRun({ commit }, { projectId, id, listener }) {
            const db = getFirestore();

            const unsub = onSnapshot(doc(db, `Project/${projectId}/TestRuns/${id}`), snapshot => {
                const item = new TestRun(snapshot);
                listener(item);
            });

            commit('storeTestRunUnsub', unsub);
            return unsub;
        },

        listenTest({ commit }, { planOrRun, id, listener }) {
            const db = getFirestore();

            const unsub = onSnapshot(doc(db, `${planOrRun.path}/Tests/${id}`), snapshot => {
                const item = new (planOrRun.isRun ? TestInstance : Test)(snapshot);
                listener(item);
            });

            commit('storeTestUnsub', unsub);
            return unsub;
        },

        async saveTest({}, { planOrRun, test }) {
            if (test.path) {
                await updateDoc(test.ref, test.data);
                return test.id;
            } else {
                const ref = await addDoc(collection(planOrRun.ref.firestore, planOrRun.path, 'Tests'), test.data);
                return ref.id;
            }
        },

        async saveTestPlan({}, { projectId, plan }) {
            if (plan.path) {
                await updateDoc(plan.ref, plan.data);
                return plan.id;
            } else {
                const db = getFirestore();
                const ref = await addDoc(collection(db, `Project/${projectId}/TestPlans`), plan.data);
                return ref.id;
            }
        },

        async deleteTestPlan({}, plan) {
            if (!plan.path) return;
            const db = getFirestore();

            const tests = await getDocs(collection(db, plan.path + '/Tests'));

            await runTransaction(db, async tr => {
                tests.docs.forEach(test => tr.delete(test.ref));
                tr.delete(plan.ref);
            });
        },

        async deleteCategory({}, {plan, category}) {
            // Locate the parent category in the plan tree
            function findParent(cat) {
                for (let i in cat.items) {
                    const item = cat.items[i];
                    if (item == category) return cat;
                    if (item.type == 'test') continue;
                    const found = findParent(item);
                    if (found) return found;
                }
            }
            const parent = findParent(plan);

            const idx = parent.items.findIndex(item => item == category);
            parent.items.splice(idx, 1);

            await updateDoc(plan.ref, { items: plan.items });
        },

        async deleteTest({}, {plan, test}) {
            // Locate the test in the plan tree
            function findCategory(category) {
                for (let i in category.items) {
                    const item = category.items[i];
                    if (item.type == 'test' && item.id == test.id) return category;
                    const found = findCategory(item);
                    if (found) return found;
                }
            }
            const category = findCategory(plan);
            
            //category.items = category.items.filter(item => item.id != test.id);
            const idx = category.items.findIndex(item => item.id == test.id);
            category.items.splice(idx, 1);

            const db = getFirestore();
            await runTransaction(db, async tr => {
                tr.update(plan.ref, { items: plan.items });
                tr.delete(test.ref);
            });
        },

        async startTest({ rootState }, plan) {
            // Create a test run from the test plan
            const db = getFirestore();

            const runPath = plan.path.replace(/\/TestPlans\/.+$/, '/TestRuns');
            const runRef = doc(collection(db, runPath));

            await runTransaction(db, async tr => {
                // Reload the plan from the database
                const newDoc = await tr.get(plan.ref);
                plan = new TestPlan(newDoc);

                // Start by creating a structure clone
                const items = deepClone(plan.items);

                // Find all leaf (test) objects in the structure
                const structureTests = [];
                function getTests(item) {
                    if (item.type == 'test') structureTests.push(item);
                    if (item.type == 'category') item.items.forEach(getTests);
                }
                items.forEach(getTests);

                // Load all test objects
                await Promise.all(structureTests.map(async testInfo => {
                    testInfo.obj = await tr.get(doc(db, `${plan.path}/Tests/${testInfo.id}`));
                }));

                // Create test instances for all of them
                structureTests.forEach(async testInfo => {
                    const ref = doc(collection(db, `${runRef.path}/Tests`));
                    const data = testInfo.obj.data();
                    delete testInfo.obj;
                    data.status = 'none';
                    data.comments = '';
                    tr.set(ref, data);
                    testInfo.id = ref.id;
                });

                // Update the test structure to add status to all nodes
                function setStatus(item) {
                    if (item.type == 'test') {
                        item.status = 'none';
                        return 1;
                    }
                    if (item.type == 'category') {
                        item.status = deepClone(emptyStatusMap);
                        item.status.none = item.items.reduce((a, n) => a + setStatus(n), 0);
                        return item.status.none;
                    }
                }
                const totalTests = items.reduce((a, n) => a + setStatus(n), 0);

                // Create the test run itself
                const runData = Object.assign(plan.data, {
                    items,
                    plan: plan.id,
                    createdAt: new Date(),
                    createdBy: rootState.User.currentUser.id,
                    createdByName: rootState.User.currentUser.name,
                    status: Object.assign({}, deepClone(emptyStatusMap), { none: totalTests }),
                });
                tr.set(runRef, runData);
            });

            return runRef.id;
        },

        async setTestStatus({ rootState }, { run, test, status }) {
            // Locate the test in the run tree
            function findIn(category) {
                for (let i in category.items) {
                    const item = category.items[i];
                    if (item.type == 'test' && item.id == test.id) return [parseInt(i)];
                    const found = findIn(item);
                    if (found) {
                        found.push(parseInt(i));
                        return found;
                    }
                }
            }
            let path = findIn(run);
            if (!path) throw 'not_found';
            path = path.reverse();

            let items = run.items;
            const oldStatus = test.status;
            for (let i in path) {
                const idx = path[i];
                if (i == path.length - 1) items[idx].status = status;
                else {
                    items[idx].status[oldStatus]--;
                    items[idx].status[status]++;
                    items = items[idx].items;
                }
            }

            run.status[oldStatus]--;
            run.status[status]++;

            test.data.status = status;
            if (status != 'none') {
                test.data.runBy = rootState.User.currentUser.id;
                test.data.runByName = rootState.User.currentUser.name;
            } else {
                test.data.runBy = null;
                test.data.runByName = null;
            }

            const db = getFirestore();
            await runTransaction(db, async tr => {
                // Update the test object
                tr.update(test.ref, { status: test.status, runBy: test.runBy, runByName: test.runByName, steps: test.steps, comments: test.comments });
                tr.update(run.ref, { status: run.status, items: run.items });
            });
        },

        //-----------------------------------------------------------
        //------------------------- FETCH ---------------------------
        //-----------------------------------------------------------

        //Fetches all reports from users on a project
        async fetchUserBugs({}, projectId){
            // TODO Paginate
            const db = getFirestore();

            let userReports = await getDocs(query(collection(db, `Project/${projectId}/UserBugs/`)));

            let ret = userReports.docs.map(doc => new UserBug(doc));

            ret.sort(function(x, y){
                return y.date - x.date;
            })

            return ret;
        },

        //Fetches all the reports for a project specified with projectID.
        async fetchCustomerBugs({}, projectId) {
            // TODO Paginate
            const db = getFirestore();
            let projectReports = await getDocs(query(collection(db, `Project/${projectId}/CustomerBugs/`), orderBy('date', 'desc')));
            
            let ret = projectReports.docs.map(doc => new CustomerBug(doc));

            ret.sort(function(x, y){
                return y.date - x.date;
            })
            
            return ret;
        },

        //Fetch a spesific report from a project
        async fetchCustomerBug({}, {projectId, reportId}) {
            // TODO Cache better
            const db = getFirestore();

            let report = await getDoc(doc(db, `Project/${projectId}/CustomerBugs/`, reportId))
            
            return new CustomerBug(report);
        },

        //Fetch a spesific user report from a project
        async fetchUserBug({}, {projectId, reportId}){
            // TODO Cache better
            const db = getFirestore();

            let report = await getDoc(doc(db, `Project/${projectId}/UserBugs/`, reportId))
            
            return new UserBug(report);
        },

        async fetchComments({ commit }, { parent, dev }) {
            const db = getFirestore();

            let comments = await getDocs(query(collection(db, `${parent.path}/${dev ? 'DeveloperComments' : 'Comments'}`), orderBy('date', 'desc')));

            let ret = comments.docs.map(doc => new Comment(doc));

            ret.sort(function(x, y){
                return x.date - y.date;
            });

            commit('cacheComments', { parent, comments: ret, dev });

            return ret;
        },

        async fetchWorkSessions({ commit }, parent) {
            const db = getFirestore();

            let sessions = await getDocs(query(collection(db, `${parent.path}/WorkSessions`), orderBy('date', 'desc')));

            let ret = sessions.docs.map(doc => new WorkSession(doc));
            
            ret.sort((x, y) => x.date - y.date);
            commit('cacheWorkSessions', { parent, sessions: ret });

            return ret;
        },

        async fetchWorkSessionsForProject({ commit }, project) {
            const db = getFirestore();

            let sessions = await getDocs(query(collectionGroup(db, 'WorkSessions'), where('project', '==', project.id), orderBy('date', 'desc')));

            let ret = sessions.docs.map(doc => new WorkSession(doc));
            
            ret.sort((x, y) => x.date - y.date);
            commit('cacheWorkSessions', { parent: project, sessions: ret });

            return ret;
        },

        //Fetch all projects which are active
        async fetchActiveProjects({ rootState, commit, state }, opts) {
            const db = getFirestore();

            if (state._projectsFetched && !opts?.force) return; // Already fetched

            let docs;
            if (rootState.User.currentUser.isAdmin) {
                docs = await getDocs(query(collection(db, "Project"), where('isActive', '==', true), orderBy("date", "desc")));
            } else {
                docs = await getDocs(query(collection(db, "Project"), where('devs', 'array-contains', rootState.User.currentUser.id), where('isActive', '==', true), orderBy("date", "desc")));
            }

            let projects = docs.docs.map(doc => new Project(doc));

            projects.sort(function(x, y){
                return y.date - x.date;
            });
            
            commit('cacheProjectList', projects);
        },

        //Fetches all developments for a project
        async fetchDevelopments({}, projectId) {
            // TODO Paginate
            const db = getFirestore();

            let developments = await getDocs(query(collection(db, `Project/${projectId}/Developments/`)));

            let ret = developments.docs.map(doc => new Development(doc));

            ret.sort(function(x, y){
                return y.date - x.date;
            })

            return ret;
        },

        //Fetches a single development request from a project
        async fetchDevelopment({}, {projectId, developmentId}) {
            const db = getFirestore();

            let development = await getDoc(doc(db, `Project/${projectId}/Developments/`, developmentId))

            return new Development(development);
        },

        //Fetches a single user object with its UserID
        async fetchUser({}, {userId}) {
            const db = getFirestore();

            let user = await getDoc(doc(db, `User/`, userId))

            return new User(user);
        },

        //Fetches all the users who have access to a project
        async fetchAccessesForProject({ state, commit }, project) {
            if (state.projectAccesses[project.id]) return;
            const db = getFirestore();

            let users = (await Promise.all(project.access.map(id => getDoc(doc(db, `User/${id}`))))).map(user => new User(user));
            commit('cacheProjectAccesses', {projectId: project.id, users});
            return users;
        },

        async fetchDevelopersForProject({}, project) {
            const db = getFirestore();

            let users = (await Promise.all(project.devs.map(id => getDoc(doc(db, `User/${id}`))))).map(user => new User(user));
            //commit('cacheProjectDevelopers', {projectId: project.id, users});
            return users;
        },

        //Fetch a spesific project
        async fetchProject({}, projectId) {
            const db = getFirestore();

            let project = await getDoc(doc(db, `Project/`, projectId));
            return new Project(project);
        },

        //Fetches all projects a user has access to.
        async fetchProjectsbyUserId({}, uid) {
            const db = getFirestore();
            const projects = await getDocs(query(collection(db, 'Project'), where('access', 'array-contains', uid), where('isActive', '==', true)));
            return projects.docs.map(doc => new Project(doc));
        },

        async fetchCostInfo({}, {item}) {
            const db = getFirestore();
            const info = await getDoc(doc(db, item.path + '/CostInfo/costinfo'));
            return new CostInfo(info);
        },

        async fetchCostInfos({}, {project}) {
            const db = getFirestore();
            const info = await getDocs(query(collectionGroup(db, 'CostInfo'), where('projectId', '==', project.id), orderBy('date', 'desc')));
            return info.docs.filter(doc => doc.data().itemId).map(doc => new CostInfo(doc));
        },

        async fetchUninvoicedCostInfos({}, {project}) {
            const db = getFirestore();
            const info = await getDocs(query(collectionGroup(db, 'CostInfo'), where('projectId', '==', project.id), where('status', '==', 'completed'), where('invoiced', '==', false), orderBy('date', 'desc')));
            return info.docs.filter(doc => doc.data().itemId).map(doc => new CostInfo(doc));
        },

        async fetchInvoices({}, {project}) {
            const db = getFirestore();
            const invoices = await getDocs(query(collection(db, `Project/${project.id}/Invoices`), orderBy('date', 'desc')));
            return invoices.docs.map(doc => new Invoice(doc));
        },

        async fetchProjectCounts({}, { project }) {
            const db = getFirestore();
            const counts = await Promise.all([
                getCountFromServer(query(collection(db, `Project/${project.id}/CustomerBugs`), where('urgency', '==', 4), where('status', '!=', 'completed'))), // Critical bugs
                getCountFromServer(query(collection(db, `Project/${project.id}/CustomerBugs`), where('status', '==', 'unhandled'))), // New bugs
                getCountFromServer(query(collection(db, `Project/${project.id}/Developments`), and(or(where('price', '==', null), where('priceAccepted', '==', true)), where('status', '==', 'unhandled')))),// New developments
            ]);
            return {
                criticalBugs: counts[0].data().count,
                newBugs: counts[1].data().count,
                newDevelopments: counts[2].data().count,
            };
        },

        async fetchProposedPriceDevelopments({}, { projectId }) {
            const db = getFirestore();

            const developments = await getDocs(query(collection(db, `Project/${projectId}/Developments`), where('price', '>', 0), where('priceAccepted', '==', false)));
            return developments.docs.map(doc => new Development(doc)).sort((a, b) => a.date - b.date);
        },

        async fetchRecentlyCompleted({}, { projectId }) {
            const db = getFirestore();

            const now = new Date();
            const then = new Date(now.getTime() - 7*24*60*60*1000);

            const [_bugs, _developments] = await Promise.all([
                getDocs(query(collection(db, `Project/${projectId}/CustomerBugs`), where('status', '==', 'completed'), where('completedAt', '>', then))),
                getDocs(query(collection(db, `Project/${projectId}/Developments`), where('status', '==', 'completed'), where('completedAt', '>', then))),
            ]);

            const bugs = _bugs.docs.map(doc => new CustomerBug(doc));
            const developments = _developments.docs.map(doc => new Development(doc));

            const all = bugs.concat(developments);
            all.sort((a, b) => b.completedAt - a.completedAt);
            return all;
        },

        async listenReminders({ state, commit }, { projectId }) {
            if (state._currentRemindersProject == projectId) return;

            const db = getFirestore();
            const unsub = onSnapshot(query(collection(db, `Project/${projectId}/Reminders`), where('completedAt', '==', null)), snapshot => {
                const reminders = snapshot.docs.map(doc => new Reminder(doc));
                reminders.sort((a, b) => {
                    if (a.deadline && !b.deadline) return -1;
                    if (b.deadline && !a.deadline) return 1;
                    if (a.deadline && b.deadline) return a.deadline - b.deadline;
                    return a.createdAt - b.createdAt;
                });
                commit('cacheReminders', { projectId, reminders });
            });
            commit('cacheRemindersUnsub', unsub);
        },

        //-----------------------------------------------------------
        //------------------------- POST ----------------------------
        //-----------------------------------------------------------

        async postComment({ commit, rootState }, { parent, comment, dev }) {
            const db = getFirestore();
            const uid = rootState.User.currentUser.id;

            let d = await addDoc(collection(db, `${parent.path}/${dev ? 'DeveloperComments' : 'Comments'}`), {
                message: comment,
                date:    serverTimestamp(),
                name:    rootState.User.currentUser.name,
                uid:     rootState.User.currentUser.id,
            });

            let document =  await getDoc(d);

            let newComment = new Comment(document);
            commit('addComment', { parent, comment: newComment, dev });

            // Increment the read counts for the item and project to avoid making the comment "unread"
            updateDoc(parent.ref, {
                [`readComments_${uid}`]: increment(1),
            });
            updateDoc(doc(db, `Project/${parent.projectId ?? parent.id}`), {
                [`readComments_${uid}`]: increment(1),
            });
        },

        async postWorkSession({ commit, rootState }, { parent, date, hours }) {
            const db = getFirestore();

            const projectId = parent.path.match(/^Project\/([^/]+)\//)[1];

            let d = await addDoc(collection(db, `${parent.path}/WorkSessions`), {
                date,
                name: rootState.User.currentUser.name,
                uid: rootState.User.currentUser.id,
                hours,
                title: parent.name ?? parent.title,
                project: projectId,
            });

            let document =  await getDoc(d);

            let session = new WorkSession(document);
            commit('addWorkSession', { parent, session });


        },

        async postInvoice({}, { project, tasks }) {
            const db = getFirestore();

            const data = {
                date: serverTimestamp(),
                tasks: tasks.map(task => ({
                    id: task.itemId,
                    price: task.price,
                    hours: task.totalHours,
                })),
            };

            const docRef = doc(collection(db, project.path + '/Invoices'));

            await runTransaction(db, async transaction => {
                for (let task of tasks) {
                    transaction.update(task.ref, { invoiced: true });
                }

                transaction.set(docRef, data);
            });

            return getDoc(docRef);
        },

        async postUserBug({}, { projectId, title, description }) {
            const db = getFirestore();

            const data = {
                title,
                description,
                date: serverTimestamp(),
                status: 'unhandled',
            };

            const newDoc = await addDoc(collection(db, `Project/${projectId}/UserBugs`), data);

            return newDoc.id;
        },

        //-----------------------------------------------------------
        //------------------------- ADD -----------------------------
        //-----------------------------------------------------------

        async addCustomerBug({}, { projectId, title, description, urgency }) {
            const db = getFirestore();

            let document = await addDoc(collection(db, `Project/${projectId}/CustomerBugs/`), {
                title,
                description,
                urgency,
                status: "unhandled",
                date: serverTimestamp(),
            });

            return document.id;
        },

        async addDevelopment({}, { projectId, title, description, priority }) {
            const db = getFirestore();

            let document = await addDoc(collection(db, `Project/${projectId}/Developments/`), {
                title,
                description,
                priority,
                price: null,
                priceAccepted: false,
                status: "unhandled",
                date: serverTimestamp(),
            });

            return document.id;
        },

        async addProject({ dispatch }, {projectName}){
            const db = getFirestore();
            
            let ret = await addDoc(collection(db, `Project/`),{
                name:         projectName,
                isActive:     true,
                date:         serverTimestamp(),
                access:       [],
                devs:         [],
            });

            dispatch('fetchActiveProjects', { force: true });
        },

        async addAccessToProject({ commit, dispatch, rootState }, { projectId, email, developer=false }) {
            const db = getFirestore();

            const lowerCaseEmail = email.toLowerCase();

            if (rootState.User.currentUser.isAdmin) {
                let user = await dispatch('User/fetchUserByEmail', lowerCaseEmail, {root: true});
                if (!user) return false;

                await setDoc(doc(db,`Project/${projectId}/Accesses/`, user.id), {});
                await setDoc(doc(db,`User/${user.id}/Access/`, projectId), {});

                const data = { access: arrayUnion(user.id) };
                if (developer) data.devs = arrayUnion(user.id);
                await updateDoc(doc(db, `Project/${projectId}`), data);

                commit('addProjectAccess', { projectId, user });
            } else {
                const functions = getFunctions(undefined, 'europe-west1');
                const addUser = httpsCallable(functions, 'project-adduser');

                const result = await addUser({ projectId, email, developer });
                if (result.status == 'error') return false;
                const id = result.data.value;

                const user = await dispatch('User/fetchUserById', id, { root: true });

                commit('addProjectAccess', { projectId, user });
            }

            return true;
        },

        async addReminder({}, { projectId, text, deadline, link }) {
            const db = getFirestore();

            await addDoc(collection(db, `Project/${projectId}/Reminders`), {
                text,
                deadline: deadline || null,
                link: link || null,
                createdAt: serverTimestamp(),
                completedAt: null,
            });
        },

        //-----------------------------------------------------------
        //------------------------ Remove ---------------------------
        //-----------------------------------------------------------

        // Remove any single document.
        // Does not cache or return anything.
        // To delete something that should be cached in the store, use (write if needed) a specific method for that.
        async remove({}, report) {
            await deleteDoc(report.doc.ref);
        },

        //Remove user from projects accesslist
        async removeUserAccessFromProject({ rootState }, { projectId, userId }){
            const db = getFirestore();

            if (rootState.User.currentUser.isAdmin) {
                await deleteDoc(doc(db, `Project/${projectId}/Accesses/${userId}/`));
                await deleteDoc(doc(db, `User/${userId}/Access/`, projectId));
                await updateDoc(doc(db, `Project/${projectId}`), { access: arrayRemove(userId), devs: arrayRemove(userId) });
            } else {
                const functions = getFunctions(undefined, 'europe-west1');
                const removeUser = httpsCallable(functions, 'project-removeuser');

                //commit('removeProjectAccess', { projectId, user: { id: userId } });
                await removeUser({ projectId, userId });
            }
        },

        async removeProject({ dispatch }, {projectId, accesses}){
            const db = getFirestore();

            //Set the project status to false
            await updateDoc(doc(db, `Project/${projectId}/`), {
                isActive: false
             });

            //Remove the user accesses from User and Project
            for (let i = 0; i < accesses.length; i++) {
                await deleteDoc(doc(db, `User/${accesses[i].id}/Access/${projectId}/`));
                await deleteDoc(doc(db, `Project/${projectId}/Accesses/${accesses[i].id}/`));
            }

            return dispatch('fetchActiveProjects', { force: true });
        },

        //-----------------------------------------------------------
        //------------------------ Update ---------------------------
        //-----------------------------------------------------------

        async updateStatus({}, { item, status, urgency, priority }) {
            const db = getFirestore();

            const data = {};
            if (status !== undefined) data.status = status;
            if (urgency !== undefined) data.urgency = urgency;
            if (priority !== undefined) data.priority = priority;

            await updateDoc(doc(db, item.path), data);
        },

        async updateTitle({}, { item, title }) {
            const db = getFirestore();

            await updateDoc(doc(db, item.path), { title });
            item.data.title = title;
        },

        async updateDescription({}, { item, description }) {
            const db = getFirestore();

            await updateDoc(doc(db, item.path), { description });
            item.data.description = description;
        },

        async updateEstimatedHours({}, { item, estimatedHours }) {
            const db = getFirestore();
            await setDoc(doc(db, item.path + '/CostInfo/costinfo'), {
                estimatedHours,
            }, { merge: true });
        },

        async updatePrice({}, { item, price, accepted = false }) {
            const db = getFirestore();
            await updateDoc(doc(db, item.path), { price, priceAccepted: price == 0 || accepted });
        },

        async confirmPrice({}, { item }) {
            const db = getFirestore();
            await updateDoc(doc(db, item.path), { priceAccepted: true });
        },

        async updateProjectName({}, { project, name }) {
            const db = getFirestore();

            await updateDoc(doc(db, `Project/${project.id}/`), {
                name,
            });
            project.data.name = name;
        },

        async updatePriceEstimate({}, { project, price }) {
            const db = getFirestore();

            await updateDoc(doc(db, `Project/${project.id}/`), {
                priceEstimate: price,
            });
        },

        async updatePricePerHour({}, { project, price }) {
            const db = getFirestore();

            await updateDoc(doc(db, `Project/${project.id}/`), {
                pricePerHour: price,
            });
        },

        markCommentsAsRead({ rootState }, { parent }) {
            const uid = rootState.User.currentUser.id;
            const unread = parent.getUnreadCommentCount(uid);

            const db = getFirestore();

            updateDoc(parent.ref, {
                [`readComments_${uid}`]: parent.commentCount,
            });
            updateDoc(doc(db, `Project/${parent.projectId}`), {
                [`readComments_${uid}`]: increment(unread),
            });
        },

        async completeReminder({}, reminder) {
            await updateDoc(reminder.ref, { completedAt: serverTimestamp() });
        },

        async updateWorkSession({ commit }, { session, parent, date, hours }) {
            await updateDoc(session.ref, {
                date,
                hours,
            });

            let document =  await getDoc(session.ref);

            let newSession = new WorkSession(document);
            commit('updateWorkSession', { parent, session: newSession });
        },

        //-----------------------------------------------------------
        //------------------------- Move ----------------------------
        //-----------------------------------------------------------

        async saveUserBugInternal({ rootState }, { report, data, comment, collectionName }) {
            const db = getFirestore();
            const docRef = doc(collection(db, report.path.replace(/UserBugs\/.*$/, collectionName)));
            const commentRef = doc(collection(db, docRef.path + '/Comments'));

            const uid = rootState.User.currentUser.id;

            await runTransaction(db, async transaction => {
                transaction.set(docRef, data);

                transaction.delete(report.ref);

                if (comment && comment.trim() != '') {
                    transaction.set(commentRef, {
                        message: comment,
                        date: serverTimestamp(),
                        name: rootState.User.currentUser.name,
                        uid,
                    });

                    transaction.update(docRef, {
                        [`readComments_${uid}`]: increment(1),
                    });

                    transaction.update(doc(db, `Project/${report.projectId}`), {
                        [`readComments_${uid}`]: increment(1),
                    });
                }
            });

            return docRef.id;
        },

        async saveUserBugAsCustomerBug({ dispatch }, { report, urgency, comment }) {
            return dispatch('saveUserBugInternal', {
                report,
                comment,
                collectionName: 'CustomerBugs',
                data: {
                    title: report.title,
                    description: report.description,
                    date: serverTimestamp(),
                    status: 'unhandled',
                    urgency,
                },
            });
        },

        async saveUserBugAsDevelopment({ dispatch }, { report, priority, comment }) {
            return dispatch('saveUserBugInternal', {
                report,
                comment,
                collectionName: 'Developments',
                data: {
                    title: report.title,
                    description: report.description,
                    date: serverTimestamp(),
                    status: 'unhandled',
                    priority,
                    price: null,
                    priceAccepted: false,
                },
            });
        },

        //-----------------------------------------------------------
        //------------------------ Assign ---------------------------
        //-----------------------------------------------------------

        async assignToReport({}, { item, userId }) {
            await updateDoc(item.ref, {
                assignedTo: arrayUnion(userId),
            });
        },

        async unassignFromReport({}, { item, userId }) {
            await updateDoc(item.ref, {
                assignedTo: arrayRemove(userId),
            });
        },
    },
}