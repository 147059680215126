import FirestoreType from './FirestoreType'

function countTests(item) {
    if (item.type == 'test') return 1;
    if (item.type == 'category') return item.items.reduce((a, n) => a + countTests(n), 0);
}

export default class TestRun extends FirestoreType {
    get name() { return this.data.name }
    get plan() { return this.data.plan }
    get createdAt() { return this.data.createdAt.toDate() }
    get createdBy() { return this.data.createdBy }
    get createdByName() { return this.data.createdByName }
    get items() { return this.data.items }
    get status() { return this.data.status }

    get isRun() { return true }

    get totalTests() {
        return this.items.reduce((a, n) => a + countTests(n), 0);
    }

    get percentCompleted() {
        const completed = Object.keys(this.status).reduce((a, n) => a + (n == 'none' || n == 'running' ? 0 : this.status[n]), 0);
        return Math.floor(10000 * completed / this.totalTests) / 100;
    }

    get percentOk() {
        const total = Object.keys(this.status).reduce((a, n) => a + (n != 'skipped' ? this.status[n] : 0), 0);
        const ok = Object.keys(this.status).reduce((a, n) => a + ((n == 'ok' || n == 'ok_note') ? this.status[n] : 0), 0);
        return Math.floor(10000 * ok / total) / 100;
    }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}
