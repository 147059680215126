<template>
  <base-popup @close="cancel">
    <div class="container">
      <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M36.9998 67.8332C54.0286 67.8332 67.8332 54.0286 67.8332 36.9998C67.8332 19.9711 54.0286 6.1665 36.9998 6.1665C19.9711 6.1665 6.1665 19.9711 6.1665 36.9998C6.1665 54.0286 19.9711 67.8332 36.9998 67.8332Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37 24.6665V36.9998" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M37 49.3335H37.0308" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="big-text">Er du sikker?</p>
      <p>Du vil ikke kunne angre på dette</p>
      <div class="buttons">
        <button class="red" @click="cancel">Nei</button>
        <button @click="confirm">Ja</button>
      </div>
    </div>
  </base-popup>
</template>

<script>
export default {
  emits: ['close', 'confirm'],
  data() {
    return {

    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    p {
      font-size: 16px;
    }
    .big-text {
      font-size: 36px;
      margin: 0;
    }
    
    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 75%;
  
      button {
        font-size: 24px;
        border: none;
        background: none;
      }
      .red {
        color: red;
      }
  }
  }
</style>