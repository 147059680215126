import FirestoreType from './FirestoreType'

export default class TestInstance extends FirestoreType {
    get name() { return this.data.name }
    get prerequisites() { return this.data.prerequisites }
    get steps() { return this.data.steps }
    get expectedOutcome() { return this.data.expectedOutcome }
    get status() { return this.data.status }
    get runBy() { return this.data.runBy }
    get runByName() { return this.data.runByName }
    get comments() { return this.data.comments }

    set comments(val) { this.data.comments = val }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}