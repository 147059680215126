<template>
    <div class="comments" :class="{developer: developer}">
        <div class="comment-list" ref="commentList">
            <div v-for="comment in comments" :key="comment.id" class="comment" :class="{ me: comment.uid == user.id }">
                <div class="message">
                    <p v-for="line in lines(comment.message)">{{line}}</p>
                </div>
                <p class="sender">{{comment.name}}, {{comment.date.toLocaleString().split(',')[0]}}</p>
            </div>
        </div>
        <div class="comment-container">
            <TextBox @comment="postComment($event)" />
        </div>
    </div>
</template>


<script>
import { serverTimestamp } from 'firebase/firestore';
import TextBox from './Comments/TextBox'

export default {
    name: 'CustomerReportComment',
    components: {
        TextBox,
    },
    props: {
        parent: {
            type: Object,
            required: true,
        },
        developer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        user() {
            return this.$store.state.User.currentUser;
        },
        comments() {
            const comments = this.$store.state.Project[this.developer ? 'developerComments' : 'comments'][this.parent.path];
            if (!comments) return [];
            return comments;
        },
    },
    methods: {
        lines(message) {
            return message.split('\n').map(l => l.length > 0 ? l : '\u00A0');
        },
        postComment(comment) {
            return this.$store.dispatch(`Project/postComment`, 
                {
                    parent: this.parent,
                    comment,
                    dev: this.developer,
                }
            );
        },
    },
    watch: {
        comments: {
            handler() {
                this.$refs.commentList.scrollTo(0, this.$refs.commentList.scrollHeight);
            },
            deep: true,
        },
    },
    mounted() {
        this.$store.dispatch('Project/fetchComments', { parent: this.parent, dev: this.developer });
        if (this.comments.length > 0) this.$refs.commentList.scrollTo(0, this.$refs.commentList.scrollHeight);
    },
}
</script>


<style lang="scss" scoped>
.comments {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 15px;
    gap: 15px;

    .comment-list {
        overflow-y:auto;

        .comment {
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .message {
                background: #F1F1F1;
                border-radius: 20px 20px 20px 0;
                padding: 15px;
                max-width: 70%;

                p {
                    margin: 0;
                }
            }

            &.me {
                align-items: flex-end;
                padding-right: 20px;

                .message {
                    border-radius: 20px 20px 0 20px;
                    background: lightgrey;
                }
            }

            .sender {
                margin-top: 0;
            }
        }
    }

    .comment-container {
        display: flex;
        gap: 8%;
    }

    &.developer .comment-list .message {
        /* background: #C7A48B; */
        /* color: w hite; */
    }
}
</style>