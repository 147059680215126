<template>
    <div class="main">
        <div class="header">
            <h1><slot name="title"></slot></h1>
            <base-button v-if="allowCreate" buttonType="new" @clicked="$emit('new')"><slot name="new-button"></slot></base-button>
        </div>
        <base-card class="padd">
            <div class="assigned-status" v-if="statusTabs">
                <span>Vis:</span>
                <dropdown v-model="assignedStatus">
                    <div value="all">Alle oppgaver</div>
                    <div value="mine">Mine oppgaver</div>
                    <div value="unassigned">Utildelte oppgaver</div>
                </dropdown>
            </div>
            <div class="tabs" v-if="statusTabs">
                <h2 :class="{ selected: selectedStatus == 'all'}" @click="selectStatus('all')">Alle</h2>
                <h2 :class="{ selected: selectedStatus == 'unhandled'}" @click="selectStatus('unhandled')">Uhåndtert</h2>
                <h2 :class="{ selected: selectedStatus == 'ongoing'}" @click="selectStatus('ongoing')">Pågår</h2>
                <h2 :class="{ selected: selectedStatus == 'completed'}" @click="selectStatus('completed')">Ferdig</h2>
            </div>
            <div class="item-list">
                <div class="empty" v-if="selectedItems.length == 0">
                    <h2>Ingen{{ selStatus }} <span class="lc-title"><slot name="title"></slot></span></h2>
                </div>
                <template v-else>
                    <div class="item-grid" :class="{ border: selectedStatus == 'all' && statusTabs }" :style="gridStyle">
                        <h3>Tittel</h3>
                        <h3>Dato</h3>
                        <h3 v-for="field in extraFields">{{ field }}</h3>
                        <h3>Timer brukt</h3>
                        <h3 class="last">Alternativer</h3>
                    </div>
                    <template v-if="selectedStatus == 'all' && statusTabs">
                        <h4 v-if="selectedItems('unhandled', assignedStatus).length > 0">Uhåndtert</h4>
                        <template v-if="selectedItems('unhandled', assignedStatus).length > 0" v-for="item in selectedItems('unhandled', assignedStatus)">
                            <report-item :key="item.id" :item="item" @action="$emit($event, item)" :actions="actions" :grid-style="gridStyle">
                                <template v-slot="data">
                                    <slot name="item-extra" :item="data.item"></slot>
                                </template>
                            </report-item>
                        </template>
                        <h4 v-if="selectedItems('ongoing', assignedStatus).length > 0">Pågår</h4>
                        <template v-if="selectedItems('ongoing', assignedStatus).length > 0" v-for="item in selectedItems('ongoing', assignedStatus)">
                            <report-item :key="item.id" :item="item" @action="$emit($event, item)" :actions="actions" :grid-style="gridStyle">
                                <template v-slot="data">
                                    <slot name="item-extra" :item="data.item"></slot>
                                </template>
                            </report-item>
                        </template>
                        <h4 v-if="selectedItems('completed', assignedStatus).length > 0">Ferdig</h4>
                        <template v-if="selectedItems('completed', assignedStatus).length > 0" v-for="item in selectedItems('completed', assignedStatus)">
                            <report-item :key="item.id" :item="item" @action="$emit($event, item)" :actions="actions" :grid-style="gridStyle">
                                <template v-slot="data">
                                    <slot name="item-extra" :item="data.item"></slot>
                                </template>
                            </report-item>
                        </template>
                    </template>
                    <template v-else>
                        <report-item v-for="item in selectedItems(selectedStatus, assignedStatus)" :key="item.id" :item="item" @action="$emit($event, item)" :actions="actions" :grid-style="gridStyle">
                            <template v-slot="data">
                                <slot name="item-extra" :item="data.item"></slot>
                            </template>
                        </report-item>
                    </template>
                </template>
            </div>
        </base-card>
    </div>
</template>

<script>
import ReportItem from './Overview/ReportItem.vue';
import Dropdown from '@/components/Dropdown.vue';

export default {
    name: 'CustomerBugOverview',
    components: {
        ReportItem,
        Dropdown,
    },
    props: {
        extraFields: {
            type: Array,
            default: () => [],
        },
        gridColumns: {
            type: String,
            default: null,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            required: true,
        },
        statusTabs: {
            type: Boolean,
            default: false,
        },
        allowCreate: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        selectedStatus: 'all',
        assignedStatus: 'all',
    }),
    computed: {
        unhandledItems() {
            return this.items.filter(item => item.status == 'unhandled');
        },
        ongoingItems() {
            return this.items.filter(item => item.status == 'ongoing');
        },
        completedItems() {
            return this.items.filter(item => item.status == 'completed');
        },
        gridStyle() {
            return this.gridColumns ? this.gridColumns : `grid-template-columns: 1fr 1fr ${this.extraFields.map(f => '1fr').join(' ')} 0.5fr 1fr`;
        },
        selStatus() {
            if (this.selectedStatus === 'unhandled') {
                return ' uhåndterte'
            } else if (this.selectedStatus === 'ongoing') {
                return ' pågående'
            } else if (this.selectedStatus === 'completed') {
                return ' ferdige'
            } else {
                return '';
            }
        }
    },
    methods: {
        selectedItems(selectedStatus, assignedStatus) {
            if (!this.statusTabs) return this.items;
            let items;
            switch (selectedStatus) {
                case 'unhandled': items = this.unhandledItems; break;
                case 'ongoing': items = this.ongoingItems; break;
                case 'completed': items = this.completedItems; break;
                default: items = this.items; break;
            }
            switch (assignedStatus) {
                case 'mine': items = items.filter(item => item.assignedTo && item.assignedTo.indexOf(this.$store.state.User.currentUser.id) != -1); break;
                case 'unassigned': items = items.filter(item => item.assignedTo === null || item.assignedTo === undefined || item.assignedTo.length == 0); break;
            }
            return items;
        },
        selectStatus(status) {
            this.selectedStatus = status;
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .padd {
        padding: 50px 40px;
        flex-grow: 1;
    }
    h4 {
        /* font-weight: normal; */
        text-align: left;
        text-decoration: underline;
    }
}

.assigned-status {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > span {
        font-weight: bold;
    }
}

.tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-bottom: 1px solid grey;
    margin-bottom: 15px;

    h2 {
        font-size: 20px;
        color: gray;
        cursor: pointer;
        margin-top: 0;
    }

    .selected {
        color: black;
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .lc-title {
        text-transform: lowercase;
    }
}

.item-grid {
    display: grid;
    gap: 10px;
    /*grid-template-columns: 1fr 1fr 1fr 1fr 1fr;*/
    align-items: center;

    &.border {
        border-bottom: 1px solid lightgrey;
        padding-bottom: 10px;
    }

    .last {
        justify-self: end;
        padding-right: 25px;
    }

    h3 {
        margin: 0
    }
}

.empty {
    text-align: center;

    h2 {
        font-size: 16px;
        font-weight: bold;
    }
}
</style>