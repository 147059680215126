<template>
    <div class="main" v-if="report">
        <div class="page-header">
            <h1><slot name="title"></slot> <i class="fa fa-pencil" v-if="isDev" @click="editTitle" /></h1>
            <div class="corner-element">
                <slot name="corner-element">
                    <create-reminder-button v-if="isDev" :projectId="$route.params.projectId" :link="pathName">Legg til påminnelse</create-reminder-button>
                    <div class="add-work-session" v-if="isDev && hasWorkSessions" @click="addHours">Legg til arbeidsøkt</div>
                </slot>
            </div>
        </div>
        <div class="report-grid">
            <div class="left-side">
                <base-card class="header">
                    <slot name="details"></slot>
                    <div class="assign" v-if="isDev">
                        <span class="assign-title">Tildelt:</span>
                        <div class="assigned-users">
                            <div v-for="user in assignedUsers" class="assigned-user">
                                <span>{{ user.name }}</span>
                                <i class="fa fa-times" @click="unassignUser(user.id)" />
                            </div>
                            <dropdown @input="assignUser" :value="null">
                                <div :value="null">Tildel en bruker...</div>
                                <div v-for="user in assignableUsers" :value="user.id">{{ user.name }}</div>
                            </dropdown>
                        </div>
                    </div>
                </base-card>

                <base-card class="description">
                    <div class="title desc">
                        <h2>Beskrivelse</h2>
                        <base-button v-if="!editingDescription" buttonType="new" @clicked="editDescription">Rediger <i class="fa fa-pencil"></i></base-button>
                        <base-button v-else buttonType="new" @clicked="saveDescription">Lagre</base-button>
                    </div>
                    <p v-if="!editingDescription">{{ report.description }}</p>
                    <textarea v-else v-model="newDescription"></textarea>
                </base-card>

                <base-card class="attachment">
                    <div class="attachment-title title">
                        <h2>Vedlegg</h2>
                        <base-button buttonType="new" @clicked="uploadFiles">Last opp</base-button>
                    </div>
                    <div class="attachment-list">
                        <a v-for="file in files" :href="file.url" target="_blank"><i class="fa fa-file" />{{ file.name }}</a>
                    </div>
                </base-card>
            </div>

            <div class="right-side">
                <slot name="right-side">
                    <base-card class="chat">
                        <div class="title chat-title">
                            <h2
                                v-if="isDev"
                                @click="showCommentsTab = false"
                                :class="{ unselectedTab: showCommentsTab }"
                            >
                                Arbeidsøkter
                            </h2>
                            <h2
                                @click="showUserComments"
                                :class="{ unselectedTab: !showCommentsTab || showDevCommentsTab }"
                            >
                                Meldinger
                            </h2>
                            <h2
                                v-if="isDev"
                                @click="showDevComments"
                                :class="{ unselectedTab: !showCommentsTab || !showDevCommentsTab }"
                            >
                                Utvikler
                            </h2>
                        </div>
                        <div class="comms" v-if="showCommentsTab">
                            <Comments key="user-comments" v-show="!showDevCommentsTab" :parent="report" />
                            <Comments key="dev-comments" developer v-show="showDevCommentsTab" v-if="isDev" :parent="report" />
                        </div>
                        <div v-else class="work-sessions-container">
                            <div class="work-sessions-wrapper">
                                <table class="work-sessions">
                                    <tr class="headers">
                                        <th>Hvem</th>
                                        <th>Dato</th>
                                        <th>Timer</th>
                                    </tr>
                                    <tr v-for="session in workSessions">
                                        <td>{{ session.name }}</td>
                                        <td>{{ session.date.toLocaleString().split(',')[0] }}</td>
                                        <td>{{ session.hours }} <i class="fa fa-pencil" @click="editHours(session)"></i></td>
                                    </tr>
                                </table>
                            </div>
                            <div class="work-sessions-sum"><span>Sum i timer</span><span>{{ totalHours }}</span></div>
                        </div>
                    </base-card>
                </slot>
            </div>
        </div>

        <input-dialog v-model="editTitleModal" ref="editTitleModal">
            <template #title>Endre tittel</template>
        </input-dialog>
        <input-dialog type="number" placeholder="Timer" v-model="addHoursModal" :validate="validateHours" ref="hoursModal">
            <template #title v-if="editingHours">Endre timer</template>
            <template #title v-else>Legg til timer</template>
            <template #text>Hvor lang tid har du brukt?</template>
            <template #button>Neste</template>
        </input-dialog>
        <input-dialog type="date" placeholder="Dato" v-model="addHoursDateModal" ref="dateModal">
            <template #title>Velg dato</template>
            <template #text>Hvilken dato ble arbeidet gjort?</template>
            <template #button v-if="editingHours">Lagre</template>
            <template #button v-else>Legg til</template>
        </input-dialog>
    </div>
</template>

<script>
// Used for customer bug reports, developments and user bug reports

import Comments from '@/components/Comments.vue'
import InputDialog from '@/components/Modal/InputDialog.vue'
import CreateReminderButton from '@/components/CreateReminderButton.vue'
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: "ReportDetails",
    components: {
        Comments,
        InputDialog,
        CreateReminderButton,
        Dropdown,
    },

    props: {
        report: {
            type: Object,
            default: null,
        },
        hasWorkSessions: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showDevCommentsTab: false,
            showCommentsTab: true,

            attachment: null,
            showFile: false,
            showImage: false,

            files: [],

            editingDescription: false,
            newDescription: '',

            editTitleModal: false,
            addHoursModal: false,
            addHoursDateModal: false,
            editingHours: false,

            assignedTo: [],
        };
    },

    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        isDev() {
            if (this.isAdmin) return true;
            if (!this.$store.state.User.currentUser) return false;
            const project = this.$store.state.Project.projectsList.find(proj => proj.id == this.report.projectId);
            if (!project) return false;
            return project.devs.includes(this.$store.state.User.currentUser.id);
        },
        workSessions() {
            const sessions = this.$store.state.Project.workSessions[this.report.path];
            if (!sessions) return [];
            return sessions;
        },
        totalHours() {
            return this.workSessions.reduce((a, n) => a + n.hours, 0);
        },
        pathName() {
            return window.location.pathname;
        },
        project() {
            return this.$store.state.Project.projectsList.find(proj => proj.id == this.report.projectId);
        },
        assignedUsers() {
            if (!this.assignedTo) return [];
            if (!this.$store.state.Project.projectAccesses[this.project.id]) return [];
            const ids = new Set(this.assignedTo);
            return this.$store.state.Project.projectAccesses[this.project.id].filter(user => ids.has(user.id));
        },
        assignableUsers() {
            if (!this.$store.state.Project.projectAccesses[this.project.id]) return [];
            const access = new Set(this.$store.state.Project.projectAccesses[this.project.id].map(user => user.id));
            this.assignedTo.forEach(assign => access.delete(assign));
            return this.$store.state.Project.projectAccesses[this.project.id].filter(user => access.has(user.id));
        },
    },
    methods: {
        async saveDescription() {
            await this.$store.dispatch('Project/updateDescription', { item: this.report, description: this.newDescription });
            this.editingDescription = false
        },
        async editTitle() {
            const title =  await this.$refs.editTitleModal.ask(this.report.title);
            if (!title || title.trim().length == 0) return;
            await this.$store.dispatch('Project/updateTitle', { item: this.report, title });
        },
        editDescription() {
            //hente beskrivelse for å redigere
            this.newDescription = this.report.description
            this.editingDescription = true
        },
        showUserComments() {
            this.showCommentsTab = true;
            this.showDevCommentsTab = false;
        },
        showDevComments() {
            this.showCommentsTab = true;
            this.showDevCommentsTab = true;
        },
        async uploadFiles() {
            // TODO Better visual handling of upload, particularly multi-file?
            const files = await new Promise(res => {
                let input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute('multiple', 'multiple');
                input.addEventListener('change', event => res([...event.target.files]));
                input.click(); // opening dialog
            });
            for (let file of files) {
                await this.$store.dispatch('Storage/uploadFile', {
                    item: this.report,
                    file,
                });
            }
            this.files = await this.$store.dispatch('Storage/getFileList', this.report);
        },
        validateHours(value) {
            if (isNaN(value)) return 'Du må skrive inn et tall over 0.';
            if (value <= 0) return 'Du må skrive inn et tall over 0.';
        },
        async addHours() {
            this.editingHours = false;
            const now = new Date();
            const hoursStr = await this.$refs.hoursModal.ask();
            if (hoursStr == null || hoursStr.trim() == '') return;
            const dateStr = await this.$refs.dateModal.ask(`${now.getFullYear()}-${now.getMonth()<9?0:''}${now.getMonth()+1}-${now.getDate()<10?0:''}${now.getDate()}`);
            if (dateStr == null || dateStr.trim() == '') return;
            const hours = parseFloat(hoursStr);
            const date = new Date(dateStr);
            await this.$store.dispatch('Project/postWorkSession', { parent: this.report, date, hours: parseFloat(hours) });
        },
        async editHours(session) {
            this.editingHours = true;
            const hoursStr = await this.$refs.hoursModal.ask(session.hours.toString());
            if (hoursStr == null || hoursStr.trim() == '') return;
            const dateStr = await this.$refs.dateModal.ask(`${session.date.getFullYear()}-${session.date.getMonth()<9?0:''}${session.date.getMonth()+1}-${session.date.getDate()<10?0:''}${session.date.getDate()}`);
            if (dateStr == null || dateStr.trim() == '') return;
            const hours = parseFloat(hoursStr);
            const date = new Date(dateStr);
            await this.$store.dispatch('Project/updateWorkSession', { session, parent: this.report, date, hours: parseFloat(hours) });
        },
        async assignUser(userId) {
            if (!userId) return;

            await this.$store.dispatch('Project/assignToReport', { item: this.report, userId });
            this.assignedTo.push(userId);
        },
        async unassignUser(userId) {
            if (!userId) return;

            await this.$store.dispatch('Project/unassignFromReport', { item: this.report, userId });
            this.assignedTo = this.assignedTo.filter(id => id != userId);
        },
    },

    async mounted() {
        if (this.isDev) this.showCommentsTab = false;
        this.assignedTo = this.report.assignedTo ?? [];
        if (this.isDev) {
            this.$store.dispatch('Project/fetchAccessesForProject', this.project);
            await this.$store.dispatch('Project/fetchWorkSessions', this.report);
        }
        this.files = await this.$store.dispatch('Storage/getFileList', this.report);
        this.newDescription = this.report.description;
    },
};
</script>


<style lang="scss" scoped>
.main {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

h1 i {
    cursor: pointer;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .corner-element {
        display: flex;
        align-items: center;
        gap: 10px;

        .create-reminder-button {
            font-family: Poppins, sans-serif;
            color: #343434;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .add-work-session {
        background: white;
        border: 1px solid lightgrey;
        border-radius: 1000px;
        padding: 10px 30px;
        font-weight: bold;
        cursor: pointer;
    }
}

.report-grid {
    display: flex;
    gap: 15px;
    height: 100%;

    .left-side {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .description {
            flex-grow: 1;
        }
    }

    .right-side {
        width: 40%;

        .chat {
            height: 100%;
        }
    }
}

.header {
    grid-area: head;

    .assign {
        display: flex;
        gap: 30px;
        align-items: center;

        .assign-title {
            font-weight: bold;
        }

        .assigned-users {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            .assigned-user {
                display: flex;
                gap: 5px;
                align-items: center;

                i {
                    cursor: pointer;
                }
            }
        }
    }
}

.description {
    grid-area: desc;
    display: flex;
    flex-direction: column;

    p {
        white-space: pre-wrap;
    }

    textarea {
        width: 100%;
        flex-grow: 1;
    }
}

.desc {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.attachment {
    grid-area: attach;
    max-height: 150px;
    display: flex;
    flex-direction: column;

    p {
        text-decoration: underline;
        cursor: pointer;
    }

    .attachment-list {
        overflow: auto;
    }
}

.chat {
    grid-area: chat;
    display: flex;
    flex-direction: column;
    min-height: 80vh;
}

.comms {
    height: 1px;
    flex-grow: 1;
    overflow: auto;
}

.chat-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.chat-title h2 {
    cursor: pointer;
}

.work-sessions-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .work-sessions-wrapper {
        flex-grow: 1;
        overflow-y: auto;
        height: 1px;

        .work-sessions {
            border-spacing: 0;
            width: 100%;
            flex-grow: 1;

            th {
                border-bottom: 1px solid lightgrey;
                text-align: left;
                padding: 20px 0 5px;
                position: sticky;
                top: 0;
                background: white;
            }

            td {
                padding: 10px 0;
            }

            i {
                transform: scale(1.25);
                margin-left: 10px;
            }
        }
    }

    .work-sessions-sum {
        display: flex;
        justify-content: space-around;
        font-weight: bold;
        border-top: 1px solid lightgrey;
        padding: 15px 0;
    }
}

.unselectedTab {
    color: #a4a4a4;
}

.status-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    position: relative;
}

.status-div .dropdown {
    width: 100%;
}

#s, #u {
    width: 10px;
    height: 10px;
    background: grey;
    border-radius: 50%;
}

.urgency-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.text {
    margin: 0px;
    font-size: 1vw;
}

.info {
    display: flex;
    justify-content: space-between;
    margin-left: 2%;
    margin-right: 2%;
    font-size: 18px;
}

.date {
    white-space: nowrap;
}

.priority {
    white-space: nowrap;
}

.title {
    border-bottom: solid 1px #dcdcdc;
}

h2 {
    font-size: 16px;
}

/* STATUS AND CHANGE STATUS */
.status {
    display: flex;
    margin-right: 5%;
    white-space: nowrap;
}

.change-status {
    border: 1px solid white;
    margin-left: 10px;
    padding: 0px 5px;
}

.change-status:hover {
    cursor: pointer;
}

.fa-angle-down {
    font-size: 24px;
    margin-left: 10px;
}

.fa-angle-down:hover {
    cursor: pointer;
}

.wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1;
    /* background-color: grey; */
}

.modal {
    position: absolute;
    z-index: 10;
    height: 135%;
    top: 0;
    left: 5%;
    background: none;
    border: black 1px solid;
    box-sizing: border-box;
    background: white;
}

.modal p {
    margin: 0;
    cursor: pointer;
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    border-top: gray solid 1px;
}

.changeComments {
    width: 100%;
    display: inline-flex;
    justify-content: space-around;
}

.kunde {
    border-bottom: 1px solid black;
    cursor: pointer;
}

.dev {
    border-bottom: 1px solid black;
    cursor: pointer;
}

.kunde2 {
    cursor: pointer;
}

.dev2 {
    cursor: pointer;
}

/* CLOSE BUTTON */
.crossIcon {
    font-size: 30px;
    margin-top: 11px;
    position: absolute;
    right: 50px;
    top: 110px;
}

.crossIcon:hover {
    cursor: pointer;
    color: rgb(207, 207, 207);
}

@media screen and (max-width: 1136px) {
    .crossIcon {
        top: 50px;
    }
}

/* DESCRIPTION */
.description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    background: #ede7e3;
    padding: 2.5%;
    font-size: 20px;
    font-weight: 400;
    font-family: "Poppins";
}

.description-title {
    font-weight: 500;
    transition: font-size 2s;
}

/* .description {
            border: 1px solid rgb(150, 150, 150);
            padding: 1%;
            margin-right: 15%;
            background: white;
            width: 100%;
            height: 250px;
            overflow-y: scroll;
            overflow-x: hidden;
            white-space: pre-line;
            white-space: pre-wrap;
            transition: margin-right 1s;
        } */

/* Scrollbar */
::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #a0a0a0;
}

::-webkit-scrollbar-thumb {
    background: rgb(231, 231, 231);
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(238, 238, 238);
}

@media screen and (max-width: 750px) {
    .description-title {
        font-size: 12px;
    }

    .description {
        margin-right: 0%;
    }
}

/* .attachment {
        background: #EDE7E3;
        padding: 0 2.5% 2.5% 2.5%;
        display: flex;
        align-items: center;
        gap: 13%;
    } */

.attachment-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attachment-text {
    margin: 0px;
    font-weight: 500;
    font-size: 20px;
    font-family: "Poppins";
}

.attachment-file {
    font-size: 18px;
    font-family: "Poppins";
    border-bottom: 1px solid grey;
}

.attachment-file:hover {
    color: #646363;
    cursor: pointer;
}

.attachment-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;

    a {
        color: black;

        i {
            font-size: 1.5em;
            margin-right: 25px;
        }
    }
}

.background {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1;
    background-color: grey;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.pdf {
    width: 50vw;
    height: 45vw;
}

/* COMMENTS */

.comments-container {
    margin-bottom: 100px;
    margin-left: 6.5%;
}

@media screen and (max-width: 1385px) {
    .text {
        font-size: 14px;
    }
}
</style>