import { getDownloadURL, getStorage, ref, uploadBytes, getMetadata, listAll } from "firebase/storage";
import { getAuth, getIdTokenResult } from 'firebase/auth'
import { getFunctions, httpsCallable } from "firebase/functions"

export default {
    namespaced: true,
    state: () => ({
        
    }),

    mutations: {
        
    },
    
    actions: {
        async ensureClaim({}, item) {
            const projectId = item.path.match(/^\/?Project\/([^/]+)\//)[1];

            const { currentUser } = getAuth();
            const token = await getIdTokenResult(currentUser);
            if (!token.claims.developer && (!token.claims.access || !token.claims.access[projectId])) {
                const functions = getFunctions(undefined, 'europe-west1');
                const setClaims = httpsCallable(functions, 'user-setclaims');
                await setClaims();
                await getIdTokenResult(currentUser, true);
            }
        },

        async getFileList({ dispatch }, item) {
            await dispatch('ensureClaim', item);

            const storage = getStorage();
            const dirRef = ref(storage, item.path);

            const list = await listAll(dirRef);
            return Promise.all(list.items.map(async item => ({
                name: item.name,
                url: await getDownloadURL(item),
            })));
        },

        async uploadFile({ dispatch }, { item, file }) {
            await dispatch('ensureClaim', item);

            const storage = getStorage();
            const fileRef = ref(storage, `${item.path}/${file.name}`);
            await uploadBytes(fileRef, file);
        },

        async uploadUserBugFile({}, { projectId, id, file }) {
            const storage = getStorage();
            const fileRef = ref(storage, `Project/${projectId}/UserBugs/${id}/${file.name}`);
            await uploadBytes(fileRef, file);
        },
    },
}