<template>
    <div class="big">
        <sidebar></sidebar>
        <div class="content-wrapper">
            <div class="content">
                <div class="content-inner">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// Router view containing the nav menu

import Sidebar from '@/components/Sidebar.vue';
export default {
components: { Sidebar },
    name: 'NavView',
}
</script>

<style lang="scss" scoped>
.big {
    display: flex;
    flex-direction: row;
}

.content-wrapper {
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.content {
    background:#F6F6F6;
    flex-grow: 1;
    padding: 15px 50px 30px;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow: auto;
    display: table;

    .content-inner {
        display: table-cell;
        height: 100%;
    }
}
</style>