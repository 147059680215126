<template>
    <div class="item-view">
        <div class="sub-indicator" v-if="level > 0"></div>
        <category-view v-if="item.type == 'category'"
            :category="item"
            :editing="editing"
            :level="level"
            @open="$emit('open', $event)"
            @addCategory="$emit('addCategory', $event)"
            @addTest="$emit('addTest', $event)"
            @deleteCategory="$emit('deleteCategory', $event)"
        />
        <single-test-view v-if="item.type == 'test'"
            :test="item"
            :editing="editing"
            :level="level"
            @open="$emit('open', $event)"
        />
    </div>
</template>

<script>
import CategoryView from './CategoryView.vue'
import SingleTestView from './SingleTestView.vue'

export default {
    name: 'ItemView',
    emits: ['open'],
    components: {
        CategoryView,
        SingleTestView,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.item-view {
    display: flex;
    margin: 5px 0;

    .indent {
        width: 15px;
        height: 10px;
    }

    .sub-indicator {
        width: 15px;
        height: 10px;
        //border-bottom: 1px solid black;
        //border-left: 1px solid black;
    }
}
</style>