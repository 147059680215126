<template>
    <div id="app">
        <router-view :key="$route.fullPath"></router-view>
        <dropdown-overlay />
    </div>
</template>

<script>
import DropdownOverlay from '@/components/DropdownOverlay.vue'

export default {
    components: {
        DropdownOverlay,
    },
    created() {
        this.$store.dispatch('User/onLoad');
    },
}
</script>

<style>
#app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #343434;
    font-size: 14px;
}
h2, h3 {
    font-size: 14px;
}
h1 {
    font-size: 20px;
}
html {
    overflow-y: hidden;
}
</style>
