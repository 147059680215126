<template>
    <editable-value type="number" :value="displayValue" @input="priceChanged" :validate="validatePrice" :disabled="disabled" @open="$emit('open')">
        <template #title><slot name="title">Pris</slot></template>
        <template #text><slot name="text">Skriv inn pris:</slot></template>
        <template #value>{{ displayPrice }}</template>
    </editable-value>
</template>

<script>
import EditableValue from '@/components/EditableValue.vue'

export default {
    name: 'EditablePrice',
    components: {
        EditableValue,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        value: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        displayValue: 0,
    }),
    computed: {
        displayPrice() {
            if (this.displayValue == 0) return '0,-';

            let whole = Math.floor(this.displayValue);
            let dec = this.displayValueprice - whole;

            const out = [];
            while (whole > 1000) {
                let next = `${whole % 1000}`;
                while (next.length < 3) next = `0${next}`;
                out.push(next);
                whole = Math.floor(whole / 1000);
            }
            out.push(whole);
            out.reverse();
            return out.join(' ') + ',' + (dec ? dec : '-');
        },
    },
    methods: {
        validatePrice(price) {
            const val = parseFloat(price);
            if (isNaN(val) || val < 0) return 'Du må skrive minst 0.';
        },
        async priceChanged(priceStr) {
            const price = parseFloat(priceStr);
            this.displayValue = price;
            this.$emit('input', this.displayValue);
            this.$emit('price', this.displayValue);
        },
        async loadValue() {
            this.displayValue = this.value;
        },
    },
    watch: {
        item: {
            handler() {
                this.loadValue();
            },
            immediate: true,
        },
        value: {
            handler() {
                this.loadValue();
            },
            // Don't need both of these to be immediate.
        },
    },
}
</script>
