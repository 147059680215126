<template>
    <div class="main">
        <input-dialog v-model="creatingPlan" ref="createPlanDialog">
            <template #title>Navn på testplan</template>
            <template #text>Legg til et kort, beskrivende navn for testplanen. Eks: Navn på tjenesten + app</template>
        </input-dialog>
        <confirm-dialog v-model="deletingPlan" ref="deletePlanDialog">
            <template #title>Slett testplan</template>
            <template #text>Er du sikker på at du vil slette {{ deletingPlanName }}?</template>
        </confirm-dialog>
        <div class="header">
            <h1>Testing</h1>
            <base-button buttonType="new" @clicked="createPlan">Opprett ny testplan</base-button>
        </div>
        <div class="flex">
            <base-card>
                <h2>Testkjøringer</h2>
                <div class="item-grid">
                    <h3>Navn</h3>
                    <h3>Startet</h3>
                    <h3>Utført</h3>
                    <h3>Bestått</h3>
                    <h3>Startet av</h3>
                    <h3></h3>
                </div>
                <div class="item-grid" v-for="run in runs" :key="run.id">
                    <p>{{ run.name }}</p>
                    <p>{{ timestr(run.createdAt) }}</p>
                    <p class="percentage">{{ run.percentCompleted }}%</p>
                    <p class="percentage" :class="{ error: run.percentOk < run.percentCompleted }"><status-icon :status="status(run)" /> {{ run.percentOk }}%</p>
                    <p>{{ run.createdByName }}</p>
                    <router-link :to="{name: 'TestRunView', params: {id: run.id, item: run, projectId: projectId}}">Åpne</router-link>
                </div>
            </base-card>
            <base-card class="tin">
                <h2>Testplaner</h2>
                <div class="test-plans">
                    <div class="test-plan" v-for="plan in plans">
                        <router-link :to="{name: 'TestPlanView', params: {id: plan.id, item: plan, projectId: projectId}}">{{ plan.name }}</router-link>
                        <base-button button-type="new" @clicked="deletePlan(plan)">Slett</base-button>
                    </div>
                </div>
            </base-card>
        </div>
    </div>
</template>

<script>
import InputDialog from '@/components/Modal/InputDialog.vue'
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'
import StatusIcon from './TestView/StatusIcon.vue'

import TestPlan from '@/types/TestPlan'

export default {
    props: {
        projectId: String
    },
    components: {
        InputDialog,
        ConfirmDialog,
        StatusIcon,
    },
    data() {
        return {
            creatingPlan: false,
            deletingPlan: false,

            deletingPlanName: '',

            plans: [],
            runs: [],

            plansUnsub: null,
            runsUnsub: null,
        }
    },
    methods: {
        timestr(date) {
            const zp = n => (n < 10 ? '0' : '') + n;
            return `${zp(date.getDate())}.${zp(date.getMonth()+1)}.${date.getFullYear()} ${zp(date.getHours())}:${zp(date.getMinutes())}`
        },
        status(run) {
            if (run.status.none > 0) return 'none';
            if (run.status.running > 0) return 'running';
            if (run.status.error > 0) return 'error';
            if (run.status.ok == 0 && run.status.ok_note == 0) return 'skipped';
            return 'ok';
        },
        async createPlan() {
            const name = await this.$refs.createPlanDialog.ask();
            if (!name || name.trim() == '') return;

            const plan = new TestPlan();
            plan.data.name = name;
            plan.data.createdAt = new Date();
            plan.data.items = [];

            const id = await this.$store.dispatch('Project/saveTestPlan', { projectId: this.$route.params.projectId, plan });
            this.$router.push({name: 'TestPlanView', params: { id, projectId: this.$route.params.projectId }});
        },
        async deletePlan(plan) {
            this.deletingPlanName = plan.name;
            const answer = await this.$refs.deletePlanDialog.ask();
            if (!answer) return;

            await this.$store.dispatch('Project/deleteTestPlan', plan);
            this.plans = this.plans.filter(p => p.id != plan.id);
        },
    },
    async mounted() {
        this.plansUnsub = await this.$store.dispatch('Project/listenTestPlans', { projectId: this.$route.params.projectId, listener: plans => {
            this.plans = plans;
        } });
        this.runsUnsub = await this.$store.dispatch('Project/listenTestRuns', { projectId: this.$route.params.projectId, listener: runs => {
            this.runs = runs;
        } });
    },
    beforeDestroy() {
        if (this.plansUnsub) this.plansUnsub();
        if (this.runsUnsub) this.runsUnsub();
    },
}
</script>

<style lang="scss" scoped>
h2 {
    border-bottom: 1px solid #DCDCDC;
}

.new-test {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    align-items: center;
    text-align: center;

    input {
        padding: 15px;
        border-radius: 20px;
        border: 1px solid #B9B9B9;
        width: 100%;
        box-sizing: border-box;
    }
}

.flx {
    display: flex;
    flex-direction: row
}

.flex {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 30px;

    > div {
        width: 60%;
    }

    .tin {
        width: 40%;
    }

    .percentage {
        font-weight: bold;
        color: #299F00;

        &.error {
            color: red;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main {
    flex-grow: 1;
    background: #F7F7F7;
    box-sizing: border-box;
}

.test-plans {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .test-plan {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            color: black;
        }
    }
}

.item-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item-grid {
    /*display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 75px;
    gap: 10px;
    align-items: center;

    h3 {
        margin: 0;
        width: 1px;
        flex-grow: 1;
    }*/
    display: flex;
    align-items: center;
    width: 100%;

    > * {
        width: 1px;
        flex-grow: 1;
    }

    > a {
        color: black;
        text-align: center;
    }
}
</style>