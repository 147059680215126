<template>
    <ReportDetails :report="report" v-if="report">
        <template #title>Feil: {{ report.title }}</template>
        <template #details>
            <div class="item-list">
                <div class="item-grid title" :class="{ dev: isDev }">
                    <h2>Rapportert</h2>
                    <h2>Status</h2>
                    <h2 v-if="isDev">Est. tid</h2>
                    <h2>Alvorlighet</h2>
                </div>
                <div class="item-grid" :class="{ dev: isDev }">
                    <p>{{ printDate }}</p>
                    <StatusDropdown :item="report" :disabled="!isDev" />
                    <EstimatedHours :item="report" v-if="isDev" />
                    <UrgencyDropdown :item="report" />
                </div>
            </div>
        </template>
    </ReportDetails>
</template>

<script>
import ReportDetails from '@/views/Report/ReportDetails.vue'
import StatusDropdown from '@/components/Dropdown/StatusDropdown.vue'
import UrgencyDropdown from '@/components/Dropdown/UrgencyDropdown.vue'
import EstimatedHours from '@/components/EstimatedHours.vue'

export default {
    name: 'CustomerBugDetails',
    components: {
        ReportDetails,
        StatusDropdown,
        UrgencyDropdown,
        EstimatedHours,
    },
    data: () => ({
        report: null,
    }),
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        isDev() {
            if (this.isAdmin) return true;
            if (!this.$store.state.User.currentUser) return false;
            const project = this.$store.state.Project.projectsList.find(proj => proj.id == this.report.projectId);
            if (!project) return false;
            return project.devs.includes(this.$store.state.User.currentUser.id);
        },
        printDate() {
            if (this.report.date == undefined) return;
            return this.report.date.toLocaleString().split(",")[0];
        },
    },
    async mounted() {
        this.report = await this.$store.dispatch(`Project/fetchCustomerBug`, {
            projectId: this.$route.params.projectId,
            reportId: this.$route.params.reportId,
        });
        
        this.$store.dispatch('Project/markCommentsAsRead', { parent: this.report });
    },
}
</script>

<style lang="scss" scoped>
.item-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 5px;

    &.dev {
        grid-template-columns: 1fr 1fr 1fr 1fr;

        @media (max-width: 1250px) {
            grid-template-columns: 1fr 1fr 1fr;

            > :nth-child(1) {
                display: none;
            }
        }
    }

    .dropdown {
        width: 140px;
    }
}
</style>