<template>
    <div class="box">
        <report-overview :items="items" status-tabs :extra-fields="['Prioritet', 'Est. timer']" :actions="[
            {
                id: 'open',
                name: 'Åpne',
                default: true,
            },
            {
                id: 'delete',
                name: 'Slett',
                border: 'red',
                dev: true,
            },
        ]" @open="openItem" @delete="deleteItem" @new="createItem" allowCreate>
            <template #title>Oppgaver</template>
            <template #new-button>+ Ny oppgave</template>
            <template #item-extra="data">
                <PriorityDropdown :item="data.item" />
                <!--span class="no-price" v-if="data.item.price === null">Mangler pris</span>
                <span class="price-unaccepted" v-else-if="!data.item.priceAccepted">Pris ikke godkjent</span>
                <span class="price-accepted" v-else>Pris godkjent</span-->
                <EstimatedHours :item="data.item" disabled />
            </template>
        </report-overview>
        <confirm-dialog v-model="deleteModalOpen" ref="deleteModal">
            <template #title>Slett oppgave</template>
            <template #text>Er du sikker på at du vil slette {{ itemToDelete.title }}?</template>
        </confirm-dialog>
        <modal v-model="createModalOpen">
            <div class="create-modal">
                <h3>Opprett oppgave</h3>
                <div class="create-line">
                    <span>Tittel</span>
                    <padded-input type="text" v-model="createTitle" />
                </div>
                <div class="create-line">
                    <span>Beskrivelse</span>
                    <textarea v-model="createDescription"></textarea>
                </div>
                <div class="create-line">
                    <span>Prioritet</span>
                    <priority-dropdown v-model="createPriority" />
                </div>
                <dialog-button @click="confirmCreate">Lagre</dialog-button>
            </div>
        </modal>
    </div>
</template>

<script>
import ReportOverview from '@/views/Report/ReportOverview.vue'
import PriorityDropdown from '@/components/Dropdown/PriorityDropdown.vue'
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'
import PaddedInput from '@/components/PaddedInput.vue'
import Modal from '@/components/Modal.vue'
import DialogButton from '@/components/Modal/DialogButton.vue'
import EstimatedHours from '@/components/EstimatedHours.vue'

export default {
    name: 'DevelopmentOverview',
    components: {
        ReportOverview,
        PriorityDropdown,
        ConfirmDialog,
        PaddedInput,
        Modal,
        DialogButton,
        EstimatedHours,
    },
    data() {
        return {
            items: [],

            deleteModalOpen: false,
            itemToDelete: null,

            createModalOpen: false,
            createTitle: '',
            createDescription: '',
            createPriority: 1,
        }
    },
    methods: {
        openItem(item) {
            this.$router.push({ name: 'DevelopmentDetails', params: { developmentId: item.id } });
        },
        async deleteItem(item) {
            this.itemToDelete = item;
            const confirm = await this.$refs.deleteModal.ask();
            this.itemToDelete = null;
            if (!confirm) return;
            await this.$store.dispatch(`Project/remove`, item);
            this.items = this.items.filter(i => i.id != item.id);
        },
        createItem() {
            this.createTitle = '';
            this.createDescription = '';
            this.createPriority = 1;
            this.createModalOpen = true;
        },
        async confirmCreate() {
            this.createModalOpen = false;
            const id = await this.$store.dispatch('Project/addDevelopment', { projectId: this.$route.params.projectId, title: this.createTitle, description: this.createDescription, priority: this.createPriority });
            this.$router.push({ name: 'DevelopmentDetails', params: { developmentId: id } });
        },
    },
    async mounted() {
        this.items = await this.$store.dispatch(`Project/fetchDevelopments`, this.$route.params.projectId);
    },
}
</script>

<style lang="scss" scoped>
.box {
    height: 100%;
}
.no-price {
    color: red;
}

.price-unaccepted {
    color: darkgrey;
}

.price-accepted {
    color: green;
}

.create-modal {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .create-line {
        display: flex;
        align-items: center;

        > :first-child {
            width: 100px;
        }

        > :nth-child(2) {
            flex-grow: 1;
        }

        > textarea {
            border: 1px solid lightgrey;
            outline: none;
            padding: 15px 30px;
            border-radius: 25px;
            height: 100px;
        }
    }
}
</style>