<template>
    <div class="big">
        <div class="content">
            <h1>Innstillinger</h1>
            <div class="cards">
                <base-card>
                    <h2>Bytt passord</h2>
                    <form @submit.prevent="submitChangePassword" action="/" class="change-password-container">
                        <div class="change-password-inputs">
                            <span>Gammelt passord:</span>
                            <padded-input type="password" v-model="oldPassword" />
                            <span>Nytt passord:</span>
                            <padded-input type="password" v-model="newPassword1" />
                            <span>Nytt passord (gjenta):</span>
                            <padded-input type="password" v-model="newPassword2" />
                        </div>
                        <base-button type="submit">Bytt passord</base-button>
                    </form>
                </base-card>
                <base-card v-if="isAdmin">
                    <h2>Inviter bruker</h2>
                    <form @submit.prevent="submitSendInvite" action="/" class="invite-user-container">
                        <div class="invite-email-line">
                            <padded-input type="text" v-model="inviteEmail" :disabled="sendingInvite" />
                            <base-button type="submit" :disabled="sendingInvite">Send invitasjon</base-button>
                        </div>
                    </form>
                </base-card>
            </div>
        </div>
        <info-dialog ref="info" />
    </div>
</template>

<script>
import PaddedInput from '@/components/PaddedInput.vue'
import InfoDialog from '@/components/Modal/InfoDialog.vue'

export default {
    name: 'DeveloperOptions',
    components: {
        PaddedInput,
        InfoDialog,
    },
    data: () => ({
        oldPassword: '',
        newPassword1: '',
        newPassword2: '',

        inviteEmail: '',
        sendingInvite: false,
    }),
    computed: {
        isAdmin() {
          if (!this.$store.state.User.currentUser) return false;
          return this.$store.state.User.currentUser.isAdmin;
        },
    },
    methods: {
        async submitChangePassword() {
            if (this.oldPassword == '') return this.$refs.info.tell('Du må skrive inn passordet ditt.', 'error');
            if (this.newPassword1 == '') return this.$refs.info.tell('Du må skrive inn et nytt passord.', 'error');
            if (this.newPassword1 != this.newPassword2) return this.$refs.info.tell('Du må skrive samme passord begge ganger.', 'error');

            try {
                await this.$store.dispatch('User/changePassword', { oldPassword: this.oldPassword, newPassword: this.newPassword1 });
                this.$refs.info.tell('Passordet ditt har blitt endret.', 'success');
                this.oldPassword = '';
                this.newPassword1 = '';
                this.newPassword2 = '';
            } catch (e) {
                if (!e.code) {
                    console.error(e);
                    return this.$refs.info.tell('En ukjent feil oppsto.', 'error');
                }

                switch (e.code) {
                    case 'auth/wrong-password':
                        return this.$refs.info.tell('Passordet du skrev inn var feil.', 'error');
                    case 'auth/weak-password':
                        return this.$refs.info.tell('Passordet du skrev inn er for svakt.', 'error');
                    default:
                        console.error(e);
                        return this.$refs.info.tell('En ukjent feil oppsto.', 'error');
                }
            }
        },
        async submitSendInvite() {
            if (this.inviteEmail.trim() == '') return this.$refs.info.tell('Du må skrive inn en e-postadresse.', 'error');

            this.sendingInvite = true;
            const url = await this.$store.dispatch('User/inviteUserByEmail', this.inviteEmail);
            window.open(`mailto:${this.inviteEmail}?subject=Invitasjon til Devhelper&body=Du har blitt invitert til å ta i bruk Devhelper.%0D%0A%0D%0AKlikk her for å registrere deg:%0D%0A${url.replace('&', '%26')}`);
            this.sendingInvite = false;
            this.inviteEmail = '';
            this.$refs.info.tell(`Hvis du ikke fikk opp et e-postvindu, send denne linken til brukeren: ${url}`)
        },
    },
}
</script>

<style lang="scss" scoped>
.big {
    display: flex;
    flex-direction: row;
}

.content {
    background: #f6f6f6;
    flex-grow: 1;
    /* padding: 15px 50px; */
    box-sizing: border-box;
    height: 100vh;

    .cards {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .change-password-inputs {
            display: grid;
            grid-template-columns: 250px max(300px);
            align-items: center;
            gap: 10px;
        }

        .invite-email-line {
            display: flex;
            gap: 20px;
            align-items: center;

            .input-container {
                width: 250px;
            }
        }
    }
}
</style>