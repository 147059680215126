<template>
    <Modal class="confirm-modal" :value="value" @input="close">
        <div class="contents" v-if="value">
            <h3 class="title"><slot name="title"></slot></h3>
            <span class="text"><slot name="text"></slot></span>
            <div class="spacing"></div>
            <div class="buttons">
                <dialog-button @click="yes"><slot name="yes-button">Ja</slot></dialog-button>
                <dialog-button @click="no"><slot name="no-button">Nei</slot></dialog-button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import PaddedInput from '@/components/PaddedInput.vue'
import DialogButton from './DialogButton.vue'

export default {
    name: 'ConfirmDialog',
    components: {
        Modal,
        PaddedInput,
        DialogButton,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        resolve: null,
    }),
    methods: {
        ask() {
            if (this.resolve) return;
            return new Promise(resolve => {
                this.resolve = resolve;
                this.$emit('input', true);
            });
        },
        yes() {
            this.$emit('input', false);
            if (this.resolve) this.resolve(true);
            this.resolve = null;
        },
        no() {
            this.$emit('input', false);
            if (this.resolve) this.resolve(false);
            this.resolve = null;
        },
        close() {
            this.no();
        },
    },
    watch: {
        defaultValue: {
            handler() {
                this.inputValue = this.defaultValue;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.confirm-modal {
    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        min-width: 500px;
        min-height: 350px;
        padding-bottom: 30px;
        box-sizing: border-box;

        .title {
            font-size: 20px;
            font-weight: bold;
        }

        .text {
        }

        .input {
            width: 100%;
            box-sizing: border-box;
        }

        .error {
            color: red;
            font-style: italic;
        }

        .spacing {
            flex-grow: 1;
        }

        .buttons {
            display: flex;
            gap: 10px;
            justify-content: space-between;
        }
    }
}
</style>