<template>
    <button @click="$emit('click')"><slot></slot></button>
</template>

<script>
export default {
    name: 'DialogButton',
}
</script>

<style lang="scss" scoped>
button {
    border: none;
    outline: none;
    background: #636363;
    color: white;
    height: 50px;
    min-width: 240px;
    padding: 10px 30px;
    box-sizing: border-box;
    border-radius: 1000px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}
</style>