<template>
    <div class="report-item" :style="gridStyle" @click="doDefaultAction">
        <p>{{item.title}}</p>
        <p>{{item.date.toLocaleString().split(',')[0]}}</p>
        <slot :item="item"></slot>
        <p>{{ totalHours }}</p>
        <div class="options">
            <template v-for="action in actions">
                <base-button v-if="isAdmin || !action.dev" :key="action.id" @clicked="$emit('action', action.id)" class="base-button" :class="buttonClasses(action)">{{ action.name }}</base-button>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        actions: {
            type: Array,
            default: () => [],
        },
        gridStyle: {
            type: String,
            default: 'grid-template-columns: 1fr 1fr 0.5fr 1fr',
        },
    },
    data: () => ({
        defaultAction: null,
        costInfo: null,
    }),
    computed: {
        isAdmin() {
            return this.$store.state.User.currentUser.isAdmin;
        },
        totalHours() {
            if (!this.costInfo) return 0;
            return this.costInfo.totalHours;
        },
    },
    methods: {
        buttonClasses(action) {
            const classes = [];
            if (action.border == 'red') classes.push('red-border');
            return classes;
        },
        updateDefaultAction() {
            this.defaultAction = this.actions.find(action => action.default);
        },
        doDefaultAction() {
            if (!this.defaultAction) return;
            this.$emit('action', this.defaultAction.id);
        },
    },
    watch: {
        actions: {
            handler() {
                this.updateDefaultAction();
            },
            deep: true,
            immediate: true,
        },
    },
    async mounted() {
        this.costInfo = await this.$store.dispatch('Project/fetchCostInfo', { item: this.item });
    },
}
</script>

<style lang="scss" scoped>
.report-item {
    display: grid;
    gap: 10px;
    align-items: center;
    border-top: solid 1px lightgrey;
    padding-top: 10px;
    color: black;
    justify-items: start;

    p {
        margin: 8px 0;
    }

    .dropdown {
        width: 80%;
    }
}

.options {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
    justify-self: end;
    padding-right: 25px;
}

.base-button {
    &.red-border {
        border-color: #F5AAAA;
    }
}
</style>