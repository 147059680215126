import FirestoreType from './FirestoreType'

export default class Invoice extends FirestoreType {
    get date() { return this.data.date.toDate(); }
    get tasks() { return this.data.tasks; }

    get price() {
        return this.tasks.reduce((a, n) => a + n.price, 0);
    }

    get hours() {
        return this.tasks.reduce((a, n) => a + n.hours, 0);
    }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}