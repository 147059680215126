<template>
    <div class="list">
        <div class="grid">
            <h3>Prosjekt</h3>
            <h3>Kritiske feil</h3>
            <h3>Nye feil</h3>
            <h3>Nye oppgaver</h3>
            <h3 class="titles">Alternativer</h3>
        </div>
        <project-item v-for="project in projects" :key="project.id" :project="project" />
    </div>
</template>

<script>
import ProjectItem from './ProjectItems/ProjectItem.vue'

export default {
    name: 'ProjectItems',
    components: {
        ProjectItem,
    },
    props: {
        projects: {}
    },
    methods:{
        async overview(projectId){
            this.$router.push({name: 'CustomerBugOverview', params: {projectId}});
        },
    },
}
</script>


<style scoped>
.list {
    display: flex;
    flex-direction: column;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.titles {
    justify-self: center;
}

</style>