<template>
    <label class="input-container">
        <input :type="type" :value="value" @input="$emit('input', $event.target.value)" :disabled="disabled" :placeholder="placeholder" />
    </label>
</template>

<script>
export default {
    name: 'PaddedInput',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.input-container {
    padding: 10px 30px;
    border: 1px solid lightgrey;
    border-radius: 1000px;
    display: block;

    input {
        border: none;
        outline: none;
        padding: 0;
        width: 100%;
    }
}
</style>