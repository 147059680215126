<template>
    <div class="new-comment">
        <textarea class="input-box" cols="30" rows="3" placeholder="Skriv en kommentar" v-model="comment"></textarea>
        <p @click="sendComment()"><i class="fa fa-send"></i></p>
    </div>
</template>

<script>
export default {
    name: 'TextBox',
    data: () => ({
        comment: '',
    }),
    methods: {
        sendComment() {
            this.$emit('comment', this.comment);
            this.comment = '';
        },
    },
}
</script>

<style lang="scss" scoped>
.new-comment {
    display: flex;
    align-items: center;
    gap: 1%;
    width: 100%;

    .input-box{
        font-family: "Poppins";
        width: 100%;
        resize: none;
        outline: none;
        padding: 15px;
        box-sizing: border-box;
        height: 92px;
        border-radius: 20px;
        border: 1px solid #D8D8D8;

        &::placeholder {
            line-height: 60px;
        }
    }

    .fa-send {
        padding: 22px;
        font-size: 1.6vw;
        color: white;
        background: #646363;
        border-radius: 10px;

        &:hover {
            cursor: pointer;
            background: #868585;
        }
    }
}
</style>