import FirestoreType from './FirestoreType'

export default class Reminder extends FirestoreType {
    get text() { return this.data.text; }
    get createdAt() { return this.data.createdAt?.toDate() || new Date(); }
    get deadline() { return this.data.deadline?.toDate(); }
    get completedAt() { return this.data.completedAt?.toDate(); }
    get link() { return this.data.link; }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}