import FirestoreType from './FirestoreType'

export default class CustomerBug extends FirestoreType {
    get title() { return this.data.title; }
    get description() { return this.data.description; }
    get date() { return this.data.date.toDate(); }
    get assignedTo() { return this.data.assignedTo; }
    get status() { return this.data.status; }
    get urgency() { return this.data.urgency; }
    get commentCount() { return this.data.commentCount || 0; }
    get hoursWorked() { return this.data.hoursWorked || 0; }
    get completedAt() { return this.data.completedAt?.toDate() || null; }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }

    getReadCommentCount(uid) { return this.data['readComments_' + uid] || 0; }
    getUnreadCommentCount(uid) { return this.commentCount - this.getReadCommentCount(uid); }
}