import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/User'
import Project from './modules/Project'
import Storage from './modules/Storage'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
      User,
      Project,
      Storage,
    },

    plugins: [createPersistedState({paths: ['User']})]
    // plugins: [createPersistedState()]
});
