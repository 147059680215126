import { getFirestore, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore"
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions'
import Vue from 'vue'

import User from '@/types/User'

export default {
    namespaced: true,
    state: () => ({
        currentUser: null,
        authUser: null,
        projectNotifications: [],
        customerReportNotifications: [],
        users: {},
        _fetchingUsers: {},
    }),

    mutations: {
        onUserChanged(state, user) {
            state.currentUser = user;
        },

        onAuthUserChanged(state, user) {
            state.authUser = user;
        },

        addUserProjectNotifs(state, {projectId, projectNotif}){
            if(state.projectNotifications[projectId] === undefined) Vue.set(state.projectNotifications, projectId, []);
            state.projectNotifications[projectId].push(projectNotif);
        },

        addUserCustomerReportNotifs(state, {reportId, reportNotif}){
            if(state.customerReportNotifications[reportId] === undefined) Vue.set(state.customerReportNotifications, reportId, []);
            state.customerReportNotifications[reportId].push(reportNotif);
        },

        markUserFetching(state, userId) {
            //state._fetchingUsers[userId] = true;
            Vue.set(state._fetchingUsers, userId, true);
        },

        cacheUser(state, user) {
            Vue.set(state.users, user.id, user);
        },
    },
    
    actions: {
        onLoad({ dispatch, commit }) {
            const auth = getAuth();
            
            onAuthStateChanged(auth, async user => {
                if (user) {
                    await dispatch('startListening', user.uid);
                    commit('onAuthUserChanged', user);
                } else {
                    commit('onUserChanged', null); 
                    commit('onAuthUserChanged', null);
                }
            });
        },

        async signIn({ dispatch }, {email, password}) {
            const auth = getAuth();
            let credentials = await signInWithEmailAndPassword(auth, email, password);
            await dispatch('startListening', credentials.user.uid);
        },

        async signInWithToken({ dispatch }, token) {
            const auth = getAuth();
            const credentials = await signInWithCustomToken(auth, token);
            await dispatch('startListening', credentials.user.uid);
        },

        async signOut({ commit }) {
            const auth = getAuth();
            commit('onUserChanged', null);
            commit('onAuthUserChanged', null);
            await signOut(auth);
        },

        async startListening({ commit }, userId) {
            const db = getFirestore();

            let data = await getDoc(doc(db, 'User', userId));

            while (!data.exists()) {
                await new Promise(res => setTimeout(res, 500));
                data = await getDoc(doc(db, 'User', userId));
            }

            if (data.data().isAdmin) {
                let userObject = {
                    id: data.id,
                    email: data.data().email,
                    name: data.data().name,
                    isAdmin: data.data().isAdmin,
                }

                commit('onUserChanged', userObject);

            } else {
                let userObject = {
                    id: data.id,
                    email: data.data().email,
                    name: data.data().name,
                    isAdmin: data.data().isAdmin,
                }

                commit('onUserChanged', userObject);
            }
        },

        reauthenticate({ state }, password) {
            const email = state.currentUser.email;
            const credential = EmailAuthProvider.credential(email, password);
            return reauthenticateWithCredential(state.authUser, credential);
        },

        async changePassword({ dispatch, state }, { oldPassword, newPassword }) {
            await dispatch('reauthenticate', oldPassword);
            await updatePassword(state.authUser, newPassword);
        },

        //-----------------------------------------------------------
        //------------------------ FETCH ----------------------------
        //-----------------------------------------------------------

        async fetchUserById({ commit }, userId) {
            commit('markUserFetching', userId);
            const db = getFirestore();
            let user = await getDoc(doc(db, "User", userId));

            let userObject = {
                id: user.id,
                email: user.data().email,
                name: user.data().name,
                isAdmin: user.data().isAdmin,
            };
            // commit('onUserChanged', userObject);
            commit('cacheUser', userObject);
            return userObject;
        },

        fetchUserByIdCached({ dispatch, state }, userId) {
            if (state._fetchingUsers[userId] || state.users[userId]) return;
            dispatch('fetchUserById', userId);
        },

        async updateUsersCustomerBugsNotif(_, userId) {
            await updateDoc(doc(db, `User/${userId}/ProjectsNotifications/${projectId}`), {
                customerBugs: increment(1)
             });
        },

        async fetchAccessById(_, userId) {
            const db = getFirestore();

            let accesses = await getDocs(query(collection(db, `User/${userId}/Access`)))
            return accesses.docs.map(doc => doc.id)
        },

        async fetchUserByEmail(_, email) {
            const db = getFirestore();

            //let userList = await getDocs(query(collection(db, `User/`)));
            //let user = userList.docs.filter(doc => doc.data().email == email);

            let users = await getDocs(query(collection(db, 'User'), where('email', '==', email)));
            if (users.docs.length == 0) return null;
            return new User(users.docs[0]);
        },

        //-----------------------------------------------------------
        //------------------------ INVITE ---------------------------
        //-----------------------------------------------------------

        async inviteUserByEmail(_, email) {
            const functions = getFunctions(undefined, 'europe-west1');
            const inviteUser = httpsCallable(functions, 'user-inviteuser');

            const response = await inviteUser({ email, urlRoot: window.location.origin });
            return response.data.value;
        },

        async acceptInvite(_, { id, token, username, password }) {
            const functions = getFunctions(undefined, 'europe-west1');
            const acceptInvite = httpsCallable(functions, 'user-acceptinvite');

            return acceptInvite({ id, token, username, password });
        },
    },
}