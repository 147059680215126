<template>
    <div>
        <report-overview :items="items" status-tabs :extra-fields="['Alvorlighet']" :actions="[
            {
                id: 'open',
                name: 'Åpne',
                default: true,
            },
            {
                id: 'delete',
                name: 'Slett',
                border: 'red',
                dev: true,
            },
        ]" @open="openItem" @delete="deleteItem" @new="createItem" allowCreate>
            <template #new-button>+ Opprett feil</template>
            <template #title>Feil</template>
            <template #item-extra="data">
                <UrgencyDropdown :item="data.item" />
            </template>
        </report-overview>
        <confirm-dialog v-model="deleteModalOpen" ref="deleteModal">
            <template #title>Slett feil</template>
            <template #text>Er du sikker på at du vil slette {{ itemToDelete.title }}?</template>
        </confirm-dialog>
        <modal v-model="createModalOpen">
            <div class="create-modal">
                <h3>Opprett feil</h3>
                <div class="create-line">
                    <span>Tittel</span>
                    <padded-input type="text" v-model="createTitle" />
                </div>
                <div class="create-line">
                    <span>Beskrivelse</span>
                    <textarea v-model="createDescription"></textarea>
                </div>
                <div class="create-line">
                    <span>Alvorlighet</span>
                    <urgency-dropdown v-model="createUrgency" />
                </div>
                <dialog-button @click="confirmCreate">Lagre</dialog-button>
            </div>
        </modal>
    </div>
</template>

<script>
import ReportOverview from '@/views/Report/ReportOverview.vue'
import UrgencyDropdown from '@/components/Dropdown/UrgencyDropdown.vue'
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'
import PaddedInput from '@/components/PaddedInput.vue'
import Modal from '@/components/Modal.vue'
import DialogButton from '@/components/Modal/DialogButton.vue'

export default {
    name: 'CustomerBugOverview',
    components: {
        ReportOverview,
        UrgencyDropdown,
        ConfirmDialog,
        PaddedInput,
        Modal,
        DialogButton,
    },
    data() {
        return {
            items: [],

            deleteModalOpen: false,
            itemToDelete: null,

            createModalOpen: false,
            createTitle: '',
            createDescription: '',
            createUrgency: 1,
        }
    },
    methods: {
        openItem(item) {
            this.$router.push({ name: 'CustomerBugDetails', params: { reportId: item.id } });
        },
        async deleteItem(item) {
            this.itemToDelete = item;
            const confirm = await this.$refs.deleteModal.ask();
            this.itemToDelete = null;
            if (!confirm) return;
            await this.$store.dispatch(`Project/remove`, item);
            this.items = this.items.filter(i => i.id != item.id);
        },
        createItem() {
            this.createTitle = '';
            this.createDescription = '';
            this.createUrgency = 1;
            this.createModalOpen = true;
        },
        async confirmCreate() {
            this.createModalOpen = false;
            const id = await this.$store.dispatch('Project/addCustomerBug', { projectId: this.$route.params.projectId, title: this.createTitle, description: this.createDescription, urgency: this.createUrgency });
            this.$router.push({ name: 'CustomerBugDetails', params: { reportId: id } });
        },
    },
    async mounted() {
        this.items = await this.$store.dispatch(`Project/fetchCustomerBugs`, this.$route.params.projectId);
    },
}
</script>

<style lang="scss" scoped>
.create-modal {
    min-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .create-line {
        display: flex;
        align-items: center;

        > :first-child {
            width: 100px;
        }

        > :nth-child(2) {
            flex-grow: 1;
        }

        > textarea {
            border: 1px solid lightgrey;
            outline: none;
            padding: 15px 30px;
            border-radius: 25px;
            height: 100px;
        }
    }
}
</style>