<template>
    <div class="modal-backdrop" v-if="value" @click.self="clickBackdrop">
        <div class="modal">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        clickBackdrop() {
            this.$emit('input', false);
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2147483647;

    .modal {
        background: white;
        padding: 20px;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>