// Notes about terminology:

// A test plan is a "blueprint" for how a test is run. It is the structure that
// can be edited to add, remove, and change tests. A test plan consists of
// a tree of tests organized into categories.

// A test is the singular item in a test plan. Like the plan itself, this can be
// edited whenever needed for changed behaviour, discovered bugs etc.

// A test run is created when the tests should actually be run (hence the name).
// Multiple runs can be done from the same test plan. The test run can't be
// edited except to update its status. Where the test plan has tests, the test
// run has test instances.

// A test instance is single "run" of a single test. It has all the data from
// the test, in addition to a status for the outcome.

import FirestoreType from './FirestoreType'

export default class Test extends FirestoreType {
    get name() { return this.data.name }
    get prerequisites() { return this.data.prerequisites }
    get steps() { return this.data.steps }
    get expectedOutcome() { return this.data.expectedOutcome }

    set name(val) { this.data.name = val }
    set prerequisites(val) { this.data.prerequisites = val }
    set steps(val) { this.data.steps = val }
    set expectedOutcome(val) { this.data.expectedOutcome = val }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}