<template>
    <div class="contain">
        <report-overview :items="items" :actions="[
            {
                id: 'open',
                name: 'Åpne',
                default: true,
            },
            {
                id: 'delete',
                name: 'Slett',
                border: 'red',
                dev: true,
            },
        ]" @open="openItem" @delete="deleteItem">
            <template #title>Brukerrapporter</template>
        </report-overview>
        <confirm-dialog v-model="deleteModalOpen" ref="deleteModal">
            <template #title>Slett feil</template>
            <template #text>Er du sikker på at du vil slette {{ itemToDelete.title }}?</template>
        </confirm-dialog>
    </div>
</template>

<script>
import ReportOverview from '@/views/Report/ReportOverview.vue'
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'

export default {
    name: 'DevelopmentOverview',
    components: {
        ReportOverview,
        ConfirmDialog,
    },
    data() {
        return {
            items: [],
            deleteModalOpen: false,
            itemToDelete: null,
        }
    },
    methods: {
        openItem(item) {
            this.$router.push({ name: 'UserBugDetails', params: { reportId: item.id } });
        },
        async deleteItem(item) {
            this.itemToDelete = item;
            const confirm = await this.$refs.deleteModal.ask();
            this.itemToDelete = null;
            if (!confirm) return;
            await this.$store.dispatch(`Project/remove`, item);
            this.items = this.items.filter(i => i.id != item.id);
        },
    },
    async mounted() {
        this.items = await this.$store.dispatch(`Project/fetchUserBugs`, this.$route.params.projectId);
    },
}
</script>

<style scoped>
.contain {
    box-sizing: border-box;
    /* padding: 15px; */
    height: 100%;
}
</style>