<template>
    <div class="big">
        <div class="content" v-if="project">
            <header class="top-div">
                <h1>{{ project.name }} dashbord</h1>
                <base-button buttonType="new" @clicked="toggleDeleteProjectPopup" v-if="isAdmin">Slett prosjekt</base-button>
                <confirm-deletion
                    v-if="showDeleteProjectPopup"
                    @close="toggleDeleteProjectPopup"
                    @confirm="deleteProject"
                ></confirm-deletion>
                <base-popup v-if="showRemoveAccessPopup" @close="toggleRemoveAccessPopup">
                    <div>
                        <p>Fjern tilgang?</p>
                        <button @click="removeAccess">Fjern</button>
                        <button @click="toggleRemoveAccessPopup">Avbryt</button>
                    </div>
                </base-popup>
            </header>
            <div class="manage-project">
                <div>
                    <base-card>
                        <div class="line top-div">
                            <h2>Detaljer</h2>
                        </div>
                        <h2>Prosjektnavn</h2>
                        <editable-value :value="project.name" @input="changeName" :validate="validateName">
                            <template #title>Endre navn</template>
                            <template #text>Skriv inn et nytt navn for prosjektet:</template>
                        </editable-value>
                        <h2>Prisestimat</h2>
                        <editable-price :value="project.priceEstimate" @price="changePriceEstimate"></editable-price>
                    </base-card>
                    <base-card class="bottom-card">
                        <div class="title chat-title">
                            <h2
                                @click="selectUserSection('customers')"
                                :class="{ selectedTab: selectedUserSection == 'customers' }"
                            >
                                Kunder
                            </h2>
                            <h2
                                @click="selectUserSection('developers')"
                                :class="{ selectedTab: selectedUserSection == 'developers' }"
                            >
                                Utviklere
                            </h2>
                        </div>
                        <div class="add-access">
                            <input type="text" placeholder="E-post" v-model="email"/>
                            <button @click="addAccess" :disabled="addingAccess">Legg til</button>
                        </div>
                        <p v-if="showError">Det skjedde en feil</p>
                        <div class="access" v-for="(access, index) in (selectedUserSection == 'customers' ? accesses : developers)" :key="selectedUserSection + access.id">
                            <p>{{ access.email }}</p>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="toggleRemoveAccessPopup(access.id, index, selectedUserSection == 'developers')">
                                <path d="M2.25 4.5H3.75H15.75" stroke="#5F5F5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72065 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5" stroke="#5F5F5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M7.5 8.25V12.75" stroke="#5F5F5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10.5 8.25V12.75" stroke="#5F5F5F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </base-card>
                </div>
                <div>
                    <base-card>
                        <div class="grid line">
                            <h2 class="GRN">Estimerte timer</h2>
                            <h2>Totalt antall timer</h2>
                            <h2>Avtalt timepris</h2>
                            <h2 :class="{ RED: totalUninvoicedHours > 0 }">Ufakturerte timer</h2>
                        </div>
                        <div class="grid">
                            <estimated-hours :item="this.project"></estimated-hours>
                            <p>{{ costInfo.totalHours }}</p>
                            <editable-price :value="project.pricePerHour" @price="changeHourlyPrice">
                                <template #title>Avtalt timepris</template>
                            </editable-price>
                            <p :class="{ RED: totalUninvoicedHours > 0 }">{{ totalUninvoicedHours }}</p>
                        </div>
                    </base-card>
                    <base-card>
                        <div class="grid line">
                            <h2>Kritiske feil</h2>
                            <h2>Nye feil</h2>
                            <h2>Nye kommentarer</h2>
                            <h2>Nye oppgaver</h2>
                        </div>
                        <div class="grid">
                            <p>{{ counts.criticalBugs }}</p>
                            <p>{{ counts.newBugs }}</p>
                            <p>{{ unreadComments }}</p>
                            <p>{{ counts.newDevelopments }}</p>
                        </div>
                    </base-card>
                    <base-card class="bottom-card">
                        <div class="title chat-title">
                            <h2
                                @click="selectContentSection('reminders')"
                                :class="{ selectedTab: selectedContentSection == 'reminders' }"
                            >
                                Påminnelser
                            </h2>
                            <h2
                                @click="selectContentSection('comments')"
                                :class="{ selectedTab: selectedContentSection == 'comments' }"
                            >
                                Kommentarer/notater
                            </h2>
                            <h2
                                @click="selectContentSection('activity')"
                                :class="{ selectedTab: selectedContentSection == 'activity' }"
                            >
                                Aktivitetslogg
                            </h2>
                        </div>
                        <div class="bottom-box">
                            <div v-if="selectedContentSection == 'comments'" class="comment-container">
                                <Comments key="dev-comments" developer :parent="project" />
                            </div>
                            <div v-else-if="selectedContentSection == 'reminders'" class="reminders">
                                <div class="reminder-header"><h2>Navn</h2></div>
                                <div class="reminder-header"><h2>Frist</h2></div>
                                <div class="reminder-header reminder-buttons">
                                    <create-reminder-button :projectId="project.id">Ny</create-reminder-button>
                                </div>
                                <template v-for="reminder in reminders">
                                    <p>{{ reminder.text }}</p>

                                    <p v-if="reminder.deadline">{{ dateTimeStr(reminder.deadline) }}</p>
                                    <p v-else></p>

                                    <div class="reminder-buttons">
                                        <router-link v-if="reminder.link" :to="reminder.link"><base-button>Vis</base-button></router-link>
                                        <base-button @clicked="completeReminder(reminder)">Fullfør</base-button>
                                    </div>
                                </template>
                            </div>
                            <div v-else class="work-sessions-container">
                                <div class="work-sessions-wrapper">
                                    <table class="work-sessions">
                                        <tr class="headers">
                                            <th>Hva</th>
                                            <th>Dato</th>
                                            <th>Timer</th>
                                        </tr>
                                        <tr v-for="session in workSessions">
                                            <td>{{ session.title }}</td>
                                            <td>{{ session.date.toLocaleString().split(',')[0] }}</td>
                                            <td>{{ session.hours }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="work-sessions-sum"><span>Sum i timer</span><span>{{ costInfo.totalHours }}</span></div>
                            </div>
                        </div>
                    </base-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, getDocs, query} from 'firebase/firestore'

import ConfirmDeletion from '@/components/ConfirmDeletion.vue';
import Comments from '@/components/Comments.vue';
import EditablePrice from '@/components/EditablePrice.vue'
import EditableValue from '@/components/EditableValue.vue'
import EstimatedHours from '@/components/EstimatedHours.vue'
import CreateReminderButton from '@/components/CreateReminderButton.vue'

export default {
    name: "DeveloperDashboard",
    components: {
        ConfirmDeletion,
        Comments,
        EditablePrice,
        EditableValue,
        EstimatedHours,
        CreateReminderButton,
    },
    data() {
        return {
            project: null,
            accesses: [],
            developers: [],
            costInfo: {},
            uninvoiced: [],
            counts: {},
            userId: [],
            showRemoveAccessPopup: false,
            showDeleteProjectPopup: false,

            addingAccess: false,

            selectedUserSection: 'customers',
            selectedContentSection: 'reminders',

            email: "",
            new_name: "",
            new_price: "",
            showError: false,
            changeNameError: false,

            newReminderTextModal: false,
            newReminderDateModal: false,
        };
    },
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        workSessions() {
            if (!this.project) return [];
            return this.$store.state.Project.workSessions[this.project.path] ?? [];
        },
        totalUninvoicedHours() {
            return this.uninvoiced.reduce((a, n) => a + n.estimatedHours, 0);
        },
        unreadComments() {
            if (!this.project) return 0;
            return this.project.getUnreadCommentCount(this.$store.state.User.currentUser.id);
        },
        reminders() {
            if (!this.project) return [];
            return this.$store.state.Project.reminders[this.project.id] || [];
        },
    },
    methods: {
        dateTimeStr(date) {
            return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()>9?'':'0'}${date.getMinutes()}`;
        },

        logout() {
            const auth = getAuth();
            signOut(auth).then(() => {
                this.$router.push("/Login");
            }).catch((error) => {
                console.log(error.message);
            });
        },

        selectUserSection(section) {
            this.selectedUserSection = section;
        },

        selectContentSection(section) {
            this.selectedContentSection = section;
        },

        async removeAccess() {
            (this.selectedUserSection == 'developers' ? this.developers : this.accesses).splice(this.userId[1], 1)
            this.showRemoveAccessPopup = false;
            await this.$store.dispatch(`Project/removeUserAccessFromProject`, {
                projectId: this.$route.params.projectId,
                userId: this.userId[0],
            });
            await this.reloadAccessesAndDevelopers();
        },

        async addAccess() {
            this.addingAccess = true;
            let bool = await this.$store.dispatch(`Project/addAccessToProject`, {
                projectId: this.$route.params.projectId,
                email: this.email,
                developer: this.selectedUserSection == 'developers',
            });
            if (!bool) {
                this.showError = true;
            } else {
                this.email = ''
            }
            await this.reloadAccessesAndDevelopers();
            this.addingAccess = false;
        },

        async reloadAccessesAndDevelopers(reloadProject=true) {
            if (reloadProject) {
                this.project = await this.$store.dispatch(
                    `Project/fetchProject`,
                    this.$route.params.projectId
                );
            }

            const [accesses, developers] = await Promise.all([
                this.$store.dispatch(`Project/fetchAccessesForProject`, this.project ),
                this.$store.dispatch(`Project/fetchDevelopersForProject`, this.project ),
            ]);

            this.accesses = accesses.filter(acc => !developers.find(access => access.id == acc.id));
            this.developers = developers;
        },

        validateName(name) {
            if (name.trim().length <= 2) return 'Navnet er for kort.';
            return null;
        },

        async changeName(name) {
            await this.$store.dispatch(`Project/updateProjectName`, {
                project: this.project,
                name,
            });
        },

        async changePriceEstimate(price) {
            this.$store.dispatch('Project/updatePriceEstimate', { project: this.project, price });
        },

        async changeHourlyPrice(price) {
            this.$store.dispatch('Project/updatePricePerHour', { project: this.project, price });
        },

        async deleteProject() {
            await this.$store.dispatch(`Project/removeProject`, {
                projectId: this.$route.params.projectId,
                accesses: this.accesses,
            });
            this.showDeleteProjectPopup = !this.showDeleteProjectPopup;
            this.$router.push({ name: "DeveloperFrontpage" });
        },

        toggleRemoveAccessPopup(id, idx, dev) {
            this.userId = [id, idx, dev];
            this.showRemoveAccessPopup = !this.showRemoveAccessPopup;
        },

        toggleDeleteProjectPopup() {
            this.showDeleteProjectPopup = !this.showDeleteProjectPopup;
        },

        async completeReminder(reminder) {
            await this.$store.dispatch('Project/completeReminder', reminder);
        },
    },
    async mounted() {
        this.project = await this.$store.dispatch(
            `Project/fetchProject`,
            this.$route.params.projectId
        );
        await Promise.all([
            this.reloadAccessesAndDevelopers(false),
            (async () => { this.costInfo = await this.$store.dispatch('Project/fetchCostInfo', { item: this.project } ); })(),
            (async () => { this.uninvoiced = await this.$store.dispatch('Project/fetchUninvoicedCostInfos', { project: this.project }) })(),
            (async () => { this.counts = await this.$store.dispatch('Project/fetchProjectCounts', { project: this.project }); })(),
            this.$store.dispatch('Project/fetchWorkSessionsForProject', this.project),
        ]);
        this.$store.dispatch('Project/listenReminders', { projectId: this.project.id });
        this.new_name = this.project.name;
        this.new_price = this.project.data.priceEstimate;

        this.accesses = this.accesses.filter(acc => !this.developers.find(access => access.id == acc.id));
    },
};
</script>

<style lang="scss" scoped>
.bottom-box {
    flex-grow: 1;
}

.comment-container {
    height: 100%;
}

.work-sessions-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .work-sessions-wrapper {
        flex-grow: 1;
        overflow-y: auto;
        height: 1px;

        .work-sessions {
            border-spacing: 0;
            width: 100%;
            flex-grow: 1;

            th {
                border-bottom: 1px solid lightgrey;
                text-align: left;
                padding: 20px 0 5px;
                position: sticky;
                top: 0;
                background: white;
            }

            td {
                padding: 10px 0;
            }
        }
    }

    .work-sessions-sum {
        display: flex;
        justify-content: space-around;
        font-weight: bold;
        border-top: 1px solid lightgrey;
        padding: 15px 0;
    }
}

.reminders {
    display: grid;
    grid-template-columns: 1fr 150px auto;
    align-items: center;

    .reminder-header {
        font-weight: bold;
        border-bottom: 1px solid lightgrey;
    }

    .reminder-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        height: 100%;
    }
}

.big {
    display: flex;
    flex-direction: row;
    height: 100%;

    .content {
        background: #f6f6f6;
        flex-grow: 1;
        /* padding: 15px 50px; */
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
}

.top-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RED {
    color: #ff0000;
}

.GRN {
    color: #2a9d3d;
}

input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #dbdbdb;
}

button {
    background: gray;
    color: white;
    border: none;
    padding: 10px 25px;
    border-radius: 25px;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
    }
}

.add-access {
    display: flex;
    gap: 15px;
    padding: 10px 0;

    input {
        flex-grow: 1;
    }
}

.manage-project {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    box-sizing: border-box;
    /* height: 85%; */
    flex-grow: 1;

    > div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 30px;
    }
}

.access {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
}

h2 {
    font-size: 16px;
}

.bottom-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.line {
    border-bottom: solid 1px #dcdcdc;
}

.chat-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    h2 {
        cursor: pointer;
        color: #a4a4a4
    }

    .selectedTab {
        color: black;
    }
}

.title {
    border-bottom: solid 1px #dcdcdc;
}
</style>