<template>
    <Dropdown :value="priority" @input="updatePriority(parseInt($event))" :disabled="disabled">
        <div value="1"><div class="dot red"></div> Lav</div>
        <div value="2"><div class="dot yellow"></div> Middels</div>
        <div value="3"><div class="dot green"></div> Høy</div>
    </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: 'PriorityDropdown',
    components: {
        Dropdown,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        value: {
            type: Number,
            default: 1,
        },
        dev: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        priority: 1,
    }),
    methods: {
        async updatePriority(priority) {
            if (this.item) await this.$store.dispatch(`Project/updateStatus`, {
                item: this.item,
                priority,
            });
            this.priority = priority;
            if (!this.item) this.$emit('input', this.priority);
        },
    },
    watch: {
        item: {
            handler() {
                if (this.item) this.priority = this.item.priority;
                else this.priority = this.value;
            },
            immediate: true,
        },
        value: {
            handler() {
                if (this.item) this.priority = this.item.priority;
                else this.priority = this.value;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: black;

    &.red {
        background: #C63030;
    }

    &.yellow {
        background: #EACE3C;
    }

    &.green {
        background: #88DB6B;
    }
}
</style>