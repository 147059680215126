<template>
    <Modal class="input-modal" :value="open" @input="next">
        <div class="contents">
            <i class="fa fa-info-circle status-icon info" v-if="currentType == 'info'" />
            <i class="fa fa-exclamation-triangle status-icon error" v-else-if="currentType == 'error'" />
            <i class="fa fa-check-circle status-icon success" v-else-if="currentType == 'success'" />
            <span class="text">{{ currentMessage }}</span>
            <dialog-button @click="next"><slot name="button">OK</slot></dialog-button>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import PaddedInput from '@/components/PaddedInput.vue'
import DialogButton from './DialogButton.vue'

export default {
    name: 'InfoDialog',
    components: {
        Modal,
        PaddedInput,
        DialogButton,
    },
    data: () => ({
        open: false,
        messages: [],
        currentMessage: '',
        currentType: 'info',
        currentResolve: null,
    }),
    methods: {
        tell(text, type='info') {
            return new Promise(resolve => {
                this.messages.push([text, type, resolve]);
                if (!this.open) this.next();
            });
        },
        next() {
            if (this.currentResolve) this.currentResolve();
            if (this.messages.length == 0) {
                this.open = false;
            } else {
                const msg = this.messages.shift();
                this.currentMessage = msg[0];
                this.currentType = msg[1];
                this.currentResolve = msg[2];
                this.open = true;
            }
        },
    },
    watch: {
        defaultValue: {
            handler() {
                this.inputValue = this.defaultValue;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.input-modal {
    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        min-width: 500px;
        min-height: 350px;
        max-width: 80vw;
        padding: 30px 0;
        box-sizing: border-box;

        .status-icon {
            font-size: 50px;

            &.info {
                color: blue; // TODO
            }

            &.error {
                color: red; // TODO
            }

            &.success {
                color: green; // TODO
            }
        }

        .text {
            font-size: 1.2em;
            max-width: 100%;
            overflow-wrap: break-word;
        }
    }
}
</style>