<template>
    
    <div class="container">

        <!-- <img class="logo" src="../assets/Link_Logo.png" alt="Logo"> -->
        <!-- <img class="logo" src="../assets/Link_Logo.svg" alt="Logo"> -->

        <div class="card">
            <!-- <div class="img-container">
                <img class="img" src="../assets/login-img.jpg" alt="Bilde">
            </div> -->

            <div class="login-container">
                <!-- <div> -->
                    <form class="login">
                        <h1 class="title">Devhelper</h1>
                        <div class="email">
                            <!-- <p class="input-title">E-post</p> -->
                            <svg class="iconn" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.33332 3.3335H16.6667C17.5833 3.3335 18.3333 4.0835 18.3333 5.00016V15.0002C18.3333 15.9168 17.5833 16.6668 16.6667 16.6668H3.33332C2.41666 16.6668 1.66666 15.9168 1.66666 15.0002V5.00016C1.66666 4.0835 2.41666 3.3335 3.33332 3.3335Z" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.3333 5L9.99999 10.8333L1.66666 5" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <input class="input" type="text" placeholder="E-post" v-model="email">
                        </div>

                        <div class="pass">
                            <!-- <p class="input-title">Passord</p> -->
                            <svg class="iconn" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.9167 6.24984L15.8333 3.33317M17.5 1.6665L15.8333 3.33317L17.5 1.6665ZM9.49168 9.67484C9.92197 10.0994 10.264 10.6049 10.4981 11.1622C10.7323 11.7195 10.8538 12.3176 10.8559 12.922C10.8579 13.5265 10.7403 14.1254 10.5099 14.6843C10.2796 15.2431 9.9409 15.7509 9.51347 16.1783C9.08604 16.6057 8.57828 16.9444 8.01943 17.1748C7.46058 17.4052 6.86168 17.5227 6.25721 17.5207C5.65274 17.5187 5.05463 17.3971 4.49734 17.163C3.94005 16.9288 3.43457 16.5868 3.01002 16.1565C2.17512 15.2921 1.71315 14.1343 1.72359 12.9326C1.73404 11.7308 2.21606 10.5813 3.06585 9.7315C3.91563 8.88171 5.06519 8.39969 6.26693 8.38925C7.46866 8.37881 8.62642 8.84078 9.49085 9.67567L9.49168 9.67484ZM9.49168 9.67484L12.9167 6.24984L9.49168 9.67484ZM12.9167 6.24984L15.4167 8.74984L18.3333 5.83317L15.8333 3.33317L12.9167 6.24984Z" stroke="#505050" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <input class="input" type="password" placeholder="Passord" v-model="password">
                        </div>
                        <p class="error" v-if="error">{{ error }}</p>

                        <button @click.prevent="signIn()" type="submit" class="login-button">Logg inn</button>
                        <p>Glemt passord?</p>
                    </form>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
// import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";

export default {
    
    name: 'Login',

    data:() => ({
        email:      '',
        password:   '',
        error:      null,
    }),

    methods: {
        async signIn() {
            this.error = null;
            try {
                await this.$store.dispatch('User/signIn', { email: this.email, password: this.password });
                this.$router.push({ name: 'Frontpage'});

            } catch (e) {
                this.error = "Feil brukernavn eller passord. Prøv igjen."
            }
        },
    }
 
}
</script>

<style scoped>

    .container {
        text-align: center;
        background: #F3F3F3;
        height: 100vh;
        margin: 0;
    }

    .logo {
        position: absolute;
        top: 26px;
        left: 30px;
        width: 100px;
        height: auto;
    }

    .error {
        color: red;
        margin-top: 20px;
        margin-right: 40px;
    }

    .card {
        height: 60%;
        width: 40%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #FFFF;
        display: flex;
        flex-direction: row;
        border: 1px solid #E1E1E1;
    }

    /* .img-container {
        background-color: rgb(121, 121, 121);
        width: 40%;
        height: 100%;
        float: left;
    } */

    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 80% 100%;
    }

    .login-container {
        width: 100%;
        /* margin-left: 40%; */
        height: 100%;
    }

    .login {
        margin: auto;
        height: 100%;
        width: 60%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
    .email {
        width: 100%;
        position: relative;
    }
    .pass {
        width: 100%;
        position: relative;
    }

    .iconn {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
    }

    .title {
        font-family: 'Butler', 'sans-sarif';
        font-size: 34px;
        font-weight: 350;
    }

    .input-title {
        font-size: 16px;
        margin: 0px;
        margin-bottom: 5px;
    }
    .input {
        width: 100%;
        /* height: 50px; */
        border-radius: 30px;
        /* background-color: #EDE7E3;  */
        border: 1px solid #CBCBCB;
        /* border: none; */
        padding: 20px 20px 20px 50px;
        box-sizing: border-box;
        font-size: 16px;
    }

    input:focus{
        outline: none;
    }

    .login-button {
        background-color: #414141;
        width: 100%;
        border-radius: 30px;
        text-align: center;
        padding: 5px;
        color: #FFFF;
        /* height: 50px; */
        font-size: 16px;
        line-height: 50px;
        border: none;
    }

    .login-button:hover {
        cursor: pointer;
    }

    /* PHONE RESPONSIVE */
    @media (max-width: 767px) {

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
    }

    .logo {
        position: initial;
        margin-bottom: 12%;
        width: 50vw;
    }

    .card {
        display: block;
        width: 100%;
        /* height: auto; */
        /* margin-top: 80px; */
        /* background-color: initial; */
        position: unset;
        transform: none;
        height: 70%;
    }

    .img-container {
        display: none;
    }

    .img {
        display: none;
    }

    .login-container {
        margin-left: 0%;
        width: 100%;
        height: 100%;
    }

    .login {
        /* margin-left: 0%; */
        height: 100%;
        text-align: center;
        justify-content: center;
        gap: 50px;
    }

    input[type="text"]
    {
        font-size:16px;
    }

    input[type="password"]
    {
        font-size:16px;
    }

    .title {
        display: none;
    }

    .input-title {
        display: none;
    }

    .input {
        height: 15vw;
        background-color: #ffffff; 
        border: 1px solid rgb(206, 206, 206);
    }

    input:focus{
        outline: initial;
    }

    .login-button {
        /* width: 50%; */
        border-radius: 8px;
        height: 15vw;
        font-size: 16px;
        line-height: 15vw;
        margin-left: auto;
        margin-right: auto;
    }

    .login-button:hover {
        cursor: pointer;
    }
    }
    
</style>