<template>
    <div class="test" @click="$emit('open', test)">
        <p>
            <status-icon v-if="showIcon" :status="test.status" />
            {{ test.name }}
        </p>
    </div>
</template>

<script>
import StatusIcon from './StatusIcon.vue'

export default {
    name: 'SingleTestView',
    emits: ['open'],
    components: {
        StatusIcon,
    },
    props: {
        test: {
            type: Object,
            required: true,
        },
        level: {
            type: Number,
            default: 0,
        },
        editing: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showIcon() {
            return 'status' in this.test;
        },
    },
}
</script>

<style lang="scss" scoped>
.test {
    width: 100%;

    p {
        margin: 0;
        cursor: pointer;
    }
}

</style>