<template>
    <Dropdown :value="urgency" @input="updateUrgency(parseInt($event))" :disabled="disabled">
        <div value="1"><div class="dot red"></div> 1</div>
        <div value="2"><div class="dot yellow"></div> 2</div>
        <div value="3"><div class="dot green"></div> 3</div>
        <div value="4"><div class="dot green"></div> 4</div>
    </Dropdown>
</template>

<script>
import Dropdown from '@/components/Dropdown.vue'

export default {
    name: 'UrgencyDropdown',
    components: {
        Dropdown,
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        value: {
            type: Number,
            default: 1,
        },
        dev: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        urgency: 1,
    }),
    methods: {
        async updateUrgency(urgency) {
            if (this.item) await this.$store.dispatch(`Project/updateStatus`, {
                item: this.item,
                urgency,
            });
            this.urgency = urgency;
            if (!this.item) this.$emit('input', this.urgency);
        },
    },
    watch: {
        item: {
            handler() {
                if (this.item) this.urgency = this.item.urgency;
                else this.urgency = this.value;
            },
            immediate: true,
        },
        value: {
            handler() {
                if (this.item) this.urgency = this.item.urgency;
                else this.urgency = this.value;
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: black;

    &.red {
        background: #C63030;
    }

    &.yellow {
        background: #EACE3C;
    }

    &.green {
        background: #88DB6B;
    }
}
</style>