<template>
    <button @click.self.stop="$emit('clicked')" :class="{ small: small, disabled: disabled }" class="btn-type" :disabled="disabled" v-if="buttonType === 'new' ">
        <slot></slot>
    </button>
    <button @click.self.stop="$emit('clicked')" :class="[color + '-btn', { small: small, disabled: disabled }]" :disabled="disabled" v-else>
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: false,
        },
        buttonType: {
            type: String,
            required: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['clicked'],
    computed: {
        btnType() {
            this.buttonType
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-type {
    color: black;
    background: white;
    border-radius: 25px;
    padding: 10px 25px;
    border: #CCCCCC solid 1px;
}

button {
    background: none;
    border-radius: 25px;
    padding: 10px 20px;
    border: #CCCCCC solid 1px;
    color: black;

    &.small {
        padding: 5px 10px;
    }

    &.disabled {
        color: lightgrey;
    }
}

.red-btn {
    border: #F5AAAA 1px solid;
}

.green-btn {
    border-color: #9CC19C;
}

button:hover {
    cursor: pointer;
}
</style>