<template>
    <div class="big">
        <div class="content">
            <base-popup v-if="createPopup" @close="createPopup = false" class="createProject">
                <div class="popz">
                    <h1>Opprett prosjekt</h1>
                    <p>Legg til navn på prosjektet og estimert tid for å gjennomføre prosjektet.</p>
                    <input placeholder="Prosjektnavn" v-model="projectName" />
                    <p v-if="error">{{ error }}</p>
                    <button @click="opprettProsjekt">Lagre prosjekt</button>
                </div>
            </base-popup>
            <div class="header">
                <h1>Prosjekter</h1>
                <button class="new-btn" @click="nyttProsjekt" v-if="isAdmin">Nytt prosjekt</button>
            </div>
            <base-card>
                <project-items v-if="isAdmin" :projects="$store.state.Project.projectsList" />
                <project-items v-else :projects="projects"></project-items>
            </base-card>
        </div>
    </div>
</template>

<script>
import ProjectItems from "@/components/ProjectItems.vue";
import { serverTimestamp } from 'firebase/firestore';

export default {
    name: "DeveloperFrontpage",

    components: { ProjectItems },

    data() {
        return {
            projects: [],
            createPopup: false,
            projectName: '',
            error: '',
        }
    },
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
    },
    methods: {
        nyttProsjekt() {
            this.createPopup = true;
        },
        async opprettProsjekt(){
            await this.$store.dispatch(`Project/addProject`, { projectName: this.projectName });
            this.projectName = '';
            this.error = '';
            this.createPopup = false;
        },
    },
    async mounted() {
        if (!this.isAdmin) {
            this.projects = await this.$store.dispatch('Project/fetchProjectsbyUserId', this.$store.state.User.currentUser.id);
        }
    },
};
</script>


<style lang="scss" scoped>
h3 {
    font-size: 12px;
}

.popz {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    /* box-sizing: border-box; */
    padding: 25px;

    input {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        border-radius: 50px;
        border: 1px solid #B9B9B9;
    }

    button {
        width: 80%;
        padding: 20px;
        border-radius: 50px;
        border: none;
        background: #646363;
        color: white;
        cursor: pointer;
    }
}

.big {
    display: flex;
    flex-direction: row;
}

.content {
    background: #f6f6f6;
    flex-grow: 1;
    /* padding: 15px 50px; */
    box-sizing: border-box;
    height: 100vh;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.new-btn {
    color: black;
    background: white;
    border-radius: 25px;
    padding: 10px 25px;
    border: #CCCCCC solid 1px;
}
</style>
