<template>
    <ReportDetails :report="report" :has-work-sessions="false" v-if="report">
        <template #title>Brukerrapport: {{ report.title }}</template>
        <template #details>
            <div class="item-list">
                <div class="item-grid title">
                    <h2>Rapportert</h2>
                </div>
                <div class="item-grid">
                    <p>{{ printDate }}</p>
                </div>
            </div>
        </template>
        <template #right-side>
            <div class="submit-container">
                <base-card class="submit-card submit-bug">
                    <div class="title">
                        <h2>Send inn som feil</h2>
                    </div>
                    <div class="severity">
                        <div>
                            <h2>Alvorlighetsgrad</h2>
                            <p>Sett en alvorlighetsgrad for å videresende problemet til utvikler</p>
                            <div class="severity-radio">
                                <div>
                                    <input type="radio" v-model="urgency" id="ur-one" value="1" />
                                    <label for="ur-one">1 (høy)</label>
                                </div>
                                <div>
                                    <input type="radio" v-model="urgency" id="ur-two" value="2" />
                                    <label for="ur-two">2</label>
                                </div>
                                <div>
                                    <input type="radio" v-model="urgency" id="ur-three" value="3" />
                                    <label for="ur-three">3</label>
                                </div>
                                <div>
                                    <input type="radio" v-model="urgency" id="ur-four" value="4" />
                                    <label for="ur-four">4 (lav)</label>
                                </div>
                            </div>
                            <h2>Kommentar</h2>
                            <textarea placeholder="Skriv en kommentar (valgfritt)" v-model="bugComment"></textarea>
                        </div>
                        <div class="submit-btns">
                            <!--button>Legg til i listen av videreutviklinger</button-->
                            <button @click="saveAsCustomerBug">Legg til i listen av feil</button>
                        </div>
                    </div>
                </base-card>
                <base-card class="submit-card submit-development">
                    <div class="title">
                        <h2>Send inn som oppgave</h2>
                    </div>
                    <div class="severity">
                        <div>
                            <h2>Prioritet</h2>
                            <p>Sett et prioritetsnivå for oppgaven</p>
                            <div class="severity-radio">
                                <div>
                                    <input type="radio" v-model="priority" id="pri-three" value="3" />
                                    <label for="pri-three">Høy</label>
                                </div>
                                <div>
                                    <input type="radio" v-model="priority" id="pri-two" value="2" />
                                    <label for="pri-two">Middels</label>
                                </div>
                                <div>
                                    <input type="radio" v-model="priority" id="pri-one" value="1" />
                                    <label for="pri-one">Lav</label>
                                </div>
                            </div>
                            <h2>Kommentar</h2>
                            <textarea placeholder="Skriv en kommentar (valgfritt)" v-model="developmentComment"></textarea>
                        </div>
                        <div class="submit-btns">
                            <!--button>Legg til i listen av videreutviklinger</button-->
                            <button @click="saveAsDevelopment">Legg til i listen av oppgaver</button>
                        </div>
                    </div>
                </base-card>
            </div>
        </template>
    </ReportDetails>
</template>

<script>
import ReportDetails from '@/views/Report/ReportDetails.vue'

export default {
    name: 'UserBugDetails',
    components: {
        ReportDetails,
    },
    data: () => ({
        report: null,
        urgency: 1,
        priority: 3,
        bugComment: '',
        developmentComment: '',
    }),
    computed: {
        printDate() {
            if (this.report.date == undefined) return;
            return this.report.date.toLocaleString().split(",")[0];
        },
    },
    methods: {
        async saveAsCustomerBug() {
            const id = await this.$store.dispatch('Project/saveUserBugAsCustomerBug', { report: this.report, urgency: parseInt(this.urgency), comment: this.bugComment });
            this.$router.push({ name: 'CustomerBugDetails', params: { reportId: id } });
        },
        async saveAsDevelopment() {
            const id = await this.$store.dispatch('Project/saveUserBugAsDevelopment', { report: this.report, priority: parseInt(this.priority), comment: this.developmentComment });
            this.$router.push({ name: 'DevelopmentDetails', params: { developmentId: id } });
        },
    },
    async mounted() {
        this.report = await this.$store.dispatch(`Project/fetchUserBug`, {
            projectId: this.$route.params.projectId,
            reportId: this.$route.params.reportId,
        });
    },
}
</script>

<style lang="scss" scoped>
.submit-btns {
    display: flex;
    justify-content: space-around;
    button {
        width: 45%;
    }
}
.item-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 5px;
}

.submit-container {
    grid-area: chat;
    gap: 15px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .submit-card {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .severity {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            .severity-radio {
                display: flex;
                justify-content: space-evenly;
            }

            button {
                padding: 20px 15px;
                background: #646363;
                color: white;
                border-radius: 10px;
                border: none;
            }

            textarea {
                resize: none;
                width: 100%;
                border-radius: 20px;
                padding: 20px;
                box-sizing: border-box;
            }
        }
    }
}
</style>