import FirestoreType from './FirestoreType'

export default class TestPlan extends FirestoreType {
    get name() { return this.data.name }
    get createdAt() { return this.data.createdAt.toDate() }
    get items() { return this.data.items }

    get isRun() { return false }

    set name(val) { this.data.name = val }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}
