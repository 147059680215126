<template>
    <div class="invite-page">
        <h1>Invitasjon til Devhelper</h1>
        <base-card>
            <h2>Opprett bruker</h2>
            <form @submit.prevent="submitAcceptInvite" action="/" class="accept-invite-container">
                <div class="accept-invite-inputs">
                    <span>Brukernavn:</span>
                    <padded-input type="text" v-model="username" :disabled="sending" />
                    <span>Passord:</span>
                    <padded-input type="password" v-model="password1" :disabled="sending" />
                    <span>Passord (gjenta):</span>
                    <padded-input type="password" v-model="password2" :disabled="sending" />
                </div>
                <base-button type="submit" :disabled="sending">Opprett bruker</base-button>
            </form>
        </base-card>
        <info-dialog ref="info" />
    </div>
</template>

<script>
import PaddedInput from '@/components/PaddedInput.vue'
import InfoDialog from '@/components/Modal/InfoDialog.vue'

export default {
    name: 'UserInvite',
    components: {
        PaddedInput,
        InfoDialog,
    },
    data: () => ({
        username: '',
        password1: '',
        password2: '',

        sending: false,
    }),
    methods: {
        async submitAcceptInvite() {
            if (this.username.trim() == '') return this.$refs.info.tell('Du må skrive inn et brukernavn.', 'error');
            if (this.password1 == '') return this.$refs.info.tell('Du må skrive inn et passord.', 'error');
            if (this.password1 != this.password2) return this.$refs.info.tell('Du må skrive samme passord begge ganger.', 'error');

            this.sending = true;
            const result = await this.$store.dispatch('User/acceptInvite', Object.assign({}, this.$route.query, { username: this.username, password: this.password1 }));
            if (result.data.status == 'error') {
                this.sending = false;
                if (result.data.error == 'not_allowed') this.$refs.info.tell('Kunne ikke opprette bruker.', 'error');
                else if (result.data.error == 'already_used') this.$refs.info.tell('Denne invitasjonen har allerede blitt brukt.', 'error');
                else this.$refs.info.tell(result.data.error, 'error');
            } else {
                await this.$store.dispatch('User/signInWithToken', result.data.value.authToken);
                await this.$refs.info.tell('Kontoen din har blitt opprettet.', 'success');
                this.$router.push({ name: 'CustomerFrontpage'});
            }
        },
    },
    beforeMount() {
        const id = this.$route.query.id;
        const token = this.$route.query.token;

        if (!id || !token) this.$router.push({ name: 'Login' });
    },
}
</script>

<style lang="scss" scoped>
.invite-page {
    background: #f6f6f6;
    box-sizing: border-box;
    height: 100vh;
    padding: 15px 50px 30px;

    .accept-invite-inputs {
        display: grid;
        grid-template-columns: 250px max(300px);
        align-items: center;
        gap: 10px;
    }
}
</style>