import FirestoreType from './FirestoreType'

export default class WorkSession extends FirestoreType {
    get uid() { return this.data.uid; }
    get name() { return this.data.name; }
    get date() { return this.data.date.toDate(); }
    get hours() { return this.data.hours; }
    get title() { return this.data.title; }

    get projectId() { return this.path.match(/^Project\/([^/]+)/)[1]; }
}