var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('report-overview',{attrs:{"items":_vm.items,"status-tabs":"","extra-fields":['Prioritet', 'Est. timer'],"actions":[
        {
            id: 'open',
            name: 'Åpne',
            default: true,
        },
        {
            id: 'delete',
            name: 'Slett',
            border: 'red',
            dev: true,
        },
    ],"allowCreate":""},on:{"open":_vm.openItem,"delete":_vm.deleteItem,"new":_vm.createItem},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Oppgaver")]},proxy:true},{key:"new-button",fn:function(){return [_vm._v("+ Ny oppgave")]},proxy:true},{key:"item-extra",fn:function(data){return [_c('PriorityDropdown',{attrs:{"item":data.item}}),_c('EstimatedHours',{attrs:{"item":data.item,"disabled":""}})]}}])}),_c('confirm-dialog',{ref:"deleteModal",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Slett oppgave")]},proxy:true},{key:"text",fn:function(){return [_vm._v("Er du sikker på at du vil slette "+_vm._s(_vm.itemToDelete.title)+"?")]},proxy:true}]),model:{value:(_vm.deleteModalOpen),callback:function ($$v) {_vm.deleteModalOpen=$$v},expression:"deleteModalOpen"}}),_c('modal',{model:{value:(_vm.createModalOpen),callback:function ($$v) {_vm.createModalOpen=$$v},expression:"createModalOpen"}},[_c('div',{staticClass:"create-modal"},[_c('h3',[_vm._v("Opprett oppgave")]),_c('div',{staticClass:"create-line"},[_c('span',[_vm._v("Tittel")]),_c('padded-input',{attrs:{"type":"text"},model:{value:(_vm.createTitle),callback:function ($$v) {_vm.createTitle=$$v},expression:"createTitle"}})],1),_c('div',{staticClass:"create-line"},[_c('span',[_vm._v("Beskrivelse")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.createDescription),expression:"createDescription"}],domProps:{"value":(_vm.createDescription)},on:{"input":function($event){if($event.target.composing)return;_vm.createDescription=$event.target.value}}})]),_c('div',{staticClass:"create-line"},[_c('span',[_vm._v("Prioritet")]),_c('priority-dropdown',{model:{value:(_vm.createPriority),callback:function ($$v) {_vm.createPriority=$$v},expression:"createPriority"}})],1),_c('dialog-button',{on:{"click":_vm.confirmCreate}},[_vm._v("Lagre")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }