<template>
    <div class="dashboard" v-if="project">
        <h1>Dashboard</h1>
        <div class="content">
            <div class="left">
                <base-card>
                    <div class="card-header">
                        <h2>Detaljer</h2>
                    </div>
                    <h2>Prosjektnavn</h2>
                    <editable-value :value="project.name" disabled />
                    <h2>Prisestimat</h2>
                    <editable-price :value="project.priceEstimate" disabled />
                </base-card>
                <base-card class="grow">
                    <div class="card-header">
                        <h2>Prisforslag</h2>
                    </div>
                    <div v-if="proposedPrices.length == 0">Ingen nye prisforslag</div>
                    <template v-else>
                        <div class="proposed-price header">
                            <h2>Navn</h2>
                            <h2>Pris</h2>
                            <h2></h2>
                        </div>
                        <div class="proposed-price" v-for="development in proposedPrices">
                            <p>{{ development.title }}</p>
                            <editable-price :value="development.price" disabled />
                            <router-link :to="link(development)"><base-button>Vis</base-button></router-link>
                        </div>
                    </template>
                </base-card>
            </div>
            <div class="right">
                <base-card class="grow">
                    <div class="card-header">
                        <h2>Nylig fullført</h2>
                    </div>
                    <div v-if="recentlyCompleted.length == 0">Ingen nylige fullførte</div>
                    <template v-else>
                        <div class="recently-completed header">
                            <h2>Navn</h2>
                            <h2>Fullført</h2>
                            <h2></h2>
                        </div>
                        <div class="recently-completed" v-for="completed in recentlyCompleted">
                            <p>{{ completed.title }}</p>
                            <p>{{ completed.date.toLocaleString().split(',')[0] }}</p>
                            <router-link :to="link(completed)"><base-button>Vis</base-button></router-link>
                        </div>
                    </template>
                </base-card>
            </div>
        </div>
    </div>
</template>

<script>
import EditablePrice from '@/components/EditablePrice.vue'
import EditableValue from '@/components/EditableValue.vue'

import CustomerBug from '@/types/CustomerBug'
import Development from '@/types/Development'

export default {
    name: 'UserDashboard',
    components: {
        EditablePrice,
        EditableValue,
    },
    data: () => ({
        project: null,
        proposedPrices: [],
        recentlyCompleted: [],
    }),
    methods: {
        link(item) {
            if (item instanceof Development) return { name: 'DevelopmentDetails', params: { developmentId: item.id } }
            if (item instanceof CustomerBug) return { name: 'CustomerBugDetails', params: { reportId: item.id } }
        },
    },
    async mounted() {
        const [project, proposedPrices, recentlyCompleted] = await Promise.all([
            this.$store.dispatch(`Project/fetchProject`, this.$route.params.projectId),
            this.$store.dispatch('Project/fetchProposedPriceDevelopments', { projectId: this.$route.params.projectId }),
            this.$store.dispatch('Project/fetchRecentlyCompleted', { projectId: this.$route.params.projectId }),
        ]);

        this.project = project;
        this.proposedPrices = proposedPrices;
        this.recentlyCompleted = recentlyCompleted;
    },
}
</script>

<style lang="scss" scoped>
.dashboard {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        display: flex;
        gap: 30px;
        flex-grow: 1;

        .left {
            width: 1px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .proposed-price {
                display: grid;
                grid-template-columns: 1fr 0.5fr 0.5fr;
                gap: 20px;
                align-items: center;
            }
        }

        .right {
            width: 1px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            gap: 30px;

            .recently-completed {
                display: grid;
                grid-template-columns: 1fr 0.5fr 0.5fr;
                gap: 20px;
                align-items: center;
            }
        }
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #dcdcdc;
    }

    .grow {
        flex-grow: 1;
    }
}
</style>