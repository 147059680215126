<template>
    <base-button class="create-reminder-button" @clicked="createReminder">
        <slot>Ny påminnelse</slot>

        <input-dialog type="text" placeholder="Tekst" v-model="newReminderTextModal" ref="reminderText">
            <template #title>Påminnelse</template>
            <template #text>Hva vil du bli minnet på?</template>
            <template #button>Neste</template>
        </input-dialog>
        <input-dialog type="datetime-local" placeholder="Dato" v-model="newReminderDateModal" ref="reminderDate">
            <template #title>Frist</template>
            <template #text>Hvis påminnelsen har en frist, skriv den inn</template>
            <template #button>Legg til</template>
        </input-dialog>
        <info-dialog ref="info" />
    </base-button>
</template>

<script>
import InputDialog from '@/components/Modal/InputDialog.vue'
import InfoDialog from '@/components/Modal/InfoDialog.vue'

export default {
    name: 'CreateReminderButton',
    components: {
        InputDialog,
        InfoDialog,
    },
    props: {
        projectId: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: false,
        },
    },
    data: () => ({
        newReminderTextModal: false,
        newReminderDateModal: false,
    }),
    methods: {
        async createReminder() {
            const text = await this.$refs.reminderText.ask();
            if (!text || text.trim() == '') return;

            let date = await this.$refs.reminderDate.ask();
            if (date === null) return;

            await this.$store.dispatch('Project/addReminder', { projectId: this.projectId, text, deadline: date == '' ? null : new Date(date), link: this.link });
            this.$refs.info.tell('Påminnelsen har blitt lagret', 'success');
        },
    },
}
</script>

<style lang="scss" scoped>
.create-reminder-button {
    background: white;
}
</style>