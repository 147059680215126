<template>
    <i class="fa fa-question-circle-o none" v-if="status == 'none'" />
    <i class="fa fa-ellipsis-h running" v-else-if="status == 'running'" />
    <i class="fa fa-check-square ok" v-else-if="status == 'ok'" />
    <i class="fa fa-info-circle ok_note" v-else-if="status == 'ok_note'" />
    <i class="fa fa-exclamation-triangle error" v-else-if="status == 'error'" />
    <i class="fa fa-minus-circle skipped" v-else-if="status == 'skipped'" />
</template>

<script>
export default {
    name: 'StatusIcon',
    props: {
        status: {
            type: String,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.none {
    color: black;
}

.running {
    color: lightgrey;
}

.ok {
    color: green;
}

.ok_note {
    color: green;
}

.error {
    color: red;
}

.skipped {
    color: lightgrey;
}
</style>