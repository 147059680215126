export default function deepClone(obj) {
    if (typeof obj == 'undefined') return obj;
    if (typeof obj == 'number') return obj;
    if (typeof obj == 'string') return obj;
    if (typeof obj == 'bigint') return obj;
    if (typeof obj == 'symbol') return obj;
    if (typeof obj == 'function') return obj;

    switch (typeof obj) {
        case 'undefined':
        case 'number':
        case 'string':
        case 'boolean':
        case 'bigint':
        case 'symbol':
        case 'function':
            return obj;
        case 'object':
            if (obj === null) return obj;
            if (Array.isArray(obj)) return obj.map(deepClone);
            const ret = {};
            for (let k in obj) ret[deepClone(k)] = deepClone(obj[k]);
            return ret;
    }
}