<template>
    <div class="editable-value">
        <div class="display" @click="editValue">
            <span><slot name="value">{{ value }}</slot></span>
            <i class="fa fa-pencil" v-if="!disabled"></i>
        </div>
        <input-dialog ref="input" :type="type" v-model="modalOpen" :validate="validate">
            <template #title><slot name="title"></slot></template>
            <template #text><slot name="text"></slot></template>
        </input-dialog>
    </div>
</template>

<script>
import InputDialog from '@/components/Modal/InputDialog.vue'

export default {
    name: 'EditableValue',
    components: {
        InputDialog,
    },
    props: {
        type: {
            type: String,
            default: null,
        },
        value: {
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        validate: {
            type: Function,
            required: false,
        },
    },
    data: () => ({
        modalOpen: false,
        error: null,
    }),
    methods: {
        async editValue() {
            if (this.disabled) return;
            this.$emit('open');
            let newValue = await this.$refs.input.ask(this.value);
            if (this.validate) {
                const error = this.validate(newValue);
                if (error) {
                    this.error = error;
                    return;
                }
            }
            this.error = null;
            this.$emit('input', newValue);
        },
    },
}
</script>

<style lang="scss" scoped>
.display {
    display: flex;
    gap: 10px;
    align-items: center;

    i {
        transform: scale(1.25);
    }
}
</style>