<template>
    <div class="main" v-if="project">
        <div class="header">
            <h1>Timelister for {{ project.name }}</h1>
            <base-button button-type="new" @clicked="creatingInvoice = false" v-if="creatingInvoice">Avbryt</base-button>
            <base-button button-type="new" @clicked="startCreatingInvoice" v-else>Legg til fakturering</base-button>
        </div>
        <div class="main-grid" :class="{ 'creating-invoice': creatingInvoice }">
            <base-card class="stats" v-if="!creatingInvoice">
                <div class="stats-list">
                    <div class="stats-grid title">
                        <h2>Estimerte timer</h2>
                        <h2>Totalt timer</h2>
                        <h2>Avtalt timepris</h2>
                        <h2>Ufakturerte timer</h2>
                    </div>
                    <div class="stats-grid">
                        <p>{{ estimatedHours }}</p>
                        <p>{{ totalHours }}</p>
                        <p>{{ toDisplayPrice(project.pricePerHour) }}</p>
                        <p>{{ uninvoicedHours }}</p>
                    </div>
                </div>
            </base-card>
            <base-card class="tasks">
                <div class="title">
                    <h2>Oppgaver</h2>
                </div>
                <div class="tasks-table-wrapper">
                    <table>
                        <tr>
                            <th>Oppgave</th>
                            <th>Dato</th>
                            <th>Est</th>
                            <th>Tid</th>
                            <th>Status</th>
                            <th>Faktura</th>
                        </tr>
                        <tr v-for="task in taskList" @click="goToTask(task)">
                            <td>{{ task.title }}</td>
                            <td>{{ dateString(task.date) }}</td>
                            <td>{{ task.estimatedHours }}</td>
                            <td>{{ task.totalHours }}</td>
                            <td><status-dropdown :item="task" disabled /></td>
                            <td>
                                <div class="invoice-dot" v-if="task.billable && !creatingInvoice" :class="{ invoiced: task.invoiced }"></div>
                                <input type="checkbox" class="invoice-checkbox" v-if="task.billable && !task.invoiced && creatingInvoice" v-model="invoiceChecks[task.itemId]" />
                            </td>
                        </tr>
                    </table>
                </div>
            </base-card>
            <div class="invoices" v-if="creatingInvoice">
                <base-card>
                    <div class="invoice-sections">
                        <h2 class="title">Ny fakturering</h2>
                        <div class="table-container">
                            <table>
                                <tr>
                                    <th>Oppgave</th>
                                    <th>Dato</th>
                                    <th>Tid</th>
                                    <th>Pris</th>
                                </tr>
                                <tr v-for="task in selectedTasks">
                                    <td>{{ task.title }}</td>
                                    <td>{{ dateString(task.date) }}</td>
                                    <td>{{ task.totalHours }}</td>
                                    <td>{{ toDisplayPrice(task.price) }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="summary">
                            <div class="info">
                                <div><span>Timer totalt:</span><span>{{ selectedTasksHours }}<span class="white">,-</span></span></div>
                                <div><span>Pris totalt:</span><span>{{ toDisplayPrice(selectedTasksPrice) }}</span></div>
                            </div>
                            <div class="submit" @click="saveInvoice" :class="{ disabled: !selectedTasks.length }">Lagre faktura</div>
                        </div>
                    </div>
                </base-card>
            </div>
            <div class="invoices" v-else>
                <base-card>
                    <div class="invoice-sections">
                        <div class="title">
                            <h2>Faktureringer</h2>
                        </div>
                        <div class="table-container">
                            <table>
                                <tr>
                                    <th>Dato</th>
                                    <th>Pris</th>
                                    <th>Timer</th>
                                </tr>
                                <tr v-for="invoice in invoices">
                                    <td>{{ dateString(invoice.date) }}</td>
                                    <td>{{ toDisplayPrice(invoice.price) }}</td>
                                    <td>{{ invoice.hours }}</td>
                                </tr>
                            </table>
                        </div>
                        <div class="sum">
                            <span>Sum</span>
                            <span>{{ toDisplayPrice(invoicePriceSum) }}</span>
                            <span>{{ invoiceHoursSum }} timer</span>
                        </div>
                    </div>
                </base-card>
            </div>
        </div>
    </div>
</template>

<script>
import StatusDropdown from '@/components/Dropdown/StatusDropdown.vue'

export default {
    name: 'ProjectTimesheet',
    components: {
        StatusDropdown,
    },
    data: () => ({
        project: null,
        customerBugs: null,
        developments: null,

        projectCostInfo: null,
        taskList: [],
        invoices: [],

        creatingInvoice: false,
        invoiceChecks: {},
    }),
    computed: {
        estimatedHours() {
            return this.taskList.reduce((a, n) => a + n.estimatedHours, 0);
        },
        totalHours() {
            return this.taskList.reduce((a, n) => a + n.totalHours, 0);
        },
        uninvoicedHours() {
            return this.taskList.filter(task => task.billable && !task.invoiced).reduce((a, n) => a + n.totalHours, 0);
        },
        selectedTasks() {
            return this.taskList.filter(task => this.invoiceChecks[task.itemId]);
        },
        selectedTasksHours() {
            return this.selectedTasks.reduce((a, n) => a + n.totalHours, 0);
        },
        selectedTasksPrice() {
            return this.selectedTasks.reduce((a, n) => a + n.price, 0);
        },
        invoicePriceSum() {
            return this.invoices.reduce((a, n) => a + n.price, 0);
        },
        invoiceHoursSum() {
            return this.invoices.reduce((a, n) => a + n.hours, 0);
        },
    },
    methods: {
        dateString(date) {
            return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
        },
        toDisplayPrice(price) {
            let whole = Math.floor(price);
            let dec = price - whole;

            const out = [];
            while (whole > 1000) {
                let next = `${whole % 1000}`;
                while (next.length < 3) next = `0${next}`;
                out.push(next);
                whole = Math.floor(whole / 1000);
            }
            out.push(whole);
            out.reverse();
            return out.join(' ') + ',' + (dec ? dec : '-');
        },
        async fetchTasksAndInvoices() {
            this.invoices = await this.$store.dispatch('Project/fetchInvoices', { project: this.project });
            this.taskList = await this.$store.dispatch('Project/fetchCostInfos', { project: this.project });

            this.taskList.sort((a, b) => {
                // Tasks that have already been invoiced go after ones that haven't, even those that can't
                if (!a.invoiced && b.invoiced) return -1;
                if (a.invoiced && !b.invoiced) return 1;

                // Tasks that can currently be invoiced go before ones that can't
                if (a.canBeInvoiced && !b.canBeInvoiced) return -1;
                if (!a.canBeInvoiced && b.canBeInvoiced) return 1;

                // Completed tasks go before incomplete tasks
                if (a.status == 'completed' && b.status != 'completed') return -1;
                if (a.status != 'completed' && b.status == 'completed') return 1;

                // All else being equal, sort by time
                return a.date.getTime() - b.date.getTime();
            });
        },
        startCreatingInvoice() {
            this.creatingInvoice = !this.creatingInvoice;
            this.invoiceChecks = {};
        },
        async saveInvoice() {
            if (this.selectedTasks.length == 0) return;
            this.creatingInvoice = false;
            await this.$store.dispatch('Project/postInvoice', { project: this.project, tasks: this.selectedTasks });
            await this.fetchTasksAndInvoices();
        },
        goToTask(task) {
            if (this.creatingInvoice) return;
            this.$router.push(task.link);
        },
    },
    async mounted() {
        this.project = await this.$store.dispatch('Project/fetchProject', this.$route.params.projectId);
        this.customerBugs = await this.$store.dispatch(`Project/fetchCustomerBugs`, this.$route.params.projectId);
        this.developments = await this.$store.dispatch(`Project/fetchDevelopments`, this.$route.params.projectId);

        this.projectCostInfo = await this.$store.dispatch('Project/fetchCostInfo', { item: this.project });
        await this.fetchTasksAndInvoices();
    },
}
</script>

<style lang="scss" scoped>
.main {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stats-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stats-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 5px;
}

.main-grid {
    height: 100%;
    display: grid;
    gap: 15px;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 140px auto;
    grid-template-areas:
        "stats invoices"
        "tasks invoices";

    &.creating-invoice {
        grid-template-rows: auto;
        grid-template-areas: "tasks invoices";
    }

    .stats {
        grid-area: stats;
    }

    .tasks {
        grid-area: tasks;
    }

    .invoices {
        grid-area: invoices;

        > .card {
            position: sticky;
            top: 15px;
        }
    }
}

.title {
    border-bottom: solid 1px #dcdcdc;
}

.tasks-table-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;

    table {
        border-spacing: 0;
        width: 100%;

        th {
            text-align: left;
        }
    }
}

.invoice-dot {
    width: 10px;
    height: 10px;
    margin: auto;
    border-radius: 100%;
    background: red;

    &.invoiced {
        background: lightgreen;
    }
}

.invoice-checkbox {
    display: block;
    margin: auto;
}

.invoice-sections {
    display: flex;
    flex-direction: column;
    height: 100%;

    .table-container {
        //height: 1px;
        //flex-grow: 1;

        table {
            width: 100%;

            th {
                text-align: left;
            }
        }
    }

    .sum {
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-around;
        font-weight: bold;
        padding-top: 10px;
    }

    .summary {
        border-top: 1px solid lightgrey;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;

        .info {
            flex-grow: 1;
            max-width: 200px;

            > div {
                display: flex;
                justify-content: space-between;

                .white {
                    color: white;
                }
            }
        }

        .submit {
            padding: 10px 30px;
            background: #666;
            color: white;
            font-weight: bold;
            border-radius: 10000px;
            cursor: pointer;

            &.disabled {
                background: #DDD
            }
        }
    }
}
</style>