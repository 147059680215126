import FirestoreType from './FirestoreType'

export default class CostInfo extends FirestoreType {
    get projectId() { return this.data.projectId; }
    get itemId() { return this.data.itemId; }
    get title() { return this.data.title; }
    get price() { return this.data.price || 0; }
    get date() { return this.data.date.toDate(); }
    get estimatedHours() { return this.data.estimatedHours || 0; }
    get totalHours() { return this.data.totalHours || 0; }
    get status() { return this.data.status ?? 'unhandled'; }
    get invoiced() { return this.data.invoiced || false; }

    get link() {
        // Return an object that can be given to a router-link
        switch (this.ref.parent.parent.parent.id) {
            case 'Developments': return { name: 'DevelopmentDetails', params: { developmentId: this.itemId } };
            case 'CustomerBugs': return { name: 'CustomerBugDetails', params: { reportId: this.itemId } };
        }
    }

    get billable() {
        return true;
    }

    get canBeInvoiced() {
        return this.billable && !this.invoiced;
    }
}