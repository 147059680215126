import FirestoreType from './FirestoreType'

export default class Project extends FirestoreType {
    get name() { return this.data.name; }
    get isActive() { return this.data.isActive; }
    get date() { return this.data.date.toDate(); }
    get commentCount() { return this.data.commentCount || 0; }

    get customerBugs() { return this.data.customerBugs || 0; }
    get userBugs() { return this.data.userBugs || 0; }
    get criticalBugs() { return this.data.criticalBugs || 0; }
    get developments() { return this.data.further_dev || 0; }

    get pricePerHour() { return this.data.pricePerHour || 0; }
    get priceEstimate() { return this.data.priceEstimate || 0; }

    get access() { return this.data.access || []; }
    get devs() { return this.data.devs || []; }

    getReadCommentCount(uid) { return this.data['readComments_' + uid] || 0; }
    getUnreadCommentCount(uid) { return this.commentCount - this.getReadCommentCount(uid); }
}