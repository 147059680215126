<template>
    <div class="page-wrapper">
        <ReportDetails :report="report" v-if="report">
            <template #title>Oppgave: {{ report.title }}</template>
            <template #corner-element v-if="!priceAccepted">
                <create-reminder-button v-if="isDev" :projectId="$route.params.projectId" :link="pathName">Legg til påminnelse</create-reminder-button>
                <span class="price-not-accepted">Pris ikke godkjent</span>
            </template>
            <template #details>
                <div class="item-list">
                    <div class="item-grid title" :class="{ dev: isDev }">
                        <h2>Rapportert</h2>
                        <h2>Status</h2>
                        <h2>Prioritet</h2>
                        <h2 v-if="isDev">Est. tid</h2>
                        <h2>Pris</h2>
                    </div>
                    <div class="item-grid" :class="{ dev: isDev }">
                        <p>{{ printDate }}</p>
                        <StatusDropdown :item="report" :disabled="!isDev" />
                        <PriorityDropdown :item="report" />
                        <EstimatedHours :item="report" @input="updatePriceFromHours($event)" v-if="isDev" />
                        <div class="price">
                            <editable-price :value="price" @input="editPrice" @open="pricePreAccepted = false" :disabled="!isDev" v-if="price !== null && (isDev || priceAccepted)">
                                <template #text>
                                    <p>Legg til en pris for utvikling av denne funksjonaliteten. Etter utfylling, sendes prisen til kunden for godkjenning.</p>
                                    <p>Ikke start utviklingen før prisen er godkjent.</p>
                                    <p><label><input type="checkbox" v-model="pricePreAccepted" /> Marker pris som godkjent</label></p>
                                </template>
                            </editable-price>
                            <div class="set-price price-button" @click="setPrice" v-else-if="isDev">Send pris</div>
                            <div class="approve-price price-button" @click="approvePrice" v-else-if="price > 0">
                                Se og godkjenn
                            </div>
                            <div class="no-price" v-else>Ikke satt</div>
                        </div>
                    </div>
                </div>
            </template>
        </ReportDetails>
        <input-dialog ref="setPriceDialog" v-model="setPriceDialogOpen">
            <template #title>Sett pris</template>
            <template #text>
                <p>Etter prosjektets timepris vil dette timetallet tilsi en pris på {{ displaySetPriceDefaultValue }}.</p>
                <p>Skriv inn en annen pris om ønskelig.</p>
                <p><label><input type="checkbox" v-model="pricePreAccepted" /> Marker pris som godkjent</label></p>
            </template>
            <template #button>Send</template>
        </input-dialog>
        <confirm-dialog ref="priceUpdateConfirm" v-model="priceUpdateConfirmModalOpen">
            <template #title>Endre pris?</template>
            <template #text>
                <p>Etter prosjektets timepris vil dette timetallet tilsi en pris på {{ displayUpdatePrice }}.</p>
                <p>Vil du oppdatere prisen på oppgaven til dette?</p>
                <p><label><input type="checkbox" v-model="pricePreAccepted" /> Marker pris som godkjent</label></p>
            </template>
        </confirm-dialog>
        <confirm-dialog ref="priceConfirm" v-model="priceConfirmModalOpen">
            <template #title>Godkjenn pris</template>
            <template #text>
                <p>Vårt prisforslag for denne oppgaven er {{ displayPrice }}.</p>
                <p>Godkjenner du dette prisforslaget?</p>
            </template>
        </confirm-dialog>
    </div>
</template>

<script>
import ReportDetails from '@/views/Report/ReportDetails.vue'
import StatusDropdown from '@/components/Dropdown/StatusDropdown.vue'
import PriorityDropdown from '@/components/Dropdown/PriorityDropdown.vue'
import EstimatedHours from '@/components/EstimatedHours.vue'
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'
import InputDialog from '@/components/Modal/InputDialog.vue'
import EditablePrice from '@/components/EditablePrice.vue'
import CreateReminderButton from '@/components/CreateReminderButton.vue'

export default {
    name: 'DevelopmentDetails',
    components: {
        ReportDetails,
        StatusDropdown,
        PriorityDropdown,
        EstimatedHours,
        ConfirmDialog,
        InputDialog,
        EditablePrice,
        CreateReminderButton,
    },
    data: () => ({
        report: null,
        project: null,

        setPriceDialogOpen: false,
        setPriceDefaultValue: 0,

        priceModalOpen: false,
        price: null,
        pricePreAccepted: false,
        priceAccepted: false,

        priceUpdateValue: 0,
        priceUpdateConfirmModalOpen: false,

        priceConfirmModalOpen: false,
    }),
    computed: {
        isAdmin() {
            if (!this.$store.state.User.currentUser) return false;
            return this.$store.state.User.currentUser.isAdmin;
        },
        isDev() {
            if (this.isAdmin) return true;
            if (!this.$store.state.User.currentUser) return false;
            const project = this.$store.state.Project.projectsList.find(proj => proj.id == this.report.projectId);
            if (!project) return false;
            return project.devs.includes(this.$store.state.User.currentUser.id);
        },
        printDate() {
            if (this.report.date == undefined) return;
            return this.report.date.toLocaleString().split(",")[0];
        },
        displayPrice() {
            if (!this.price) return null;
            return this.toDisplayPrice(this.price);
        },
        displayUpdatePrice() {
            if (!this.priceUpdateValue) return null;
            return this.toDisplayPrice(this.priceUpdateValue);
        },
        displaySetPriceDefaultValue() {
            if (!this.setPriceDefaultValue) return 0;
            return this.toDisplayPrice(this.setPriceDefaultValue);
        },
        pathName() {
            return window.location.pathname;
        },
    },
    methods: {
        toDisplayPrice(price) {
            let whole = Math.floor(price);
            let dec = price - whole;

            const out = [];
            while (whole > 1000) {
                let next = `${whole % 1000}`;
                while (next.length < 3) next = `0${next}`;
                out.push(next);
                whole = Math.floor(whole / 1000);
            }
            out.push(whole);
            out.reverse();
            return out.join(' ') + ',' + (dec ? dec : '-');
        },
        async updatePriceFromHours(hours) {
            if (!this.isDev) return;

            if (!this.project) this.project = await this.$store.dispatch('Project/fetchProject', this.$route.params.projectId);
            if (!this.project.pricePerHour) return;

            this.priceUpdateValue = hours * this.project.pricePerHour;
            if (this.priceUpdateValue == this.price) return;
            this.pricePreAccepted = false;
            const confirm = await this.$refs.priceUpdateConfirm.ask();
            if (!confirm) return;

            await this.$store.dispatch('Project/updatePrice', {
                item: this.report,
                price: this.priceUpdateValue,
                accepted: this.pricePreAccepted,
            });
            this.price = this.priceUpdateValue;
            this.priceAccepted = this.price == 0 || this.pricePreAccepted;
        },
        async setPrice() {
            if (!this.isDev) return;

            if (!this.project) this.project = await this.$store.dispatch('Project/fetchProject', this.$route.params.projectId);
            //if (!this.project.pricePerHour) return;

            const info = await this.$store.dispatch('Project/fetchCostInfo', { item: this.report });
            const hours = info ? info.estimatedHours : 0;

            this.setPriceDefaultValue = hours * this.project.pricePerHour;

            this.pricePreAccepted = false;
            const strPrice = await this.$refs.setPriceDialog.ask(this.setPriceDefaultValue);
            if (strPrice === null || strPrice === undefined || (typeof strPrice == 'string' && strPrice.trim() == '')) return;

            const price = parseFloat(strPrice);
            await this.$store.dispatch('Project/updatePrice', {
                item: this.report,
                price,
                accepted: this.pricePreAccepted,
            });
            this.price = price;
            this.priceAccepted = this.price == 0 || this.pricePreAccepted;
        },
        async editPrice(strPrice) {
            if (!this.isDev) return;
            if (strPrice === null) return;
            const price = parseFloat(strPrice);
            if (price == this.price && !this.pricePreAccepted) return;
            await this.$store.dispatch('Project/updatePrice', {
                item: this.report,
                price,
                accepted: this.pricePreAccepted,
            });
            this.price = price;
            this.priceAccepted = this.price == 0 || this.pricePreAccepted;
        },
        async approvePrice() {
            if (this.isDev) return;
            let confirm = await this.$refs.priceConfirm.ask();
            if (!confirm) return;
            await this.$store.dispatch('Project/confirmPrice', { item: this.report });
            this.priceAccepted = true;
        },
    },
    async mounted() {
        this.report = await this.$store.dispatch(`Project/fetchDevelopment`, {
            projectId: this.$route.params.projectId,
            developmentId: this.$route.params.developmentId,
        });
        this.price = this.report.price;
        this.priceAccepted = this.report.priceAccepted;

        this.$store.dispatch('Project/markCommentsAsRead', { parent: this.report });
    },
}
</script>

<style lang="scss" scoped>
.page-wrapper {
    height: 100%;
}

.price-not-accepted {
    color: darkred;
    font-weight: bold;
}

.item-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    gap: 5px;

    &.dev {
        grid-template-columns: 0.75fr 1fr 1fr 0.5fr 0.75fr;

        @media (max-width: 1450px) {
            grid-template-columns: 1fr 1fr 0.5fr 0.75fr;

            > :nth-child(1) {
                display: none;
            }
        }
    }

    .dropdown {
        width: 140px;
    }
}

.price-button {
    color: red;
    border: 1px solid red;
    border-radius: 10000px;
    padding: 5px 30px;
    text-align: center;
}
</style>