<template>
    <div class="dropdown" :class="{ open: isOpen, disabled: disabled }">
        <div class="main">
            <div class="value" ref="value" @click.stop="open" :class="{ projectSelect: projectSelector }">
                <div class="value-inner" v-pre></div>
                <i class="chevron fa" :class="{ 'fa-angle-down': !isOpen, 'fa-angle-up': isOpen }"></i>
            </div>
            <div class="options real-options" ref="options"><slot></slot></div>
        </div>
    </div>
</template>

<script>
const NO_VALUE = Symbol();

/**
 * Improved dropdown
 * 
 * To use: The dropdown options must be child elements of the dropdown element. Each element must have a value
 * attribute (string), which is used as the component's value. This can be set and fetched using v-model. The option
 * elements can be styled as usual, but should not have any events attached to them - choosing them by clicking is
 * included functionality in the dropdown component.
 * 
 * The code below uses non-Vue element handling for much of its functionality. This is because dropdowns need to behave
 * in some special ways to work well, that aren't easily written in Vue. This behavior is encapsulated in the dropdown
 * component, and does not need to be accounted for outside of it.
 */

export default {
    name: 'Dropdown',
    props: {
        value: {
            default: NO_VALUE,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        projectSelector: {
            type: Boolean,
            default: false,
        }
    },
    data: () => ({
        isOpen: false,
        overlayRect: {
            left: 0,
            top: 0,
        },
    }),
    methods: {
        clickOverlay() {
            this.isOpen = false;
        },
        clickOption(value) {
            this.$emit('input', value);
            this.isOpen = false;
        },
        open() {
            if (this.disabled) return;
            this.isOpen = true;
            this.$dropdownOverlay.open(this);
        },
        updateDisplayedValue() {
            const valueMap = {};
            let firstValue = null;
            [...this.$refs.options.children].forEach(child => {
                valueMap[child.getAttribute('value')] = child;
                if (!firstValue) firstValue = child.getAttribute('value');
            });

            const value = this.value == NO_VALUE ? firstValue : this.value;
            const el = (valueMap[value] ?? valueMap[firstValue]).cloneNode(true);
            this.$refs.value.firstChild.innerHTML = '';
            this.$refs.value.firstChild.appendChild(el);
        },
    },
    watch: {
        value() {
            this.updateDisplayedValue();
        },
    },
    mounted() {
        this.updateDisplayedValue();
    },
}
</script>

<style lang="scss" scoped>
.main {
    position: relative;
    cursor: pointer;
    //overflow: hidden;
}

.dropdown {
    justify-self: start;
}
.value {
    background: white;
    padding: 5px 10px;
    //border: 1px solid lightgrey;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .value-inner {
        flex-grow: 1;
    }

    .dropdown.open & {
        border-radius: 5px 5px 0 0;
    }

    .dropdown.disabled & {
        border-color: lightgrey;
    }
}

.projectSelect {
    padding: 0 5px 0 0;
    gap: 0;
}
.chevron {
    transform: scale(1.5);

    .dropdown.disabled & {
        display: none;
    }
}

.real-options {
    display: none;
}

.overlay-options-inner {
    & > * {
        cursor: pointer;
    }
}
</style>