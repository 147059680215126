<template>
    <div>
        <div class="wrapper">
            <div class="navbar">
                <!-- LOGO -->
              <img class="img" src="../assets/Link_Logo.png" alt="logo">

            </div>
        </div>
        <div class="sidebar">
            <h1 class="header">Hjelp til utfylling</h1>
            <h2 class="textHead">Tittel</h2>
            <p  class="text">Her er det viktig å skrive en generell beskrivende tekst av problemet.</p>
            <h2 class="textHead">Beskrivelse</h2>
            <p  class="text">Legg til en utfyllende beskrivelse av problemet. Gjerne inkluder informasjon om hva du trykket på/gjorde for å utløse feilen.</p>
            <h2 class="textHead">Filer</h2>
            <p  class="text">Relevante filer kan være screenshots eller skjermbilder som viser problemet.</p>
            <!--h2 class="textHead">Prioritet</h2>
            <p  class="text">1: Feilen gjør at produktet ikke fungerer i det hele tatt.</p>
            <p  class="text">2: Feilen går an å jobbe rundt, men det er enkelte ting jeg ikke får gjort.</p>
            <p  class="text">3: Feilen går an å jobbe rundt, og jeg har fortsatt mulighet å gjøre alle tingene jeg ønsker.</p>
            <p  class="text">4: Feilen er bare irriterende, eller et feil i utseende.</p-->
        </div>

        <!--div @click="setVisibility()" v-if="showInfo" class="background"></div-->
        <div v-if="showInfo" class="sidebar2">
            <p @click="setVisibility()" class="close">X</p>
            <h1 class="header">Hjelp til utfylling</h1>
            <h2 class="textHead">Tittel</h2>
            <p  class="text">Her er det viktig å skrive en generell beskrivende tekst av problemet.</p>
            <h2 class="textHead">Beskrivelse</h2>
            <p  class="text">Legg til en utfyllende beskrivelse av problemet. Gjerne inkluder informasjon om hva du trykket på/gjorde for å utløse feilen.</p>
            <h2 class="textHead">Filer</h2>
            <p  class="text">Relevante filer kan være screenshots eller skjermbilder som viser problemet.</p>
            <!--h2 class="textHead">Prioritet</h2>
            <p  class="text">1: Feilen gjør at produktet ikke fungerer i det hele tatt.</p>
            <p  class="text">2: Feilen går an å jobbe rundt, men det er enkelte ting jeg ikke får gjort.</p>
            <p  class="text">3: Feilen går an å jobbe rundt, og jeg har fortsatt mulighet å gjøre alle tingene jeg ønsker.</p>
            <p  class="text">4: Feilen er bare irriterende, eller et feil i utseende.</p-->
        </div>

        <div @click="setVisibility()" class="pullout">
              <i class="fa fa-info-circle"></i>
        </div>

        <p class="head">Opprett en feilrapport</p>

        <div class="container">
            <div class="break"></div>
            <div class="mid">
              <p class="content">Tittel</p>
              <textarea :class="{'error': fillTitle}" class="box" cols="30" rows="1" v-model="title"></textarea>
              <p class="content">Beskrivelse</p>
              <textarea :class="{'error': fillDescription}" class="box" cols="30" rows="12" v-model="description"></textarea>

              <div class="file">
                <input type="file" @change="fileChange" id="input" class="fileInput" ref="fileInput">
              </div>

              <button class="send" @click="sendInfo()">Send feilmelding</button>
            </div>
            <div class="break"></div>
        </div>
        <info-dialog ref="info" />
    </div>
</template>

<script>
import { serverTimestamp } from 'firebase/firestore';

import InfoDialog from '@/components/Modal/InfoDialog.vue'

export default {
    name: 'UserCreateBug',
    components: {
        InfoDialog,
    },
    data:() => ({
        title:        '',
        description:  '',

        fillTitle:       false,
        fillDescription: false,
        showInfo:        false,

        file: null,
    }),
    methods: {
        setUrgency(u){
            this.urgency = u
        },
        async sendInfo(){
            this.fillTitle = (this.title == '');
            this.fillDescription = (this.description == '');
            if (this.fillTitle || this.fillDescription) return;

            const id = await this.$store.dispatch('Project/postUserBug', { projectId: this.$route.params.projectId, title: this.title, description: this.description });

            if (this.file) {
                await this.$store.dispatch('Storage/uploadUserBugFile', { projectId: this.$route.params.projectId, id, file: this.file });
            }

            this.title = '';
            this.description = '';
            this.$refs.fileInput.value = null;

            this.$refs.info.tell('Rapporten din har blitt sendt!');
        },

        fileChange(e) {
            this.file = e.target.files[0] || e.dataTransfer.files[0];
        },

        setVisibility() {
            this.showInfo = !this.showInfo;
        }
    }
}
</script>

<style scoped>

* {
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    font-weight: 300;
    overflow: hidden;
}

.wrapper {
    background: #EDE7E3;
}

.navbar {
    display: flex;
    padding-bottom: 0;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
}

.img {
    width: 100px;
    height: auto;
    padding-left: 30px;
}

.img:hover {
    cursor: pointer;
    
}

.close {
    color: rgb(255, 255, 255);
    position: absolute;
    top: -2px;
    right: 15px;
}

.close:hover {
    cursor: pointer;
    color: rgb(0, 0, 0);
}

.error {
    border: 1px solid rgb(212, 135, 135);
    box-shadow: -1px 0px 3px rgb(212, 135, 135);
}


.pullout {
  visibility: hidden;
  z-index: 11;
}

.background {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #646363;
    opacity: 0.5;
    z-index: 1;
}

label {
    display: inline-block;
    margin-right: 20px;
    text-align: center;
}

.header{
    color: white;
    text-align: center;
    font-size: 2vw;
}

.fa-info-circle {
    font-family: 'FontAwesome';
    color: white;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.sidebar{
    position: absolute;
    right: 0;
    width: 20%;
    min-height: 100%;
    background-color: #343434;
}

.sidebar2{
    margin-top: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #343434;
    z-index: 10;
}

.textHead{
    color: white;
    margin-left: 8%;
    margin-bottom: 0px;
    font-size: 1.3vw;
}

.text{
    color: white;
    margin-left: 5%;
    margin-top: 0px;
    padding: 10px;
    font-size: 0.8vw;
}

.container{
    margin-right: 275px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mid {
    flex-basis: 60%;
}

.break {
    flex-basis: 20%;
    height: 0;
}

.head{
    font-size: 2vw;
    width: 80%;
    text-align: center;
    color: #343434;
    margin-bottom: 0px;
    margin-top: 15px;
}

.content{
    margin-bottom: 0;
    padding-left: 4px;
    padding-right: 4px;
}

.box{
    width: 90%;
    resize: none;
    border-radius: 0px;
    outline: none;
    min-height: 25px;
    padding-left: 8px;
    padding-top: 7px;
    font-size: 14px;
}

.file{
    border: solid 1px #343434;
    width: 91%;
    margin-top: 20px;
    height: 40px;
}

.fileInput{
    padding-top: 10px;
    padding-left: 4px;
}

.send{
    width: 92%;
    height: 40px;
    background-color: #343434;
    color: white;
    border-radius: 5px;
    border: none;
    margin-top: 15px;
    margin-bottom: 25px;
}

.send:hover {
    cursor: pointer;
    background-color: #646363;
}

.send:hover {
    cursor: pointer;
}

@media screen and (max-width: 1385px) {
    .text {
        font-size: 11px;
    }

    .textHead {
        font-size: 16.5px;
    }

    .header {
        font-size: 18px;
    }

    .sidebar {
        width: 275px;
    }
}

@media screen and (max-width: 767px) {
    .text {
        font-size: 11px;
    }

    .mid {
        flex-basis: 80%;
    }

    .break {
        flex-basis: 0%;
    }

    .header {
        font-size: 18px;
    }

    .textHead {
        font-size: 16.5px;
    }

    .sidebar {
        display: none;
    }

    .pullout {
        visibility: visible;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background: #343434;
        width: 35px;
        height: 20%;
        position: fixed;
        right: 0;
        top: 45%;
    }

    .pullout:hover {
        cursor: pointer;
        background-color: #646363;
    }

    .sidebar2{
        margin-top: 100px;
        width: 80%;
        background-color: #343434;
        z-index: 10;
    }

    .container {
        width: 100%;
    }

    .head {
        margin-left: 5%;
        font-size: 18px;
    }
}

@media screen and (min-width: 767px) {
    .text {
        font-size: 11px;
    }

    .header {
        font-size: 18px;
    }

    .textHead {
        font-size: 16.5px;
    }

    .sidebar2 {
        visibility: hidden;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        background: #343434;
        width: 35px;
        height: 20%;
        position: fixed;
        right: 0;
        top: 45%;
    }

    .background {
        visibility: hidden;
    }
}


</style>