<template>
    <div id="dropdown-overlay" :class="{ open: isOpen }" @click="clickOverlay">
        <div class="options overlay-options" ref="overlayOptions" :style="overlayRect">
            <div class="overlay-options-inner" v-pre></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: 'DropdownOverlay',
    data: () => ({
        dropdown: null,
        isOpen: false,
        overlayRect: {
            left: 0,
            top: 0,
        },
    }),
    methods: {
        clickOverlay(event) {
            let target = event.target;
            while (this.isOpen) {
                if (target == this.$el) {
                // Click on the overlay - close
                    this.isOpen = false;
                    this.dropdown.clickOverlay();
                } else if (target.parentElement.parentElement == this.$refs.overlayOptions) {
                    // Click on an option - close, and emit value
                    this.isOpen = false;
                    this.dropdown.clickOption(target.getAttribute('value'));
                } else {
                    // Not found anything yet - go up and check again
                    target = target.parentElement;
                }
            }
        },
        open(dropdown) {
            this.dropdown = dropdown;
            this.$refs.overlayOptions.firstChild.innerHTML = '';
            [...dropdown.$refs.options.children].forEach(child => {
                const el = child.cloneNode(true);
                this.$refs.overlayOptions.firstChild.appendChild(el);
            });

            this.$nextTick(() => {
                const rect = dropdown.$el.getBoundingClientRect();
                this.overlayRect = {
                    left: rect.left + 'px',
                    top: (rect.bottom - 1) + 'px',
                    'min-width': rect.width + 'px',
                };
                this.isOpen = true;
            });
        },
    },
    mounted() {
        Vue.prototype.$dropdownOverlay = this;
    },
    beforeUnmount() {
        Vue.prototype.$dropdownOverlay = null;
    },
}
</script>

<style lang="scss" scoped>
#dropdown-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 2147483647;

    &.open {
        display: block;
    }

    .overlay-options {
        position: absolute;
        box-sizing: border-box;
        background: white;
        padding: 5px 10px;
        border: 1px solid lightgrey;
        border-radius: 5px;

        .overlay-options-inner {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }
}
</style>