import deepClone from '@/util/deepClone'

export default class FirestoreType {
    constructor(doc) {
        this.doc = doc;
        this.data = this.doc?.exists() ? this.doc.data() : {}
        this.snapshot = null;
    }

    get id() { return this.doc?.id; }
    get ref() { return this.doc?.ref; }
    get path() { return this.doc?.ref?.path; }

    takeSnapshot() {
        this.snapshot = deepClone(this.data);
    }

    restoreSnapshot() {
        if (!this.snapshot) throw 'no snapshot taken';
        this.data = deepClone(this.snapshot);
    }

    clearSnapshot() {
        this.snapshot = null;
    }
}