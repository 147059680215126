import { render, staticRenderFns } from "./UserBugOverview.vue?vue&type=template&id=97a46298&scoped=true&"
import script from "./UserBugOverview.vue?vue&type=script&lang=js&"
export * from "./UserBugOverview.vue?vue&type=script&lang=js&"
import style0 from "./UserBugOverview.vue?vue&type=style&index=0&id=97a46298&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97a46298",
  null
  
)

export default component.exports