import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login'

import EmptyRouterView from '@/views/EmptyRouterView'

import CustomerBugOverview from '@/views/CustomerBug/CustomerBugOverview'
import CustomerBugDetails from '@/views/CustomerBug/CustomerBugDetails'

import DevelopmentOverview from '@/views/Development/DevelopmentOverview'
import DevelopmentDetails from '@/views/Development/DevelopmentDetails'

import UserBugOverview from '@/views/UserBug/UserBugOverview'
import UserBugDetails from '@/views/UserBug/UserBugDetails'

import DeveloperDashboard from '@/views/DeveloperDashboard'
import UserDashboard from '@/views/UserDashboard'

import ProjectTimesheet from '@/views/ProjectTimesheet'

import NavView from '@/views/NavView'

import DeveloperTesting from '@/views/Testing/DeveloperTesting'
import TestView from '@/views/Testing/TestView'

import Settings from '@/views/Settings'
import Frontpage from '@/views/Frontpage'

import UserInvite from '@/views/Anonymous/UserInvite'
import UserCreateReport from '@/views/UserCreateReport'

import store from '@/store/index.js';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: NavView,
    children: [
      {
        path: '',
        name: 'Frontpage',
        component: Frontpage,
      },
    ],
  },
  {
    path: '/invite',
    name: 'Invite',
    component: UserInvite,
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/bug/:projectId',
    name: 'UserCreateReport',
    component: UserCreateReport,
  },
  {
    path: '/Project',
    name: 'ProjectBase',
    component: NavView,
    children: [
      {
        path: ':projectId',
        // name: 'Project',
        component: EmptyRouterView,
        children: [
          {
            path: '',
            name: 'DeveloperDashboard',
            component: DeveloperDashboard,
            props: true,
          },
          {
            path: 'Dashboard',
            name: 'UserDashboard',
            component: UserDashboard,
          },
          {
            path: 'testing',
            // name: 'Testing',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'testingOverview',
                component: DeveloperTesting,
                props: true,    
              },
              {
                path: 'plan/:id',
                name: 'TestPlanView',
                props: route => ({ id: route.params.id, item: route.params.item, isRun: false }),
                component: TestView,
              },
              {
                path: 'run/:id',
                name: 'TestRunView',
                props: route => ({ id: route.params.id, item: route.params.item, isRun: true }),
                component: TestView,
              },
            ],
          },        
          {
            path: 'CustomerBug',
            // name: 'CustomerBug',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'CustomerBugOverview',
                component: CustomerBugOverview,
              },
              {
                path: ':reportId',
                name: 'CustomerBugDetails',
                component: CustomerBugDetails,
                props: true,
              },
            ],
          },
          {
            path: 'Development',
            // name: 'Development',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'DevelopmentOverview',
                component: DevelopmentOverview,
              },
              {
                path: ':developmentId',
                name: 'DevelopmentDetails',
                component: DevelopmentDetails,
                props: true,
              },
            ],
          },
          {
            path: 'UserBug',
            // name: 'UserBug',
            component: EmptyRouterView,
            children: [
              {
                path: '',
                name: 'UserBugOverview',
                component: UserBugOverview,
              },
              {
                path: ':reportId',
                name: 'UserBugDetails',
                component: UserBugDetails,
                props: true,
              },
            ],
          },
          {
            path: 'Timesheet',
            name: 'Timesheet',
            component: ProjectTimesheet,
          },
        ],
      },
    ],
  },
  /*{
    path: '/Customer',
    // name: 'Customer',
    component: NavView,
    props: true,
    children: [
      {
        path: '',
        name: 'CustomerFrontpage',
        component: Frontpage,
        props: true,
        meta: {
          requireAuthenticated: true,
        },
      },

    ]
  },*/
  /*{
    path: '/Developer',
    component: NavView,
    props: true,
    children: [
      {
        path: '',
        name: 'DeveloperFrontpage',
        component: Frontpage,
        props: true,
        meta: {
          requireAdmin: true,
        },
      },
      {
        path: 'Timesheet/',
        name: 'DeveloperTimesheet',
        component: DeveloperTimesheet,
        props: true,
        meta: {
          requireAdmin: true,
        },
        children: [
          {
            path: 'Timesheet/:projectId',
            name: 'TimesheetDetails',
            component: DeveloperTimesheetDetails,
            props: true,
          }
        ],
      },
    ],
  },*/
  {
    path: '/settings',
    component: NavView,
    children: [
      {
        path: '',
        name: 'Settings',
        component: Settings,
        props: true,
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.meta.requireAuthenticated) {
    if (!store.state.User.currentUser) return next({ name: 'Login' });
  }
  if (to.meta.requireAdmin) {
    if (!store.state.User.currentUser) return next({ name: 'Login' });
    if (!store.state.User.currentUser.isAdmin) return next({ name: 'Login' });
  }
  return next();
});

export default router
