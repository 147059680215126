<template>
    <div class="main" v-if="planOrRun">
        <div class="header">
            <h1><span v-if="editing">Rediger</span><status-icon v-if="isRun" :status="status" /> {{ planOrRun.name }}</h1>
            <template v-if="isRun"></template>
            <template v-else>
                <div v-if="!editing" class="buttons">
                    <base-button @clicked="startTestRun" buttonType="new">Start Test</base-button>
                    <base-button @clicked="editing = true" buttonType="new">Rediger Testplan</base-button>
                </div>
                <div v-else class="buttons">
                    <base-button @clicked="editing = false" buttonType="new">Ferdig</base-button>
                </div>
            </template>
        </div>
        <input-dialog v-model="creatingCategory" ref="createCategoryDialog">
            <template #title>Ny kategori</template>
            <template #text>Hva er navnet på kategorien?</template>
        </input-dialog>
        <input-dialog v-model="creatingTest" ref="createTestDialog">
            <template #title>Ny test</template>
            <template #text>Hva er navnet på testen?</template>
        </input-dialog>
        <confirm-dialog v-model="deletingCategory" ref="deleteCategoryDialog">
            <template #title>Slett kategori</template>
            <template #text>Er du sikker på at du vil slette {{ deletingCategoryName }}?</template>
        </confirm-dialog>
        <confirm-dialog v-model="deletingTest" ref="deleteTestDialog">
            <template #title>Slett test</template>
            <template #text>Er du sikker på at du vil slette {{ test.name }}?</template>
        </confirm-dialog>
        <div class="flex">
            <base-card class="category-section">
                <h2>Kategorier</h2>
                <div class="categories">
                    <item-view v-for="item in planOrRun.items" :key="item.id"
                        :item="item"
                        :editing="editing"
                        @open="openTest"
                        @addCategory="addCategory"
                        @addTest="addTest"
                        @deleteCategory="deleteCategory"
                    />
                </div>
                <div class="buttons" v-if="editing">
                    <base-button small @clicked="addCategory">+ Kategori</base-button>
                    <base-button small @clicked="addTest">+ Test</base-button>
                </div>
            </base-card>
            <base-card class="test-section">
                <template v-if="test">
                    <div v-if="!isRun && !savingTest" class="test-buttons">
                        <base-button small @clicked="saveTest" v-if="editingTest">Lagre</base-button>
                        <base-button small @clicked="editingTest = true" v-else>Rediger</base-button>
                        <base-button small @clicked="deleteTest" v-if="!editingTest">Slett</base-button>
                    </div>
                    <div v-if="isRun" class="test-buttons">
                        <base-button small @clicked="startTest" v-if="test.status == 'none'">Start</base-button>
                        <base-button small @clicked="undoTestData" v-if="runningTest">Tilbakestill</base-button>
                        <base-button small @clicked="resetTest" v-if="runningTest">Avbryt test</base-button>
                        <base-button small @clicked="resetTest" v-if="test.status != 'none' && test.status != 'running'">Nullstill test</base-button>
                    </div>
                    <h2>{{ test.name }}</h2>
                    <div class="information">
                        <div class="info-line" v-if="isRun && test.status != 'none'">
                            <h3 v-if="test.status == 'running'">Utføres av</h3>
                            <h3 v-else>Utført av</h3>
                            <p>{{ test.runByName }}</p>
                        </div>
                        <div class="info-line" v-if="editingTest">
                            <h3>Forkrav</h3>
                            <div class="removable-input" v-for="(prereq, idx) in test.prerequisites">
                                <base-button small @clicked="removePrerequisite(idx)">x</base-button>
                                <input type="text" v-model="test.prerequisites[idx]" />
                            </div>
                            <base-button small @clicked="addPrerequisite">+</base-button>
                        </div>
                        <div class="info-line" v-else>
                            <h3>Forkrav</h3>
                            <p v-for="prereq in test.prerequisites">{{ prereq }}</p>
                        </div>
                        <div class="info-line">
                            <h3>Trinn</h3>
                            <table>
                                <tr>
                                    <th></th>
                                    <th>Steg</th>
                                    <th>Forventet resultat</th>
                                    <th v-if="isRun">Kommentarer</th>
                                </tr>
                                <tr v-for="(step, idx) in test.steps">
                                    <td v-if="editingTest"><base-button small @clicked="removeStep(idx)">x</base-button></td>
                                    <td v-else>{{ idx+1 }}.</td>
                                    <td>
                                        <input type="text" v-model="step.text" v-if="editingTest">
                                        <span v-else>{{ step.text }}</span>
                                    </td>
                                    <td>
                                        <input type="text" v-model="step.expectedOutcome" v-if="editingTest">
                                        <span v-else>{{ step.expectedOutcome }}</span>
                                    </td>
                                    <td v-if="isRun">
                                        <input type="text" v-model="step.comments" v-if="runningTest">
                                        <span v-else>{{ step.comments }}</span>
                                    </td>
                                </tr>
                                <tr v-if="editingTest">
                                    <td colspan="3">
                                        <base-button small @clicked="addStep">+</base-button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="info-line">
                            <h3>Forventet resultat</h3>
                            <input type="text" v-if="editingTest" v-model="test.expectedOutcome" />
                            <p v-else>{{ test.expectedOutcome }}</p>
                        </div>
                        <template v-if="isRun">
                            <div class="info-line" v-if="runningTest">
                                <h3>Kommentarer</h3>
                                <textarea v-model="test.comments"></textarea>
                            </div>
                            <div class="info-line" v-else>
                                <h3>Kommentarer</h3>
                                <p>{{ test.comments }}</p>
                            </div>
                            <div class="info-line buttons" v-if="runningTest">
                                <base-button @clicked="completeTest('ok')"><status-icon status="ok" /> Godkjent</base-button>
                                <base-button @clicked="completeTest('ok_note')"><status-icon status="ok_note" /> Godkjent med forbehold</base-button>
                                <base-button @clicked="completeTest('error')"><status-icon status="error" /> Ikke godkjent</base-button>
                                <base-button @clicked="completeTest('skipped')"><status-icon status="skipped" /> Hoppet over</base-button>
                            </div>
                        </template>
                    </div>
                </template>
            </base-card>
        </div>
    </div>
</template>

<script>
import ConfirmDialog from '@/components/Modal/ConfirmDialog.vue'
import InputDialog from '@/components/Modal/InputDialog.vue'
import ItemView from './TestView/ItemView.vue'
import StatusIcon from './TestView/StatusIcon.vue'

import Test from '@/types/Test'

export default {
    components: {
        ConfirmDialog,
        InputDialog,
        ItemView,
        StatusIcon,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        isRun: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            planOrRun: null,
            test: null,
            planOrRunUnsub: null,
            testUnsub: null,

            editing: false,
            creatingCategory: false,
            creatingTest: false,
            editingTest: false,
            savingTest: false,
            deletingCategory: false,
            deletingTest: false,

            deletingCategoryName: '',

            //deleteTest: false,
        }
    },
    computed: {
        status() {
            if (this.planOrRun.status.none > 0) return 'none';
            if (this.planOrRun.status.running > 0) return 'running';
            if (this.planOrRun.status.error > 0) return 'error';
            if (this.planOrRun.status.ok == 0 && this.planOrRun.status.ok_note == 0) return 'skipped';
            return 'ok';
        },
        runningTest() {
            if (!this.isRun) return false;
            if (!this.test) return false;
            if (this.test.status != 'running') return false;
            return this.test.runBy == this.$store.state.User.currentUser.id;
        },
    },
    methods: {
        async openTest(testInfo) {
            this.test = null;
            this.editingTest = false;
            this.testUnsub = await this.$store.dispatch('Project/listenTest', { planOrRun: this.planOrRun, id: testInfo.id, listener: test => {
                this.test = test;
                this.test.takeSnapshot();
            } });
        },
        addPrerequisite() {
            this.test.prerequisites.push('');
        },
        removePrerequisite(idx) {
            this.test.prerequisites.splice(idx, 1);
        },
        addStep() {
            this.test.steps.push({
                text: '',
                expectedOutcome: '',
            });
        },
        removeStep(idx) {
            this.test.steps.splice(idx, 1);
        },
        async saveTest() {
            this.savingTest = true;
            this.editingTest = false;
            await this.$store.dispatch('Project/saveTest', { planOrRun: this.planOrRun, test: this.test });
            this.test.takeSnapshot();
            this.savingTest = false;
        },
        async addCategory(category) {
            const name = await this.$refs.createCategoryDialog.ask();
            if (!name || name.trim() == '') return;

            const newCategory = {
                type: 'category',
                name: name,
                items: [],
            };
            if (category) category.items.push(newCategory);
            else this.planOrRun.items.push(newCategory);
            await this.$store.dispatch('Project/saveTestPlan', { plan: this.planOrRun });
        },
        async deleteCategory(category) {
            this.deletingCategoryName = category.name;
            const answer = await this.$refs.deleteCategoryDialog.ask();
            if (!answer) return;

            await this.$store.dispatch('Project/deleteCategory', { plan: this.planOrRun, category });
        },
        async addTest(category) {
            const name = await this.$refs.createTestDialog.ask();
            if (!name || name.trim() == '') return;

            const newTest = new Test();
            newTest.name = name;
            newTest.prerequisites = [];
            newTest.steps = [];
            newTest.expectedOutcome = '';
            const id = await this.$store.dispatch('Project/saveTest', { planOrRun: this.planOrRun, test: newTest });

            const newEntry = {
                type: 'test',
                name: name,
                id,
            };
            if (category) category.items.push(newEntry);
            else this.planOrRun.items.push(newEntry);
            await this.$store.dispatch('Project/saveTestPlan', { plan: this.planOrRun });

            await this.openTest(newEntry);
        },
        async deleteTest() {
            const answer = await this.$refs.deleteTestDialog.ask();
            if (!answer) return;
            await this.$store.dispatch('Project/deleteTest', { plan: this.planOrRun, test: this.test });
            this.test = null;
        },
        async startTestRun() {
            const id = await this.$store.dispatch('Project/startTest', this.planOrRun);
            this.$router.push({name: 'TestRunView', params: { id, projectId: this.$route.params.projectId }});
        },
        async startTest() {
            // Start a test that is in 'none' status
            this.$store.dispatch('Project/setTestStatus', { run: this.planOrRun, test: this.test, status: 'running' });
            this.test.takeSnapshot();
        },
        async completeTest(status) {
            // Complete a test you've started
            this.$store.dispatch('Project/setTestStatus', { run: this.planOrRun, test: this.test, status });
            this.test.takeSnapshot();
        },
        async undoTestData() {
            // Undo your current changes to the test
            this.test.restoreSnapshot();
        },
        async resetTest() {
            // Set a test back to 'none' status (also undoes any changes)
            this.test.restoreSnapshot();
            this.$store.dispatch('Project/setTestStatus', { run: this.planOrRun, test: this.test, status: 'none' });
            this.test.takeSnapshot();
        },
    },
    async mounted() {
        if (this.item) this.planOrRun = this.item;
        else this.planOrRunUnsub = await this.$store.dispatch(`Project/listenTest${this.isRun ? 'Run' : 'Plan'}`, Object.assign({ listener: planOrRun => {
            this.planOrRun = planOrRun;
        } }, this.$route.params));
    },
    beforeDestroy() {
        if (this.testUnsub) this.testUnsub();
        if (this.planOrRunUnsub) this.planOrRunUnsub();
    },
}
</script>

<style lang="scss" scoped>
.main {
    flex-grow: 1;
    background: #F7F7F7;
    box-sizing: border-box;

    h2 {
        border-bottom: 1px solid #DCDCDC;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* padding: 10px 0 20px 0; */

        .buttons {
            display: flex;
            gap: 10px;
        }
    }

    .flex {
        display: flex;
        flex-direction: row;
        gap: 25px;

        img {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 0;
        }

        .category-section {
            flex-shrink:1;
            min-width: 415px;

            .categories {
                position: relative;

                button {
                    display:inline-flex;
                }

                img {
                    cursor: pointer;
                }

                .subGroup {
                    padding-left: 50px;
                    position: relative;

                    p {
                        text-decoration: underline;
                    }

                    .flex::before {
                        content: '';
                        position: absolute;
                        width: 30px;
                        height: 15px;
                        left: 5px;
                        border-left: 1px #B3B3B3 solid;
                        border-bottom: 1px #B3B3B3 solid;
                    }
                }

                .flex {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                }
            }

            .buttons {
                display: flex;
                gap: 10px;
            }
        }

        .test-section {
            flex-grow: 2;
            position: relative;

            /*.edit-test-button {
                padding: 5px 10px;
                position: absolute;
                top: 10px;
                right: 20px;
            }*/

            .test-buttons {
                position: absolute;
                top: 10px;
                right: 20px;
                display: flex;
                gap: 10px;
            }

            .information {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .removable-input {
                    display: flex;
                    gap: 10px;
                }

                .info-line {
                    h3 {
                        margin: 0;
                    }

                    p {
                        margin: 0;
                    }

                    input, textarea {
                        display: block;
                        width: 100%;
                        font-family: inherit;
                    }

                    textarea {
                        height: 100px;
                    }

                    table {
                        width: 100%;

                        th {
                            text-align: left;
                        }

                        th, td {
                            padding: 0 10px;
                        }
                    }

                    &.buttons {
                        display: flex;
                        gap: 10px;
                    }
                }
            }
        }
    }
}
</style>