var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[_c('report-overview',{attrs:{"items":_vm.items,"actions":[
        {
            id: 'open',
            name: 'Åpne',
            default: true,
        },
        {
            id: 'delete',
            name: 'Slett',
            border: 'red',
            dev: true,
        },
    ]},on:{"open":_vm.openItem,"delete":_vm.deleteItem},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Brukerrapporter")]},proxy:true}])}),_c('confirm-dialog',{ref:"deleteModal",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Slett feil")]},proxy:true},{key:"text",fn:function(){return [_vm._v("Er du sikker på at du vil slette "+_vm._s(_vm.itemToDelete.title)+"?")]},proxy:true}]),model:{value:(_vm.deleteModalOpen),callback:function ($$v) {_vm.deleteModalOpen=$$v},expression:"deleteModalOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }